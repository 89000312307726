import React from "react"
import styled from "styled-components";
import Toast from "./Toast";
import UserTokenListItemMini from './UserTokenListItemMini'
import Web3Utils from "web3-utils";
// import Web3Eth from "web3-eth";
import * as Web3 from "web3-eth";

const styles = {
    inputSection: {
        width: '100%',
        marginBottom: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    header: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        margin: '20px 0'
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    disabledButton: {
        border: 'none',
        backgroundColor: '#CCC',
        display: 'inline-flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'not-allowed',
        borderRadius: '5px',
    },
    cardTitle: {
        paddingBottom: '10px',
        boxSizing: 'border-box',
        marginBottom: '10px',
        borderBottom: '1px solid #CCC',
        fontSize: '24px',
        fontWeight: '600',
        width: '100%',
    },
    infoDescription: {
        color: '#666',
        marginBottom: '20px',
        fontSize: '14px',
    },
    modalContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    container: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '20px',
        zIndex: 200,
        fontFamily: 'Lato, Arial',
    },
    inputDescription: {
        color: '#666',
        fontSize: '14px',
        marginBottom: '5px',
    },
    imageContainer: {
        marginBottom: '10px',
    }
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.div`
:hover {
    background-color: #49ab67 !important;
}
`

let toastId = 1

const abi = [
    {
        "constant": false,
        "inputs": [
            {
                "internalType": "address",
                "name": "_from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "_data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "internalType": "address",
                "name": "_owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "internalType": "address[]",
                "name": "_owners",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "_ids",
                "type": "uint256[]"
            }
        ],
        "name": "balanceOfBatch",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
]

class SettingsDepositNFT extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            eth_address: "",
            toasts: [],
            user_tokens: props.user_tokens,
            token_selected: null,
            showModal: false,
            quantities: {},
            tokens: []
        }
    }

    onChange(e) {
        console.log('e', e)
        this.setState({[e.target.name]: e.target.value})
    }

    selectToken = (token) => {
        console.log('token', token)
        this.setState({token_selected: token, showModal: true})
    }

    closeModal() {
        this.setState({showModal: false})
    }

    connectWallet = () => {
        if (!this.state.depositing && window.ethereum) {
            this.setState({connecting: true})
            let test = window.ethereum.request({ method: 'eth_requestAccounts' })
            test.then(res => {
                this.updateUserSetting('eth')
                this.setState({purchasing: false})
            }).catch(err => {
                this.setState({purchasing: false})
            })
        }
    }

    submitForm() {
        if (this.state.depositing) {
            return
        }

        if (this.state.payment_method_preference === 'eth' && !window.ethereum?.selectedAddress) {
            this.connectWallet()
            return
        }

        const body = {
            token_id: this.state.token_selected.id,
            quantity: this.state.quantity,
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        let fetchPath = `${window.location.origin}/api/v0/token_deposits`

        this.setState({depositing: true, showModal: false})

        fetch(fetchPath, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                if (res.success) {
                    let tx = await this.sendTransaction(res)
                    console.log('tx', tx)
                    if (tx) {
                        this.updateTokenDeposit(res.token_deposit, tx)
                    }
                    this.setState({depositing: false})
                } else {
                    this.state.toasts.push({title: 'Error', information:  res.errors?.[0], id: toastId++})
                    this.setState({toasts: this.state.toasts, purchasing: false, depositing: false})
                }
            })
            .catch(err => {
                this.setState({depositing: false})
            })
    }

    getTokens(ids) {
        const body = {
            page: 1,
            ids: ids.join(','),
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/tokens?ids=${body.ids}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        tokens: res.tokens,
                    });
                }
            })
            .catch(err => {
            })
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderTokens() {
        return this.state.tokens.map(token => {
            return (<UserTokenListItemMini key={token.blockchain_id} clickMethod={this.selectToken.bind(this, token)} quantity={this.state.quantities[token.blockchain_id]} token={token} />)
        })
    }

    depositModal = () => {

        if (!this.state.showModal) {
            return null
        }

        let disabled = false
        let errorMessage = 'Transfer Invalid'
        let imageStyle = {width: '300px', height: '300px', objectFit: 'cover', borderRadius: '20px'}

        if (this.state.quantity === 0 || this.state.quantity === ''){
            disabled = true
            errorMessage = `Enter a quantity above 0`
        }

        if (parseInt(this.state.quantity) > parseInt(this.state.quantities[this.state.token_selected.blockchain_id])) {
            disabled = true
            errorMessage = `Max Quantity ${this.state.quantities[this.state.token_selected.blockchain_id]}`
        }

        return (
            <div style={styles.modalContainer}>
                <FormContainer style={styles.container}>
                    <div style={styles.cardTitle}>
                        {`Deposit ${this.state.token_selected.name}`}
                    </div>
                    <div style={styles.imageContainer}>
                        <img style={imageStyle} src={this.state.token_selected.thumbnail_url} />
                    </div>
                    <div style={styles.inputSection}>
                        <div style={styles.inputLabel}>
                            Quantity
                        </div>
                        <div style={styles.inputDescription}>
                            {`${this.state.quantities[this.state.token_selected.blockchain_id]} available for deposit`}
                        </div>
                        <input style={styles.inputBox} maxLength="50" name="quantity" placeholder="0" value={this.state.quantity} onChange={this.onChange.bind(this)} />
                    </div>
                    {
                        !disabled ? (
                            <SubmitButton onClick={this.submitForm.bind(this)} style={styles.submitButton}>
                                {`Deposit`}
                            </SubmitButton>

                        ) : (

                            <button style={styles.disabledButton}>
                                {errorMessage}
                            </button>
                        )
                    }
                </FormContainer>
                <div onClick={() => this.closeModal()} style={styles.background} />
            </div>
        )
    }

    renderWithdrawNFTs = () => {
        return (
            <React.Fragment>
                <div style={styles.cardTitle}>
                    Deposit NFTs
                </div>
                <div style={styles.infoDescription}>
                    <div>
                        <a style={{color: '#5dd982', fontSize: '24px', fontWeight: 600}} href={'https://www.youtube.com/watch?v=F3gKREyUJl4'} target={"_blank"}>
                            {`Video Guide for NFT Deposits`}
                        </a>
                    </div>
                    NFT deposits are only possible from the Polygon network
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Select Token
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.renderTokens()}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    componentDidMount() {
        console.log('mounted')
        if (document.readyState === "complete") {
            if (window.ethereum && window.ethereum.selectedAddress && window.ethereum.networkVersion === '137') {
                console.log('balanced fetched')
                this.getTokenBalances()
            }
        } else {
            window.addEventListener("load", async () => {
                console.log('loaded')
                if (window.ethereum && window.ethereum.selectedAddress && window.ethereum.networkVersion === '137') {
                    console.log('balanced fetched')
                    this.getTokenBalances()
                }
            });
        }
    }

    getTokenBalances = async(res) => {
        const web3Instance = new Web3('https://polygon-rpc.com/')
        let myContract = new web3Instance.Contract(abi, "0xdbEd9947A2A7DB821C42E80AbBe515Bb8D127457", { gasLimit: "15000000" })
        console.log()
        let addressArray = []
        for (let i = 0; i < this.props.blockchain_ids.length; i++) {
            addressArray.push(window.ethereum.selectedAddress)
        }
        let data = myContract.methods.balanceOfBatch(addressArray, this.props.blockchain_ids).call({from: window.ethereum.selectedAddress})

        const trueThis = this
        data.then(res => {
            console.log('the result', res)
            let blockchain_ids = []
            let quantities = []
            for (let i = 0; i < res.length; i++) {
                if (res[i] > 0) {
                    blockchain_ids.push(this.props.blockchain_ids[i])
                    quantities[this.props.blockchain_ids[i]] = res[i]
                }
            }
            trueThis.setState({blockchain_ids: blockchain_ids, quantities: quantities})

            trueThis.getTokens(blockchain_ids)
        })
    }

    updateTokenDeposit(token_deposit, transaction) {
        const body = {
            tx_id: transaction
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        let fetchPath = `${window.location.origin}/api/v0/token_deposits/${token_deposit.id}`

        fetch(fetchPath, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                if (res.success) {
                    this.state.toasts.push({title: 'Token Deposit Initiated', information:  `Token deposit started and should be reflected shortly`, id: toastId++})
                    this.setState({toasts: this.state.toasts, purchasing: false, depositing: false})
                } else {
                    this.state.toasts.push({title: 'Error', information:  res.errors?.[0], id: toastId++})
                    this.setState({toasts: this.state.toasts, purchasing: false, depositing: false})
                }
            })
            .catch(err => {
                this.setState({depositing: false})
            })
    }

    sendTransaction = async(res) => {
        const web3Instance = new Web3('https://polygon-rpc.com/')
        let myContract = new web3Instance.Contract(abi, "0xdbEd9947A2A7DB821C42E80AbBe515Bb8D127457", { gasLimit: "15000000" })
        let data = myContract.methods.safeTransferFrom(window.ethereum.selectedAddress, "0x7a819a5DF7A959b42EF9447d53A003677A6e58c3", this.state.token_selected.blockchain_id, parseInt(this.state.quantity), Web3Utils.toHex(res.token_deposit.code)).encodeABI()

        const transactionParameters = {
            to: "0xdbEd9947A2A7DB821C42E80AbBe515Bb8D127457",
            from: window.ethereum.selectedAddress,
            data: data,
            value: Web3Utils.toHex(0)
        }

        let txHash = null

        try {
            txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            })
        } catch (err) {
            if (err.code === 4001) {
                this.resetCryptoPayment()
            }
            console.log('nope')
            console.log(err)
        }

        return txHash
    }

    render() {
        return (
            <div>
                {this.renderWithdrawNFTs()}
                {this.depositModal()}
                {this.renderToasts()}
            </div>
        )
    }
}


export default SettingsDepositNFT
