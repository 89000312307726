import React from "react"
import moment from "moment";
import Paginator from "./Paginator";
import styled from "styled-components";

const styles = {
    tradeContainer: {
        boxSizing: 'border-box',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
        width: '100%',
    },
    trade: {
        padding: '15px 30px',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tradeLink: {
        color: '#5dd982',
        fontWeight: '600',
    },
    tradeWord: {
        color: '#999',
    },
    tradeSection: {
        width: '100%',
        marginTop: '30px',
    },
    tradeTitle: {
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '10px',
    },
    tradeInfo: {
        flexBasis: '75%',
    },
    timeAgo: {
        flexBasis: '25%',
        marginLeft: '10px',
        color: '#999',
        textAlign: 'right',
    }
}

const InfoContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

class TradeList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            totalPages: props.total_pages,
            trades: props.trades,
        }
    }

    capitalize = (str) => (str[0].toUpperCase() + str.slice(1, str.length))

    renderTrade(trade, lastTrade) {
        return (
            <div style={{...styles.trade, borderBottom: lastTrade ? null : '1px solid #CCC',}}>
                <div style={styles.tradeInfo}>
                    <a style={styles.tradeLink} href={`/users/${trade.buyer.slug}`}>
                        {this.capitalize(trade.buyer.username)}
                    </a>
                    <span style={styles.tradeWord}>
                        {` bought ${trade.quantity} `}
                    </span>
                    <a style={styles.tradeLink} href={`/tokens/${trade.token.slug}`}>
                        {trade.token.name}
                    </a>
                    <span style={styles.tradeWord}>
                        {" from "}
                    </span>
                    <a style={styles.tradeLink} href={`/users/${trade.seller.slug}`}>
                        {this.capitalize(trade.seller.username)}
                    </a>
                    <span style={styles.tradeWord}>
                        {" for "}
                    </span>
                    <span style={{fontWeight: '600'}}>
                        {(trade.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </span>
                </div>
                <div style={styles.timeAgo}>
                    {moment(new Date(trade.created_at)).fromNow(true) + ' ago'}
                </div>
            </div>
        )
    }

    objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    }

    updateTrades(page) {
        const body = {
            token_id: this.props.trades[0].token_id,
            page: page || 1
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/trades?${this.objToQueryString(body)}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        trades: res.trades,
                    });
                }
            })
            .catch(err => {
            })
    }

    render() {
        if (this.props.trades.length === 0) {
            return null
        }
        return (
            <div style={styles.tradeSection}>
                <div style={styles.tradeTitle}>
                    Trades
                </div>
                <InfoContainer style={styles.tradeContainer}>
                    {this.state.trades.map((trade, index) => this.renderTrade(trade, index + 1 === this.props.trades.length))}
                </InfoContainer>
                {this.state.totalPages > 1 ? <Paginator updateFn={this.updateTrades.bind(this)} totalPages={this.state.totalPages} /> : null}
            </div>
        )
    }
}

export default TradeList