import React from "react"
import styled from "styled-components"
import TokenMiniListItem from "./TokenMiniListItem";
import DateTimePicker from 'react-datetime-picker'
import axios from "axios"
import Toast from "./Toast";

const styles = {
    container: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
    },
    h1: {
        fontSize: 36,
        fontWeight: 600,
        marginBottom: '25px',
        marginTop: '35px',
    },
    formContainer: {
        maxWidth: '800px',
        padding: '30px',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: '20px',
    },
    termTitle: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    termInfo: {
        fontSize: '14px',
        color: '#333',
        marginBottom: '60px',
    },
    rewards: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    rewardTitle: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '20px',
    },
    rewardDescription: {
        fontSize: '14px',
        color: '#666',
        textAlign: 'center',
        marginTop: '20px',
        padding: '0 20px',
    },
    bannerTextInfo: {
        fontSize: '14px',
        color: '#e73b1d',
        textAlign: 'center',
        marginTop: '20px',
        padding: '0 20px',
    },
    rewardDescriptionAvailable: {
        fontSize: '14px',
        color: '#5dd982',
        textAlign: 'center',
        marginTop: '20px',
        padding: '0 20px',
    },
    rewardsSection: {
        marginTop: '40px',
    },
    rewardsSectionTitle: {
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '10px',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#DDD',
        margin: '30px 0',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '24px',
        fontWeight: '600',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
        width: '100%',
    },
    referralLinkTitle: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '24px',
    },
    referralLinkText: {
        textAlign: 'center',
        fontSize: '20px',
        border: '1px solid #CCC',
        borderRadius: '20px',
        margin: '20px 0',
        padding: '20px',
    },
    referralDescription: {
        fontSize: '14px',
        color: '#666',
        textAlign: 'center',
    },
    progressContainerContainer: {
      padding: '0 20px',
      width: '100%',
      boxSizing: 'border-box',
    },
    progressContainer: {
        backgroundColor: '#333',
        position: 'relative',
        width: '100%',
        borderRadius: '20px',
        height: '20px',
        margin: '20px 0',
    },
    progressFilled: {
        backgroundColor: '#5dd982',
        height: '20px',
        borderRadius: '20px',
    },
    progressText: {
        position: 'absolute',
        color: '#FFF',
        fontWeight: '600',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        textAlign: 'center',
        top: 0,
    },
    rewardSection: {
        boxSizing: 'border-box',
    },
    referralSectionDescription: {
        fontSize: '14px',
        color: '#666',
        textAlign: 'center',
    }
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.input`
:hover {
    background-color: #49ab67 !important;
}
`

let toastId = 1

class Referrals extends React.Component {

    state = {
        toasts: [],
        mobile: window.innerWidth < 1000,
    }

    referralLink = () => {
        return window.location.protocol + "//" + window.location.host + `?referred_by=${this.props.user.username}`;
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.referralLink())
        this.state.toasts.push({title: 'Referral link copied', information:  `You have successfully copied your referral link`, id: toastId++})
        this.setState({toasts: this.state.toasts})
    }

    renderProgressBar = (numerator, denominator) => {
        return (
            <div style={styles.progressContainerContainer}>
                <div style={styles.progressContainer}>
                    <div style={{...styles.progressFilled, width: `${(numerator / denominator) * 100}%`}}>
                    </div>
                    <div style={styles.progressText}>
                        {`${numerator} / ${denominator}`}
                    </div>
                </div>
            </div>
        )
    }

    render () {
        return (
            <React.Fragment>
                <div style={styles.container}>
                    <div style={styles.h1}>
                        {`Receive Free NFTs`}
                    </div>
                    <FormContainer style={styles.formContainer}>
                        <div>
                            <div style={styles.referralLinkTitle}>
                                Referral Link
                            </div>
                            <div style={styles.referralDescription}>
                                Receive free NFTs for successful referrals
                            </div>
                            <div style={styles.referralLinkText}>
                                {this.referralLink()}
                            </div>
                            <SubmitButton type="submit" value="Copy" onClick={this.copyToClipboard} style={styles.submitButton} />
                        </div>
                        <div style={styles.rewardsSection}>
                            <div style={styles.rewardsSectionTitle}>
                                Rewards
                            </div>

                            <div style={styles.referralSectionDescription}>
                                {`Total signups with your referral link: `}
                                <span style={{fontWeight: 600, fontSize: '20px', color: '#000'}}>
                                    {this.props.referrals}
                                </span>
                            </div>
                            <div style={styles.divider} />
                            <div style={{...styles.rewards, flexWrap: this.state.mobile ? 'wrap' : 'nowrap'}}>
                                <div style={styles.rewardSection}>
                                    <div style={styles.rewardTitle}>
                                        Free NFT (Satellites)
                                    </div>
                                    <div style={styles.rewardDescriptionAvailable}>
                                        Available
                                    </div>
                                    <div style={styles.rewardDescription}>
                                        {`Receive the free 1/1000 "Satellites" NFT by participating in the `}
                                        <span style={{fontWeight: 'bold', color: '#111'}}>
                                            giveaway!
                                        </span>
                                        {` (Limit 1 per person)`}
                                    </div>
                                    {this.renderProgressBar(this.props.referrals_received, 1)}
                                    <TokenMiniListItem {...this.props.referral_token} />
                                </div>
                                <div style={styles.rewardSection}>
                                    <div style={styles.rewardTitle}>
                                        Free NFT (Meld)
                                    </div>
                                    <div style={styles.rewardDescriptionAvailable}>
                                        Available
                                    </div>
                                    <div style={styles.rewardDescription}>
                                        {`Receive the free 1/10,000 "Meld" NFT when a friend you referred `}
                                        <span style={{fontWeight: 'bold', color: '#111'}}>
                                            makes a purchase
                                        </span>. (Limit 5 per person)
                                    </div>
                                    {this.renderProgressBar(this.props.referrals_received_paid, 5)}
                                    <TokenMiniListItem {...this.props.referral_token_paid} />
                                </div>
                            </div>
                        </div>
                    </FormContainer>
                    {this.renderToasts()}
                </div>
            </React.Fragment>

        );
    }
}

export default Referrals
