import React from "react"
import styled from "styled-components";
import Checkbox from "./Checkbox";

const styles = {
    h1: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        margin: '20px 0 0',
    },
    notificationSection: {
        marginTop: '20px',
        alignItems: 'center',
        display: 'flex',
    },
    notificationTitle: {
        fontWeight: '600',
        fontSize: '16px',
        marginLeft: '10px',
    },
    cardTitle: {
        paddingBottom: '10px',
        boxSizing: 'border-box',
        marginBottom: '30px',
        borderBottom: '1px solid #CCC',
        fontSize: '24px',
        fontWeight: '600',
        width: '100%',
    },
}

class SettingsNotifications extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
        }
    }

    objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    }

    componentDidMount() {
        const body = {
            user_id: this.props.user.id
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/users?${this.objToQueryString(body)}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        user: res.user,
                    });
                }
            })
            .catch(err => {
            })
    }

    updateUserSetting(attribute) {
        const body = attribute
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/users/${this.props.user?.slug}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                } else {
                }
            })
            .catch(err => {

            })
    }

    updateSaleEmail(value) {
        this.state.user.sale_emails_enabled = value
        this.setState({user: this.state.user})
        this.updateUserSetting({sale_emails_enabled: value})
    }

    updateBuyFromUserEmail(value) {
        this.state.user.buy_from_user_emails_enabled = value
        this.setState({user: this.state.user})
        this.updateUserSetting({buy_from_user_emails_enabled: value})
    }

    updatePurchaseEmail(value) {
        this.state.user.purchase_emails_enabled = value
        this.setState({user: this.state.user})
        this.updateUserSetting({purchase_emails_enabled: value})
    }

    updateOrderEmail(value) {
        this.state.user.order_emails_enabled = value
        this.setState({user: this.state.user})
        this.updateUserSetting({order_emails_enabled: value})
    }

    updateWonDrawingEmail(value) {
        this.state.user.won_drawing_emails_enabled = value
        this.setState({user: this.state.user})
        this.updateUserSetting({won_drawing_emails_enabled: value})
    }

    updateLostDrawingEmail(value) {
        this.state.user.lost_drawing_emails_enabled = value
        this.setState({user: this.state.user})
        this.updateUserSetting({lost_drawing_emails_enabled: value})
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.cardTitle}>
                    Notifications
                </div>
                <div style={styles.notificationSection}>
                    <Checkbox active={this.state.user.sale_emails_enabled} updateFn={this.updateSaleEmail.bind(this)} model={'something'} field={'agree'} />
                    <div style={styles.notificationTitle}>
                        Sale Emails
                    </div>
                </div>
                <div style={styles.notificationSection}>
                    <Checkbox active={this.state.user.buy_from_user_emails_enabled} updateFn={this.updateBuyFromUserEmail.bind(this)} model={'something'} field={'agree'} />
                    <div style={styles.notificationTitle}>
                        Buy From User Emails
                    </div>
                </div>
                <div style={styles.notificationSection}>
                    <Checkbox active={this.state.user.purchase_emails_enabled} updateFn={this.updatePurchaseEmail.bind(this)} model={'something'} field={'agree'} />
                    <div style={styles.notificationTitle}>
                        Purchase Emails
                    </div>
                </div>
                <div style={styles.notificationSection}>
                    <Checkbox active={this.state.user.order_emails_enabled} updateFn={this.updateOrderEmail.bind(this)} model={'something'} field={'agree'} />
                    <div style={styles.notificationTitle}>
                        Order Emails
                    </div>
                </div>
                <div style={styles.notificationSection}>
                    <Checkbox active={this.state.user.won_drawing_emails_enabled} updateFn={this.updateWonDrawingEmail.bind(this)} model={'something'} field={'agree'} />
                    <div style={styles.notificationTitle}>
                        Won Drawing Emails
                    </div>
                </div>
                <div style={styles.notificationSection}>
                    <Checkbox active={this.state.user.lost_drawing_emails_enabled} updateFn={this.updateLostDrawingEmail.bind(this)} model={'something'} field={'agree'} />
                    <div style={styles.notificationTitle}>
                        Lost Drawing Emails
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsNotifications