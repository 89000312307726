import React from "react"
import styled from "styled-components";
import moment from "moment";

const styles = {
    container: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        maxWidth: '1000px',
        margin: '50px auto',
        boxSizing: 'border-box',
    },
    imageContainer: {
        width: '100%',
        maxWidth: '500px',
        height: '375px',
    },
    h1: {
        fontWeight: '600',
        fontSize: '30px',
        margin: '0 0 30px',
    },
    name: {
        fontWeight: '600',
        fontSize: '30px',
        marginTop: '5px',
    },
    priceContainer: {
        padding: 20,
    },
    price: {
        fontWeight: '600',
        fontSize: '24px',
    },
    attributesContainer: {
        marginTop: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    transactionType: {
        fontWeight: '600',
        fontSize: '24px',
        color: '#5dd982',
    },
    tradeInfo: {
        textAlign: 'center',
    },
}

const Container = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

class TradePage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Container style={styles.container}>
                    <div style={styles.h1}>
                        Trade Receipt
                    </div>
                    <div style={styles.tradeInfo}>
                        <a href={this.props.trade.item.edition ?`/item_details/${this.props.trade.item.item_detail.slug}/${this.props.trade.item.edition}`: `/items/${this.props.trade.item.id}`}>
                            <div style={styles.imageContainer}>
                                <img style={{width: '100%', maxWidth: '500px', height: '375px', objectFit: 'cover'}} src={this.props.trade.item.item_detail.thumbnail_url} />
                            </div>
                            <div style={styles.name}>
                                {this.props.trade.item.item_detail.name}
                            </div>
                            <div style={styles.author}>
                                {'by ' + this.props.trade.item.item_detail.user.username}
                            </div>
                        </a>
                    </div>
                    <div style={styles.attributesContainer}>
                        <div style={styles.priceContainer}>
                            <div>
                                Price
                            </div>
                            <div style={styles.price}>
                                {(this.props.trade.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>

                        <div style={styles.priceContainer}>
                            <div>
                                Transaction
                            </div>
                            <div style={styles.transactionType}>
                                {this.props.trade.buyer.id === this.props.user.id ? "Secondary Buy" : "Sell"}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div>
                                Date Traded
                            </div>
                            <div style={styles.price}>
                                {moment(new Date(this.props.trade.created_at)).format("MMM DD, YYYY")}
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default TradePage