import React from "react"
import TokenListItem from "./TokenListItem";
import Paginator from "./Paginator";
import TokenMiniListItem from "./TokenMiniListItem";
import styled from "styled-components";
import Toast from "./Toast";

const styles = {
    totalContainer: {
        width: '100%',
        fontFamily: 'Lato, Arial',
    },
    container: {
        padding: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    countdownLabel: {
        fontSize: '20px',
        color: '#333',
        paddingBottom: '10px',
        marginBottom: '10px',
        marginTop: '40px',
        borderBottom: '1px solid #EEE',
    },
    collectionList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#DDD',
        margin: '30px 0 0',
    },
    countdownTimer: {
        fontWeight: '600',
        fontSize: '34px',
        marginBottom: '20px',
        textAlign: 'center',
        marginTop: '30px',
    },
    pastDrops: {
        paddingTop: '50px',
        fontSize: '36px',
        fontWeight: '600',
        width: '100%',
        textAlign: 'center',
    },
    time: {
        fontWeight: '600',
        fontSize: '34px',
    },
    timeWord: {
        fontWeight: '400',
        marginRight: '30px',
        fontSize: '20px',
    },
    banner: {
        width: '100%',
        padding: '100px 20px',
        boxSizing: 'border-box',
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #EEE',
        textAlign: 'center',
    },
    bannerMini: {
        width: '100%',
        padding: '50px 20px',
        boxSizing: 'border-box',
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #EEE',
        textAlign: 'center',
    },
    referralBanner: {
        width: '100%',
        padding: '20px 20px',
        boxSizing: 'border-box',
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #EEE',
        textAlign: 'center',
    },
    bannerTitle: {
        fontWeight: '600',
        fontSize: '34px',
    },
    bannerText: {
        fontSize: '20px',
        color: '#666',
        marginTop: '10px',
    },
    bannerTextInfo: {
        fontSize: '16px',
        marginTop: '10px',
        color: '#e73b1d',
    },
    learnMore: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#5dd982',
    },
    learnMoreOriginal: {
        fontSize: '16px',
        fontWeight: '600',
        marginTop: '10px',
        color: '#5dd982',
    },
    collectionContainer: {
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    subDropText: {
        color: '#666',
        margin: '5px 0',
        padding: '0 20px',
        boxSizing: 'border-box',
    },
    referralContainer: {
        padding: 10,
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        paddingBottom: '40px',
    },
    h1: {
        fontSize: 36,
        fontWeight: 600,
        marginBottom: '25px',
        marginTop: '35px',
    },
    referralLinkTitle: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '24px',
    },
    referralLinkText: {
        textAlign: 'center',
        fontSize: '16px',
        boxSizing: 'border-box',
        border: '1px solid #CCC',
        borderRadius: '20px',
        margin: '0 0 20px',
        padding: '10px 20px',
    },
    referralDescription: {
        fontSize: '14px',
        color: '#666',
        textAlign: 'center',
        marginBottom: '10px',
    },
    formContainer: {
        maxWidth: '450px',
        width: '100%',
        padding: '20px',
        boxSizing: 'border-box',
        borderRadius: '20px',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '18px',
        fontWeight: '600',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        margin: '0 15px',
        borderRadius: '5px',
    },
    submitButtonInfo: {
        display: 'inline-flex',
        padding: '10px 20px',
        fontSize: '18px',
        fontWeight: '400',
        backgroundColor: 'white',
        border: '1px solid #333',
        alignItems: 'center',
        color: '#333',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        margin: '0 15px',
        borderRadius: '5px',
    },
    submitButtonMini: {
        display: 'inline-flex',
        padding: '6px 12px',
        fontSize: '16px',
        fontWeight: '400',
        marginTop: '25px',
        backgroundColor: 'white',
        border: '1px solid #333',
        alignItems: 'center',
        color: '#333',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    referralInner: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    applicationContainer: {
        width: '100%',
        padding: '60px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #EEE',
    },
    applyTitle: {
        fontWeight: '600',
        fontSize: '28px',
    },
    applyDescription: {
        marginBottom: '20px',
        marginTop: '5px',
        fontSize: '14px',
        textAlign: 'center',
        padding: '0 20px',
        boxSizing: 'border-box',
        color: '#666',
    },
    buttons: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
    }
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.input`
:hover {
    background-color: #49ab67 !important;
}
`

let toastId = 1

class DropPage extends React.Component {
    constructor(props) {
        super(props)

        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        let toasts = []
        if (urlParams.get('confirmed')) {
            toasts = [{title: 'Email Confirmed', information:  `You have successfully confirmed your email address`, id: toastId++}]
        } else if (urlParams.get('logout')) {
            toasts = [{title: 'Logged Out', information:  `You have successfully logged out of your account`, id: toastId++}]
        }
        this.state = {
            toasts: toasts,
            timeToNextDrop: props.time_to_next_drop,
            timeToUpcomingDrop: props.time_to_upcoming_drop,
            totalPages: props.total_pages,
            future_tokens: props.future_tokens,
            active_tokens: props.active_tokens,
            prev_tokens: props.prev_tokens,
        }
    }

    componentDidMount() {
        setInterval(() => this.setState({timeToNextDrop: this.state.timeToNextDrop-1 < 0 ? 0 : this.state.timeToNextDrop-1}), 1000)
        setInterval(() => this.setState({timeToUpcomingDrop: this.state.timeToUpcomingDrop-1 < 0 ? 0 : this.state.timeToUpcomingDrop-1}), 1000)
    }

    calcSeconds = time => {
        return this.pad(Math.floor(time % 60))
    }

    calcMinutes = time => {
        return this.pad(Math.floor(time/60) % 60)
    }

    calcHours = time => {
        return this.pad(Math.floor(time/3600))
    }

    pad = num => {
        if ((num + "").length === 1) {
            return "0" + num
        }
        return num
    }

    calcTime = time => {
        return (
            <span>
                <span style={styles.time}>
                    {this.calcHours(time)}
                </span>
                <span style={styles.timeWord}>
                    {" hours"}
                </span>
                <span style={styles.time}>
                    {this.calcMinutes(time)}
                </span>
                <span style={styles.timeWord}>
                    {" minutes"}
                </span>
                <span style={styles.time}>
                    {this.calcSeconds(time)}
                </span>
                <span style={{...styles.timeWord, marginRight: 0}}>
                    {" seconds"}
                </span>
            </span>
        )
    }

    renderCollections = tokens => {
        return tokens.map((token, index) => {
            return <TokenListItem {...token} key={token.id} index={index} />
        })
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderActiveCollections = () => {
        if (this.props.active_tokens.length === 0) {
            return null
        }

        return (
            <React.Fragment>
                <div style={styles.pastDrops}>
                    Active Drop
                </div>
                <div style={styles.subDropText}>
                    This drop is now available to purchase for a limited time!
                </div>
                <div style={styles.countdownTimer}>
                    {this.calcTime(this.state.timeToNextDrop)}
                </div>
                <div style={styles.collectionList}>
                    {this.renderCollections(this.props.active_tokens)}
                </div>
                <div style={styles.divider} />
            </React.Fragment>
        )
    }

    renderNextDrops() {
        if (this.props.future_tokens.length === 0) {
            return null
        }

        return (
            <React.Fragment>
                <div style={styles.pastDrops}>
                    Upcoming Drops
                </div>
                <div style={styles.subDropText}>
                    Explore upcoming art that will be released on Nanobash
                </div>
                { this.props.active_tokens.length === 0 && (
                    <div style={styles.countdownTimer}>
                        {this.calcTime(this.state.timeToUpcomingDrop)}
                    </div>
                )}
                <div style={styles.collectionList}>
                    {this.renderCollections(this.props.future_tokens)}
                </div>
                <div style={styles.divider} />
            </React.Fragment>
        )
    }

    renderPastDrops() {
        if (this.state.prev_tokens.length === 0) {
            return null
        }

        return (
            <React.Fragment>
                <div style={styles.pastDrops}>
                    Past Drops
                </div>
                <div style={styles.subDropText}>
                    List of all previous drops previously launched on Nanobash
                </div>
                <div style={styles.collectionList}>
                    {this.renderCollections(this.state.prev_tokens)}
                </div>
            </React.Fragment>
        )
    }

    objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    }

    updateTokens(page) {
        const body = {
            page: page || 1,
            prev: true
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/tokens?${this.objToQueryString(body)}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        prev_tokens: res.tokens,
                    });
                }
            })
            .catch(err => {
            })
    }

    renderSignupBanner() {
        return (
            <div style={styles.bannerMini}>
                <div style={styles.bannerTitle}>
                    Buy & Sell Premium NFTs
                </div>
                <div style={styles.bannerText}>
                    A highly curated collection of NFTs from the top artists in the world!
                </div>
                <div style={styles.buttons}>
                    <a href={'/users/new'} style={styles.learnMore}>
                        <SubmitButton type="submit" value="Sign Up" style={styles.submitButton} />
                    </a>
                    <a href={'/about'}>
                        <input type="submit" value="Learn More" style={styles.submitButtonInfo} />
                    </a>
                </div>
            </div>
        )
    }

    renderBanner() {
        return (
            <div style={styles.bannerMini}>
                <div style={styles.bannerTitle}>
                    Buy & Sell Premium NFTs
                </div>
                <div style={styles.bannerText}>
                    A highly curated collection of NFTs from the top artists in the world!
                </div>
                <div style={styles.buttons}>
                    <a href={'/referrals'} style={styles.learnMore}>
                        <SubmitButton type="submit" value="Claim NFTs" style={styles.submitButton} />
                    </a>
                    <a href={'/about'}>
                        <input type="submit" value="Learn More" style={styles.submitButtonInfo} />
                    </a>
                </div>
            </div>
        )
    }

    referralLink = () => {
        return window.location.protocol + "//" + window.location.host + `?referred_by=${this.props.user.username}`;
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.referralLink())
        this.state.toasts.push({title: 'Referral link copied', information:  `You have successfully copied your referral link`, id: toastId++})
        this.setState({toasts: this.state.toasts})
    }

    renderReferral = () => {
        return (
            <div style={styles.referralBanner}>
                <div style={styles.referralContainer}>
                    <div style={styles.h1}>
                        {`Receive Free NFTs`}
                    </div>
                    <FormContainer style={styles.formContainer}>
                        <div style={styles.referralInner}>
                            <div style={styles.referralLinkTitle}>
                                Referral Link
                            </div>
                            <div style={styles.referralDescription}>
                                Receive free NFTs for successful referrals
                            </div>
                            <div style={styles.referralLinkText}>
                                {this.referralLink()}
                            </div>
                            <SubmitButton type="submit" value="Copy" onClick={this.copyToClipboard} style={styles.submitButton} />
                        </div>
                    </FormContainer>
                    <a href={'/referrals'}>
                        <input type="submit" value="View Rewards" style={styles.submitButtonMini} />
                    </a>
                </div>
            </div>
        )
    }

    renderDropSections() {
        const tokens = []

        if (this.state.active_tokens.length > 0) {
            tokens.push({
                method: this.renderActiveCollections.bind(this)
            })
        }
        if (this.state.future_tokens.length > 0) {
            tokens.push({
                method: this.renderNextDrops.bind(this)
            })
        }
        if (this.state.prev_tokens.length > 0) {
            tokens.push({
                method: this.renderPastDrops.bind(this)
            })
        }

        return tokens.map((token, index) => {
            return (
                <div style={{...styles.collectionContainer, backgroundColor: index % 2 === 0 ? 'none' : 'white'}}>
                    {token.method()}
                </div>
            )
        })
    }

    renderArtistApplication = () => {
        return (
            <div style={styles.applicationContainer}>
                <div style={styles.applyTitle}>
                    Launch Art On Nanobash
                </div>
                <div style={styles.applyDescription}>
                    Fill out the application if you are interested in launching art on Nanobash
                </div>
                <a href={'https://forms.gle/Pft7K3kKW4qvigat7'}>
                    <input type="submit" value="Apply" style={styles.submitButton} />
                </a>
            </div>
        )
    }

    render () {
        return (
            <div style={styles.totalContainer}>
                {this.props.user ? this.renderBanner() : this.renderSignupBanner()}
                {this.renderDropSections()}
                {this.state.totalPages > 1 && (
                    <div style={styles.container}>
                        <Paginator updateFn={this.updateTokens.bind(this)} totalPages={this.state.totalPages} />
                    </div>
                )}
                {this.props.user && this.renderReferral()}
                {this.renderArtistApplication()}
                {this.renderToasts()}
            </div>
        );
    }
}

export default DropPage
