import React from "react"
import Header from "./Header";
import DateTimePicker from "react-datetime-picker";
import styled from "styled-components";
import ErrorSection from "./ErrorSection";

const styles = {
    container: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
    },
    h1: {
        fontSize: 36,
        fontWeight: 600,
        marginTop: '35px',
    },
    subheading: {
        fontSize: 18,
        color: '#333',
        marginBottom: '25px',
        marginTop: '10px',
    },
    formContainer: {
        maxWidth: '800px',
        padding: '30px',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: '20px',
    },
    formDescription: {

    },
    inputSection: {
        width: '100%',
        marginBottom: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    inputLabelMini: {
        fontSize: '16px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBoxMini: {
        fontSize: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    resetPassword: {
        marginTop: '40px',
    }
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.input`
:hover {
    background-color: #49ab67 !important;
}
`

class Signup extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            password_confirmation: '',
            email: '',
            code: '',
        }
    }

    submitForm = () => {

        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = this.state

        fetch(`${window.location.origin}/api/v0/users`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.success) {
                    if (res.received_promotion) {
                        window.location.href = `/users/${res.user.slug}`
                    } else {
                        window.location.href = '/'
                    }
                } else {
                    this.setState({errors: res.errors})
                    scroll(0,0)
                }
            })
            .catch(err => {
                this.setState({errors: err.errors})
                scroll(0,0)
            })
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    renderReferredBy = () => {
        return (
            <div style={styles.inputSection}>
                <div style={styles.inputLabelMini}>
                    Referred by
                </div>
                <div style={styles.inputBoxMini}>
                    {this.props.referred_by.username}
                </div>
            </div>
        )
    }

    render () {
        return (
            <React.Fragment>
                <div style={styles.container}>
                    <div style={styles.h1}>
                        Signup
                    </div>
                    <div style={styles.subheading}>
                        Create an account to start trading NFTs!
                    </div>
                    <FormContainer style={styles.formContainer}>
                        <ErrorSection errors={this.state.errors} />

                        <div style={styles.formDescription}>

                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Email
                            </div>
                            <input style={styles.inputBox} maxLength="100" name="email" value={this.state.email} onChange={this.onChange.bind(this)} />
                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Username
                            </div>
                            <input style={styles.inputBox} maxLength="20" name="username" value={this.state.username} onChange={this.onChange.bind(this)} />
                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Password
                            </div>
                            <input style={styles.inputBox} maxLength="100" type="password" name="password" value={this.state.password} onChange={this.onChange.bind(this)} />
                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Confirm Password
                            </div>
                            <input style={styles.inputBox} maxLength="100" type="password" name="password_confirmation" value={this.state.password_confirmation} onChange={this.onChange.bind(this)} />
                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Promotion Code - (Optional)
                            </div>
                            <input style={styles.inputBox} maxLength="20" name="code" value={this.state.code} onChange={this.onChange.bind(this)} />
                        </div>

                        {this.props.referred_by && this.renderReferredBy()}

                        <SubmitButton type="submit" onClick={this.submitForm.bind(this)} name="commit" value="Signup" style={styles.submitButton} data-disable-with="
Signup" />
                    </FormContainer>
                    <div style={styles.resetPassword}>
                        <a style={{color: '#5dd982'}} href={'/users/reset_password'}>
                            Forgot Password
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Signup