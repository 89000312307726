import React from "react"
import styled from "styled-components";
import ErrorSection from "./ErrorSection";
import Toast from "./Toast";

const styles = {
    inputSection: {
        width: '100%',
        marginBottom: '20px',
        marginTop: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    pageTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        margin: '20px 0 0',
    },
    cardTitle: {
        paddingBottom: '10px',
        boxSizing: 'border-box',
        marginBottom: '30px',
        borderBottom: '1px solid #CCC',
        fontSize: '24px',
        fontWeight: '600',
        width: '100%',
    },
    cardElement: {
        margin: '20px 0',
    },
    cardContainer: {
        display: 'flex',
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
        border: '1px solid #CCC',
        marginBottom: '10px',
        cursor: 'pointer',
        alignItems: 'center',
    },
    selected: {
        border: '1px solid #CCC',
        backgroundColor: '#5dd982',
        width: '20px',
        height: '20px',
        borderRadius: '20px',
    },
    unselected: {
        border: '1px solid #CCC',
        backgroundColor: '#EEE',
        width: '20px',
        height: '20px',
        borderRadius: '20px',
    },
    cardSection: {
        marginLeft: '50px',
        display: 'flex',
        flexDirection: 'column',
    },
    miniTitle: {
        color: '#999',
        fontSize: '14px',
    },
    miniText: {
        fontWeight: '600',
        fontSize: '16px',
    },
    emptyBox: {
        width: '100%',
        maxWidth: '1000px',
        boxSizing: 'border-box',
        backgroundColor: '#EEE',
        textAlign: 'center',
        padding: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#333',
        marginTop: '20px',
    },
    cardElementWrapper: {
        padding: '0 10px',
        boxSizing: 'border-box',
        border: '1px solid #CCC',
        borderRadius: '5px',
        marginBottom: '20px',
    }
}

let toastId = 1
let processing = false

class SettingsPayment extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            stripe_payment_method_id: props.user.stripe_payment_method_id,
            card_details: null,
            cardElement: null,
            processed: false,
            fetchedCards: false,
            intent: null,
            toasts: [],
        }
    }

    fetchCardInfo = () => {
        const body = {}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/users/fetch_card_info`, {
            method: "POST",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        intent: res.intent,
                        card_details: res.card_details,
                        fetchedCards: true,
                    }, () => {
                        this.setupPage()
                    });
                }
            })
            .catch(err => {
            })
    }

    updateUserSetting(stripe_payment_method_id) {
        this.state.card_details.forEach(cd => {
            cd.selected = cd.payment_method_id === stripe_payment_method_id
        })
        this.setState({card_details: this.state.card_details})

        const body = {stripe_payment_method_id: stripe_payment_method_id}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/users/${this.props.user?.slug}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log('Got response from update', res)
                if (res.success) {
                    this.state.toasts.push({title: 'Card set', information:  `You have successfully set your new default credit card`, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                } else {
                }
            })
            .catch(err => {

            })
    }

    fetchPaymentMethod(payment_method_id) {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/payment_methods/${payment_method_id}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.state.card_details.forEach(cd => cd.selected = false)
                    res.payment_method.selected = true
                    this.state.card_details.unshift(res.payment_method)
                    this.setState({
                        card_details: this.state.card_details,
                    });
                }
            })
            .catch(err => {
            })
    }

    componentDidMount() {
        this.fetchCardInfo()
    }

    setupPage = () => {
        var stripe = Stripe(this.props.stripe_publishable_key);

        var elements = stripe.elements();
        var cardElement = elements.create('card', {
            style: {
                base: {
                    iconColor: '#5dd982',
                    backgroundColor: '#FFF',
                    padding: 10,
                    fontSize: '24px',
                    fontWeight: '500',
                    fontFamily: 'Lato, Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSmoothing: 'antialiased',
                },
            },
        });
        cardElement.mount('#card-element');
        this.setState({cardElement: cardElement})

        var cardholderName = document.getElementById('cardholder-name');
        var setupForm = document.getElementById('setup-form');
        var clientSecret = setupForm.dataset.secret;

        const trueThis = this

        setupForm.addEventListener('submit', function(ev) {
            ev.preventDefault();
            if (processing) {
                return
            }
            processing = true
            stripe.confirmCardSetup(
                clientSecret,
                {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: cardholderName.value,
                        },
                    },
                }
            ).then(function(result) {
                if (result.error) {
                    // Display error.message in your UI.
                    console.log(result.error)
                    if (result.error.message === "You passed an empty string for 'payment_method_data[billing_details][name]'. We assume empty values are an attempt to unset a parameter; however 'payment_method_data[billing_details][name]' cannot be unset. You should remove 'payment_method_data[billing_details][name]' from your request or supply a non-empty value.") {
                        result.error.message = "Full name missing."
                    }
                    trueThis.setState({errors: [result.error.message]})
                    processing = false
                } else {
                    // The setup has succeeded. Display a success message.
                    // this.setState({stripe_payment_method_id: result.setupIntent.payment_method})
                    trueThis.setState({errors: null, processed: true})

                    trueThis.state.cardElement.clear()
                    processing = false
                    trueThis.updateUserSetting(result.setupIntent.payment_method)
                    trueThis.fetchPaymentMethod(result.setupIntent.payment_method)
                }
            });
        });
    }

    renderCard = (card) => {
        return (
          <div style={styles.cardContainer} onClick={this.updateUserSetting.bind(this, card.payment_method_id)}>
              <div>
                  <div style={{...(card.selected ? styles.selected : styles.unselected)}} />
              </div>
              <div style={styles.cardSection}>
                  <div style={styles.miniTitle}>
                      Brand
                  </div>
                  <div style={styles.miniText}>
                      {card.brand}
                  </div>
              </div>
              <div style={styles.cardSection}>
                  <div style={styles.miniTitle}>
                      Last 4
                  </div>
                  <div style={styles.miniText}>
                      {card.last4}
                  </div>
              </div>
          </div>
        )
    }

    renderCards = () => {
        if (this.state.card_details.length === 0) {
            return this.renderEmptyState()
        }
        return this.state.card_details.map(this.renderCard)
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderEmptyState() {
        return (
            <div style={styles.emptyBox}>
                You haven't added any payment methods yet. Please fill out the form below to add one.
            </div>
        )
    }

    renderForm() {
        if( this.state.processed ) {
            return null
        }

        // return (
        //     <React.Fragment>
        //         <div style={styles.cardTitle}>
        //             Add Card
        //         </div>
        //         <div style={styles.emptyBox}>
        //             Site currently in demo mode, please use the balance on your account to test the site instead of a credit card.
        //         </div>
        //     </React.Fragment>
        // )

        return (
            <React.Fragment>
                <div style={{width: '100%', margin: '30px 0', height: '1px'}}>

                </div>
                <div style={styles.cardTitle}>
                    Add Card
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Full Name
                    </div>
                    <input style={styles.inputBox} placeholder={'Full Name'} id="cardholder-name" type="text" />
                </div>
                <form id="setup-form" data-secret={this.state.intent.client_secret}>
                    <div style={styles.cardElementWrapper}>
                        <div style={styles.cardElement} id="card-element" />
                    </div>
                    <button style={styles.submitButton} id="card-button">
                        Add Card
                    </button>
                </form>
            </React.Fragment>
        )
    }

    render() {
        if (!this.state.fetchedCards) {
            return (
                <div>
                    <div style={styles.cardTitle}>
                        Select Card
                    </div>
                    <div>
                        Loading...
                    </div>
                </div>
            )
        }

        return (
            <div>
                <ErrorSection errors={this.state.errors} />
                <div>
                    <div style={styles.cardTitle}>
                        Select Card
                    </div>
                    {this.renderCards()}
                    {this.renderForm()}
                    <div id="card-element" />
                </div>
                {this.renderToasts()}
            </div>
        )
    }
}

export default SettingsPayment