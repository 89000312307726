import React from "react"
import styled from "styled-components";
import SettingsProfile from "./SettingsProfile";
import SettingsCashout from "./SettingsCashout";
import SettingsWithdrawNFT from "./SettingsWithdrawNFT";
import SettingsDepositNFT from "./SettingsDepositNFT";
import SettingsPayment from "./SettingsPayment";
import SettingsNotifications from "./SettingsNotifications";
import SettingsVerification from "./SettingsVerification";
import SettingsTwoFactorAuth from "./SettingsTwoFactorAuth";

const styles = {
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    innerContainer: {
        fontFamily: 'Lato, Arial',
        boxSizing: 'border-box',
        display: 'flex',
        padding: '20px',
        width: '100%',
        maxWidth: '1120px',
    },
    innerContainerMobile: {
        fontFamily: 'Lato, Arial',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        width: '100%',
        maxWidth: '960px',
    },
    selector: {
        width: '300px',
        flexShrink: 0,
        border: '1px solid #CCC',
        borderRadius: '5px',
        backgroundColor: 'white',
    },
    tab: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '15px 30px',
        cursor: 'pointer',
    },
    screenContainer: {
        padding: '20px',
        maxWidth: '800px',
        width: '100%',
        marginLeft: '20px',
        boxSizing: 'border-box',
        borderRadius: '20px',
    },
    screenContainerMobile: {
        padding: '20px',
        maxWidth: '800px',
        width: '100%',
        marginTop: '20px',
        boxSizing: 'border-box',
        borderRadius: '20px',
    },
}

const Tab = styled.div`
transition: 0.2s;
:hover {
    background-color: #EEE !important;
}
`

const ScreenContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

class Settings extends React.Component {

    constructor(props) {
        super(props)

        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        this.state = {
            screens: [
                {
                    name: 'Profile',
                    slug: 'profile',
                    screen: <SettingsProfile {...props} />,
                },{
                    name: 'Verification',
                    slug: 'verification',
                    screen: <SettingsVerification {...props} />,
                },{
                    name: 'Notifications',
                    slug: 'notifications',
                    screen: <SettingsNotifications {...props} />,
                },{
                    name: 'Payment Methods',
                    slug: 'payment',
                    screen: <SettingsPayment {...props} />,
                },{
                    name: 'Cashout',
                    slug: 'cashout',
                    screen: <SettingsCashout {...props} />,
                },{
                    name: 'Withdraw NFTs',
                    slug: 'withdraw_nft',
                    screen: <SettingsWithdrawNFT {...props} />,
                },{
                    name: 'Deposit NFTs',
                    slug: 'deposit_nft',
                    screen: <SettingsDepositNFT {...props} />,
                },{
                    name: 'Two Factor Auth',
                    slug: 'two_factor_auth',
                    screen: <SettingsTwoFactorAuth {...props} />,
                },{
                    name: 'Log Out',
                    slug: 'log_out',
                    screen: <div />,
                },
            ],
            mobile: window.innerWidth < 1000,
        }
        this.state.currentScreen = this.state.screens[0]
        if (urlParams.get('screen')) {
            const paramScreen = this.state.screens.filter((screen) => screen.slug === urlParams.get('screen'))
            if (paramScreen.length === 1) {
                this.state.currentScreen = paramScreen[0]
            }
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    logOut = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {}

        const outsideThis = this

        fetch(`${window.location.origin}/api/v0/sessions/logout`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                outsideThis.setState({user: null, loggedIn: false})
                window.location.href = '/?logout=true'
            })
            .catch(err => {
                outsideThis.setState({user: null, loggedIn: false})
                window.location.href = '/?logout=true'
            })
    }

    clickTab(tab) {
        if (tab.slug === 'log_out') {
            this.logOut()
        } else {
            window.history.pushState(tab.name, tab.name, `/settings/?screen=${tab.slug}`);
            this.setState({currentScreen: tab})
        }
    }

    renderTab(tab, includeBorder) {
        return (
            <Tab onClick={this.clickTab.bind(this, tab)} style={{...styles.tab, borderBottom: includeBorder ? '1px solid #CCC' : null, borderLeft: tab.slug === this.state.currentScreen.slug ? '3px solid #5dd982' : 'none'}}>
                {tab.name}
            </Tab>
        )
    }

    renderSelector() {
        return (
            <div style={styles.selector}>
                {this.state.screens.map((screen, index) => this.renderTab(screen, index + 1 !== this.state.screens.length))}
            </div>
        )
    }

    renderScreen() {
        return this.state.currentScreen.screen
    }

    render () {
        return (
            <div style={styles.container}>
                <div style={this.state.mobile ? styles.innerContainerMobile : styles.innerContainer}>
                    <span>
                        {this.renderSelector()}
                    </span>
                    <ScreenContainer style={this.state.mobile ? styles.screenContainerMobile : styles.screenContainer}>
                        {this.renderScreen()}
                    </ScreenContainer>
                </div>
            </div>
        );
    }
}

export default Settings
