import React from "react"
import styled from "styled-components";

const styles = {
    container: {
        display: 'inline-flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        border: '1px solid #CCC',
        borderRadius: '5px',
    },
    box: {
        borderRight: '1px solid #CCC',
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'all .2s ease',
    },
    active: {
        transition: 'none',
        backgroundColor: '#5dd982',
        color: 'white',
    }
}

const Box = styled.div`
:hover {
    background-color: #fafafa;
}
`

//     .paginator {
//     display: inline-flex;
//     flex-direction: row;
//     background-color: $white;
//     border: 1px solid $grayC;
//     border-radius: 5px;
//     color: $primary-color;
// }
//
// .paginator-box {
//     border-right: 1px solid $grayC;
//     padding: 10px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     cursor: pointer;
//     transition: all .2s ease;
//
// &:hover {
//         background-color: $gray-table;
//     }
//
// &.active {
//         transition: none;
//         background-color: $primary-color;
//         color: $white;
//     @extend .font-bold;
//         padding-top: 6px;
//     }
//
// &.left {
//         border-radius: 5px 0 0 5px;
//     }
//
// &.right {
//         border-right: none;
//         border-radius: 0 5px 5px 0;
//     }
// }

class Paginator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: props.currentPage || 1,
            totalPages: props.totalPages || 1,
        };
    }

    gotoPage(page) {
        console.log("Reached")
        if(this.props.updateFn){
            console.log("page", page)
            this.props.updateFn(page)
            this.setState({currentPage: page});
        }
    }

    prevClick() {
        if (this.state.currentPage > 1) {
            this.gotoPage(this.state.currentPage - 1);
        }
    }

    nextClick() {
        if (this.state.currentPage < this.props.totalPages) {
            this.gotoPage(this.state.currentPage + 1);
        }
    }

    renderPages(pageAmount, currentPage) {
        const pages = [];
        for(let i = (currentPage - 3 <= 0) ? 0 : currentPage - 3; i < ((currentPage + 2 > pageAmount) ? pageAmount : (currentPage + 2)); i++){
            pages.push(<Box style={{...styles.box, ...(currentPage === i + 1 ? styles.active : {})}} onClick={() => this.gotoPage(i + 1)} key={i}> {i + 1} </Box>);
        }
        return pages;
    }

    render() {
        return (
            <div style={styles.container}>
                <Box style={styles.box} onClick={() => this.gotoPage(1)}>
                    First
                </Box>
                {this.state.currentPage >= 4 ?
                    <Box style={styles.box} onClick={() => this.prevClick()}>
                        <i className="fa fa-chevron-left"/>
                    </Box>
                    : null }
                { this.renderPages(this.props.totalPages, this.state.currentPage || 1) }
                {this.state.currentPage <= this.props.totalPages - 3 ?
                    <Box style={styles.box} onClick={() => this.nextClick()}>
                        <i className="fa fa-chevron-right" />
                    </Box>
                    : null}
                <Box style={{...styles.box, borderRight: 'none'}} onClick={() => this.gotoPage(this.props.totalPages)}>
                    Last
                </Box>
            </div>
        )
    }
}

export default Paginator