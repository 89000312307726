import React from "react"
import styled from "styled-components"
import moment from 'moment'
import Paginator from "./Paginator";
import Toast from "./Toast";

const styles = {
    container: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        maxWidth: '1000px',
        margin: '20px auto',
    },
    h1: {
        fontSize: 36,
        fontWeight: 600,
        marginBottom: '20px',
        marginTop: '20px',
    },
    transaction: {
        margin: '10px 0',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    link: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
    },
    transactionRight: {
        padding: '20px 20px 20px 0',
        boxSizing: 'border-box',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    orderMobile: {
        margin: '10px',
        boxSizing: 'border-box',
        borderRadius: '10px',
        display: 'inline-flex',
        flexDirection: 'column',

    },
    linkMobile: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
    },
    transactionRightMobile: {
        padding: '20px 0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
    },
    date: {
        flexBasis: '10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 10px 0',
    },
    dateMobile: {
        flexBasis: '10%',
        display: 'inline-flex',
        flexDirection: 'column',
    },
    month: {
        fontSize: '12px'
    },
    day: {
        fontWeight: '600',
    },
    transactionType: {
        flexBasis: '10%',
        fontWeight: '600',
        color: '#5dd982',
    },
    info: {
        flexBasis: '50%',
    },
    name: {
        fontWeight: '600',
    },
    author: {
        color: '#999',
        fontSize: '14px',
    },
    price: {
        flexBasis: '20%',
    },
    imageContainer: {
        width: '100px',
        borderBottomLeftRadius: '10px',
        borderTopLeftRadius: '10px',
        height: '100px',
    },
    imageContainerMobile: {
        width: '200px',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        height: '200px',
    },
    tab: {
        margin: 10,
        padding: 10,
        borderRadius: '5px',
        cursor: 'pointer',
    },
    tabSection: {
        display: 'flex',
    },
    emptyBox: {
        width: '100%',
        margin: '20px 20px 0',
        maxWidth: '1000px',
        backgroundColor: '#EEE',
        padding: '100px',
        boxSizing: 'border-box',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#333',
    },
    divider: {
        width: '1px',
        height: '100px',
        backgroundColor: '#EEE',
    },
    extraInfo: {
        padding: '15px',
        boxSizing: 'border-box',
        borderBottomRightRadius: '10px',
        borderTopRightRadius: '10px',
        alignItems: 'center',
        height: '100px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    dividerMobile: {
        width: '100%',
        height: '1px',
        backgroundColor: '#EEE',
    },
    extraInfoMobile: {
        padding: '15px',
        boxSizing: 'border-box',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        alignItems: 'center',
        height: '50px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        textAlign: 'center',
    },
    leftInfo: {
        width: '100%',
    },
    cancel: {
        textAlign: 'center',
        color: '#5dd982',
        fontWeight: '600',
    }
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
transition: 0.3s;
:hover {
    box-shadow: rgb(0, 0, 0, 0.2) 0px 4px 16px !important;
}
`

const ExtraInfo = styled.div`
background-color: #F6F6F6;
transition: 0.3s;
:hover {
    background-color: #E9E9E9 !important;
}
`

let orderTypes = {
    'limit_sell': "Limit Sell",
    'limit_buy': 'Limit Buy',
}

let toastId = 1

class Transactions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            purchasesTotalPages: props.purchases_total_pages,
            ordersTotalPages: props.orders_total_pages,
            orders: props.orders,
            purchases: props.purchases,
            activeTab: 'orders',
            toasts: [],
        }
    }

    renderTransactions() {
        let items = []
        if (this.state.activeTab === 'orders') {
            items = items.concat(this.state.orders)
        }

        if (this.state.activeTab === 'purchases') {
            items = items.concat(this.state.purchases)
        }

        if (items.length === 0) {
            return this.renderEmptyState()
        }

        return (
            <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
                {items.map(this.renderTransaction)}
            </div>
        )
    }

    cancelOrder = (order) => {

        console.log(order.status)
        if (order.status !== 'pending') {
            return
        }

        const body = {}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/orders/${order.id}/cancel`, {
            method: "POST",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    console.log('res', res)
                    if (res.order.status === 'pending_cancelled') {
                        res.order.status = 'Cancelling'
                    }
                    for (let i = 0; i < this.state.orders.length; i++) {
                        if (this.state.orders[i].id === res.order.id) {
                            this.state.orders[i] = res.order
                            console.log(this.state.orders)
                            this.setState({orders: this.state.orders})
                        }
                    }
                    this.state.toasts.push({title: 'Order Cancelling', information:  `You have successfully requested to cancel the order`, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                } else {
                    this.state.toasts.push({title: 'Cancel Failed', information: res.message, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                }
            })
            .catch(err => {
            })
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderOrderMobile(order) {
        return (
            <FormContainer style={styles.orderMobile}>
                <a style={styles.linkMobile} href={`/orders/${order.id}`}>
                    <div style={styles.imageContainerMobile}>
                        <img style={{width: '100%', maxWidth: '200px', height: '200px', objectFit: 'cover', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}} src={order.token.thumbnail_url} />
                    </div>
                    <div style={styles.transactionRightMobile}>
                        <div style={styles.info}>
                            <div style={styles.name}>
                                {order.token.name}
                            </div>
                            <div style={styles.author}>
                                {'by ' + order.token.user.username}
                            </div>
                        </div>
                        <div style={{...styles.dateMobile, margin: '20px 0'}}>
                            <div style={styles.month}>
                                {moment(new Date(order.created_at)).format("MMM")}
                            </div>
                            <div style={styles.day}>
                                {moment(new Date(order.created_at)).format("DD")}
                            </div>
                        </div>
                        <div style={styles.transactionType}>
                            {orderTypes[order.order_type]}
                        </div>
                        <div style={{...styles.price, margin: '20px 0',}}>
                            <div style={styles.author}>
                                Order Price
                            </div>
                            <div style={styles.name}>
                                {(order.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                        <div style={styles.price}>
                            <div style={styles.author}>
                                # Filled
                            </div>
                            <div style={styles.name}>
                                {order.quantity_filled + ' / ' + order.quantity}
                            </div>
                        </div>
                        <div style={{...styles.price, margin: '20px 0 0',}}>
                            <div style={styles.author}>
                                Status
                            </div>
                            <div style={styles.name}>
                                {this.capitalize(order.status)}
                            </div>
                        </div>
                    </div>
                </a>
                <div style={styles.dividerMobile} />
                <ExtraInfo style={styles.extraInfoMobile} onClick={this.cancelOrder.bind(this, order)}>
                    <div style={styles.leftInfo}>
                        <div style={styles.cancel}>
                            {order.status === 'pending' ? 'Cancel Order' : ''}
                        </div>
                    </div>
                </ExtraInfo>
            </FormContainer>
        )
    }

    renderOrder(order) {
        return (
            <FormContainer style={styles.transaction}>
                <a style={styles.link} href={`/orders/${order.id}`}>
                    <div style={styles.imageContainer}>
                        <img style={{width: '100%', maxWidth: '100px', height: '100px', objectFit: 'cover', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'}} src={order.token.thumbnail_url} />
                    </div>
                    <div style={styles.transactionRight}>
                        <div style={styles.date}>
                            <div style={styles.month}>
                                {moment(new Date(order.created_at)).format("MMM")}
                            </div>
                            <div style={styles.day}>
                                {moment(new Date(order.created_at)).format("DD")}
                            </div>
                        </div>
                        <div style={{...styles.transactionType, paddingRight: '10px'}}>
                            {orderTypes[order.order_type]}
                        </div>
                        <div style={{...styles.info, paddingRight: '10px'}}>
                            <div style={styles.name}>
                                {order.token.name}
                            </div>
                            <div style={styles.author}>
                                {'by ' + order.token.user.username}
                            </div>
                        </div>
                        <div style={styles.price}>
                            <div style={styles.author}>
                                Order Price
                            </div>
                            <div style={styles.name}>
                                {(order.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                        <div style={styles.price}>
                            <div style={styles.author}>
                                # Filled
                            </div>
                            <div style={styles.name}>
                                {order.quantity_filled + ' / ' + order.quantity}
                            </div>
                        </div>
                        <div style={styles.price}>
                            <div style={styles.author}>
                                Status
                            </div>
                            <div style={styles.name}>
                                {this.capitalize(order.status)}
                            </div>
                        </div>
                    </div>
                </a>
                <div style={styles.divider} />
                <ExtraInfo style={styles.extraInfo} onClick={this.cancelOrder.bind(this, order)}>
                    <div style={styles.leftInfo}>
                        <div style={styles.cancel}>
                            Cancel Order
                        </div>
                    </div>
                </ExtraInfo>
            </FormContainer>
        )
    }

    capitalize = (str) => (str[0].toUpperCase() + str.slice(1, str.length))

    renderPurchaseMobile(purchase) {
        return (
            <FormContainer style={styles.orderMobile}>
                <a style={styles.linkMobile} href={`/purchases/${purchase.id}`}>
                    <div style={styles.imageContainerMobile}>
                        <img style={{width: '100%', maxWidth: '200px', height: '200px', objectFit: 'cover', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}} src={purchase.token.thumbnail_url} />
                    </div>
                    <div style={styles.transactionRightMobile}>
                        <div style={styles.info}>
                            <div style={styles.name}>
                                {purchase.token.name}
                            </div>
                            <div style={styles.author}>
                                {'by ' + purchase.token.user.username}
                            </div>
                        </div>
                        <div style={{...styles.dateMobile, margin: '20px 0'}}>
                            <div style={styles.month}>
                                {moment(new Date(purchase.created_at)).format("MMM")}
                            </div>
                            <div style={styles.day}>
                                {moment(new Date(purchase.created_at)).format("DD")}
                            </div>
                        </div>
                        <div style={styles.transactionType}>
                            {purchase.price === 0 ? 'Referral' : 'Purchase'}
                        </div>
                        <div style={{...styles.price, margin: '20px 0',}}>
                            <div style={styles.author}>
                                Price (Each)
                            </div>
                            <div style={styles.name}>
                                {(purchase.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                        <div style={styles.price}>
                            <div style={styles.author}>
                                Quantity
                            </div>
                            <div style={styles.name}>
                                {purchase.quantity}
                            </div>
                        </div>
                    </div>
                </a>
            </FormContainer>
        )
    }

    renderPurchase(purchase) {
        return (
            <a style={{width: '100%'}} href={`/purchases/${purchase.id}`}>
                <FormContainer style={styles.transaction}>
                    <div style={styles.imageContainer}>
                        <img style={{width: '100%', maxWidth: '100px', height: '100px', objectFit: 'cover', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'}} src={purchase.token.thumbnail_url} />
                    </div>
                    <div style={styles.transactionRight}>
                        <div style={styles.date}>
                            <div style={styles.month}>
                                {moment(new Date(purchase.created_at)).format("MMM")}
                            </div>
                            <div style={styles.day}>
                                {moment(new Date(purchase.created_at)).format("DD")}
                            </div>
                        </div>
                        <div style={styles.transactionType}>
                            {purchase.price === 0 ? 'Referral' : 'Purchase'}
                        </div>
                        <div style={styles.info}>
                            <div style={styles.name}>
                                {purchase.token.name}
                            </div>
                            <div style={styles.author}>
                                {'by ' + purchase.token.user.username}
                            </div>
                        </div>
                        <div style={styles.price}>
                            <div style={styles.author}>
                                Price (Each)
                            </div>
                            <div style={styles.name}>
                                {(purchase.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                        <div style={styles.price}>
                            <div style={styles.author}>
                                Quantity
                            </div>
                            <div style={styles.name}>
                                {purchase.quantity}
                            </div>
                        </div>
                    </div>
                </FormContainer>
            </a>
        )
    }

    renderTransaction = (transaction) => {
        if (transaction.type === "order") {
            return this.renderOrderMobile(transaction)
        }
        if (transaction.type === "purchase") {
            return this.renderPurchaseMobile(transaction)
        }
    }

    objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    }

    updateOrders(page) {
        const body = {
            user_id: this.props.user.id,
            page: page || 1
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/orders?${this.objToQueryString(body)}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        orders: res.orders,
                    });
                }
            })
            .catch(err => {
            })
    }

    renderTabs() {
        return (
            <div style={styles.tabSection}>
                <div onClick={() => this.setState({activeTab: 'orders'})} style={{...styles.tab, border: this.state.activeTab === 'orders' ? '1px solid #333' : '1px solid #CCC'}}>
                    Orders
                </div>
                <div onClick={() => this.setState({activeTab: 'purchases'})} style={{...styles.tab, border: this.state.activeTab === 'purchases' ? '1px solid #333' : '1px solid #CCC'}}>
                    Purchases
                </div>
            </div>
        )
    }

    updatePurchases(page) {
        const body = {
            user_id: this.props.user.id,
            page: page || 1
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/purchases?${this.objToQueryString(body)}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        purchases: res.purchases,
                    });
                }
            })
            .catch(err => {
            })
    }

    renderEmptyState() {
        return (
            <div style={styles.emptyBox}>
                {`You haven't made any ${this.state.activeTab} yet, try making one on the `}
                <a style={{color: '#5dd982', marginLeft: '5px', fontWeight: '600'}} href={'/tokens'}>
                    Token Page
                </a>
                !
            </div>
        )
    }

    render () {
        return (
            <React.Fragment>
                <div style={styles.container}>
                    <div style={styles.h1}>
                        Transactions
                    </div>
                    {this.renderTabs()}
                    {this.renderTransactions()}
                    <div style={{width: '100%', height: '10px'}} />
                    {this.state.ordersTotalPages > 1 && this.state.activeTab === 'orders' ? <Paginator updateFn={this.updateOrders.bind(this)} totalPages={this.state.ordersTotalPages} /> : null}
                    {this.state.purchasesTotalPages > 1 && this.state.activeTab === 'purchases' ? <Paginator updateFn={this.updatePurchases.bind(this)} totalPages={this.state.purchasesTotalPages} /> : null}
                    {this.renderToasts()}
                </div>
            </React.Fragment>
        );
    }
}

export default Transactions
