import React from "react"
import styled from "styled-components"
import DateTimePicker from 'react-datetime-picker'
import axios from "axios"

const styles = {
    container: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
    },
    h1: {
        fontSize: 36,
        fontWeight: 600,
        marginBottom: '25px',
        marginTop: '35px',
    },
    formContainer: {
        maxWidth: '800px',
        padding: '30px',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: '20px',
    },
    termTitle: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    termInfo: {
        fontSize: '14px',
        color: '#333',
        marginBottom: '20px',
    }
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

class Terms extends React.Component {
    render () {
        return (
            <React.Fragment>
                <div style={styles.container}>
                    <div style={styles.h1}>
                        {`Terms of Service`}
                    </div>
                    <FormContainer style={styles.formContainer}>
                        <div style={styles.termTitle}>
                            Account Registration
                        </div>
                        <div style={styles.termInfo}>
                            <p>
                                By Registering an account you guarantee that all information you provide is accurate and you have not previously registered an account on NanoBash.
                            </p>
                            <p>
                                If a user is found to have more than a single account then balances and FTs in both accounts will be frozen and access to both accounts will be prevented.
                            </p>
                        </div>
                        <div style={styles.termTitle}>
                            Account Termination
                        </div>
                        <div style={styles.termInfo}>
                            Nanobash reserves the right to freeze, suspend, or terminate accounts in the event of fraud, bug abuse, or other malicious acts.
                        </div>
                        <div style={styles.termTitle}>
                            Communication
                        </div>
                        <div style={styles.termInfo}>
                            We reserve the right to send correspondence via email and text for account activity, transactions, notifications, and marketing. Opt in/out can be done via your account profile.
                        </div>
                        <div style={styles.termTitle}>
                            Changes To The Terms Of Use
                        </div>
                        <div style={styles.termInfo}>
                            <p>
                                These Terms of Use are effective as of March 12, 2021 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
                            </p>
                            <p>
                                We reserve the right to update or change our Terms of Use at any time and you should check this page periodically. Your continued use of the Nanobash after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Terms of Use.
                            </p>
                        </div>
                        <div style={styles.termTitle}>
                            Contact Us
                        </div>
                        <div style={styles.termInfo}>
                            If you have any questions about the Terms of Use, please contact us.
                        </div>
                    </FormContainer>
                </div>
            </React.Fragment>

        );
    }
}

export default Terms
