import React from "react"

const styles = {
    container: {
        width: '100%',
        backgroundColor: '#ffe3de',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontWeight: '600',
        padding: '0 20px 20px',
        boxSizing: 'border-box',
        margin: '20px 0',
        fontSize: '20px',
    },
    error: {
        padding: '20px 0 0',
    }
}

class ErrorSection extends React.Component {

    renderErrors() {
        return this.props.errors.map(this.renderError)
    }

    renderError(error) {
        return (
            <div style={styles.error}>
                {error}
            </div>
        )
    }

    render () {
        if (!this.props.errors || this.props.errors.length === 0) {
            return null
        }
        return (
            <div style={styles.container}>
                {this.renderErrors()}
            </div>
        );
    }
}

export default ErrorSection
