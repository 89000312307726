import React from "react"
import styled from "styled-components";
import Toast from "./Toast";

const styles = {
    inputSection: {
        width: '100%',
        marginBottom: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    header: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        margin: '20px 0'
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    cardTitle: {
        paddingBottom: '10px',
        boxSizing: 'border-box',
        marginBottom: '30px',
        borderBottom: '1px solid #CCC',
        fontSize: '24px',
        fontWeight: '600',
        width: '100%',
    },
}

const SubmitButton = styled.div`
:hover {
    background-color: #49ab67 !important;
}
`

let toastId = 1

class SettingsProfile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            bio: props.user.bio ?? "",
            twitter: props.user.twitter ?? "",
            instagram: props.user.instagram ?? "",
            website: props.user.website ?? "",
            email: props.user.email ?? "",
            toasts: [],
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    submitForm(e) {
        const body = {
            bio: this.state.bio,
            twitter: this.state.twitter,
            instagram: this.state.instagram,
            website: this.state.website,
            email: this.state.email,
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/users/${this.props.user?.slug}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.success) {
                    this.state.toasts.push({title: 'Updated Profile', information:  `You have updated your profile information`, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                }
            })
            .catch(err => {
            })
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    render() {
        return (
            <div>
                <div style={styles.cardTitle}>
                    Settings Profile
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Email
                    </div>
                    <input style={styles.inputBox} maxLength="100" name="email" value={this.state.email} onChange={this.onChange.bind(this)} />
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Bio
                    </div>
                    <textarea style={styles.inputBox} maxLength="500" name="bio" value={this.state.bio} onChange={this.onChange.bind(this)} />
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Twitter
                    </div>
                    <input style={styles.inputBox} maxLength="100" name="twitter" value={this.state.twitter} onChange={this.onChange.bind(this)} />
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Instagram
                    </div>
                    <input style={styles.inputBox} maxLength="100" name="instagram" value={this.state.instagram} onChange={this.onChange.bind(this)} />
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Website
                    </div>
                    <input style={styles.inputBox} maxLength="100" name="website" value={this.state.website} onChange={this.onChange.bind(this)} />
                </div>

                <SubmitButton onClick={this.submitForm.bind(this)} name="commit" style={styles.submitButton}>
                    Save
                </SubmitButton>
                {this.renderToasts()}
            </div>
        )
    }
}


export default SettingsProfile
