import React from "react"
import styled from "styled-components";
import moment from "moment";

const styles = {
    containerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    container: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        maxWidth: '1000px',
        margin: '30px 20px',
        boxSizing: 'border-box',
        borderRadius: '20px',
    },
    imageContainer: {
        width: '100%',
        maxWidth: '500px',
        height: '375px',
    },
    h1: {
        fontWeight: '600',
        fontSize: '36px',
        margin: '40px 0 0',
        fontFamily: 'Lato, Arial',
        width: '100%',
        textAlign: 'center',
    },
    name: {
        fontWeight: '600',
        fontSize: '30px',
        marginTop: '5px',
    },
    priceContainer: {
        padding: 20,
        margin: 10,
        border: '1px solid #CCC',
        borderRadius: '20px',
    },
    price: {
        fontWeight: '600',
        fontSize: '24px',
    },
    priceTitle: {
        color: '#666',
        fontSize: '14px',
    },
    attributesContainer: {
        marginTop: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    purchaseInfo: {
        textAlign: 'center',
    },
    tx: {
        marginTop: '5px',
        fontSize: '20px',
        color: '#5dd982',
    },
}

const Container = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

class PurchasePage extends React.Component {
    render() {
        return (
            <div style={styles.containerContainer}>
                <div style={styles.h1}>
                    {`Receipt`}
                </div>
                <Container style={styles.container}>
                    <div style={styles.purchaseInfo}>
                        <a href={`/tokens/${this.props.purchase.token.slug}`}>
                            <div style={styles.imageContainer}>
                                <img style={{width: '100%', maxWidth: '500px', height: '375px', objectFit: 'cover', borderRadius: '20px'}} src={this.props.purchase.token.thumbnail_url} />
                            </div>
                            <div style={styles.name}>
                                {this.props.purchase.token.name}
                            </div>
                            <a href={`https://polygonscan.com/tx/${this.props.purchase.tx_id}`}>
                                <div style={styles.tx}>
                                    {this.props.purchase.tx_id && ("0x" + this.props.purchase.tx_id.substr(0, 10) + "..." + this.props.purchase.tx_id.substr(60))}
                                </div>
                            </a>
                            <div style={styles.author}>
                                {'by ' + this.props.purchase.token.user.username}
                            </div>
                        </a>
                    </div>
                    <div style={styles.attributesContainer}>
                        <div style={styles.priceContainer}>
                            <div style={styles.priceTitle}>
                                Price (Each)
                            </div>
                            <div style={styles.price}>
                                {(this.props.purchase.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div style={styles.priceTitle}>
                                Quantity
                            </div>
                            <div style={styles.price}>
                                {this.props.purchase.quantity}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div style={styles.priceTitle}>
                                Total
                            </div>
                            <div style={styles.price}>
                                {((this.props.purchase.price * this.props.purchase.quantity)/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div style={styles.priceTitle}>
                                Date Purchased
                            </div>
                            <div style={styles.price}>
                                {moment(new Date(this.props.purchase.created_at)).format("MMM DD, YYYY")}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div style={styles.priceTitle}>
                                Payment Method
                            </div>
                            <div style={styles.price}>
                                {this.props.purchase.payment_method}
                            </div>
                        </div>
                        {this.props.purchase.payment_method === 'eth' && (
                            <React.Fragment>
                                <div style={styles.priceContainer}>
                                    <div style={styles.priceTitle}>
                                        Payment Status
                                    </div>
                                    <div style={styles.price}>
                                        {this.props.purchase.payment_received ? 'Received' : 'Pending'}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Container>
            </div>
        );
    }
}

export default PurchasePage