import React from "react"

const styles = {
    container: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    image: {
        maxWidth: '100%',
        maxHeight: '80vh',
        objectFit: 'contain',
        zIndex: 500,
    },
}

class MediaModal extends React.Component {
    render () {
        return (
            <div style={styles.container}>
                <img style={styles.image} src={this.props.media_url} />
                <div onClick={this.props.close} style={styles.background} />
            </div>
        );
    }
}

export default MediaModal
