import React from "react"
import styled from "styled-components"
import DateTimePicker from 'react-datetime-picker'
import axios from "axios"

const styles = {
    container: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
    },
    h1: {
        fontSize: 36,
        fontWeight: 600,
        marginBottom: '25px',
        marginTop: '35px',
    },
    formContainer: {
        maxWidth: '800px',
        padding: '30px',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: '20px',
    },
    termTitle: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    termInfo: {
        fontSize: '14px',
        color: '#333',
        marginBottom: '60px',
    }
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

class About extends React.Component {
    render () {
        return (
            <React.Fragment>
                <div style={styles.container}>
                    <div style={styles.h1}>
                        {`Nanobash FAQ`}
                    </div>
                    <FormContainer style={styles.formContainer}>
                        <div style={styles.termTitle}>
                            What is Nanobash?
                        </div>
                        <div style={styles.termInfo}>
                            Nanobash is a website that allows you to buy & sell digital art from popular artists. Traditionally known as Non-Fungible Tokens (NFTs), Nanobash has a slight twist. While all art on Nanobash is tokenized via the Polygon blockchain, each edition is in fact fungible meaning each edition is valued identically.
                        </div>
                        <div style={styles.termTitle}>
                            How can I buy the art?
                        </div>
                        <div style={styles.termInfo}>
                            All purchases are done in fiat via Stripe or with Eth using metamask. To make a purchase simply go to the token page https://www.nanobash.com/tokens, select the token you wish to purchase, and then fill out either the purchase form or the order form.
                        </div>
                        <div style={styles.termTitle}>
                            How long is each art piece sold for?
                        </div>
                        <div style={styles.termInfo}>
                            Each piece of artwork can be bought for up to 1 week. After the week has concluded no new editions of the artwork will be minted and can only be acquired via purchasing from another person who owns an edition.
                        </div>
                        <div style={styles.termTitle}>
                            How do I cash out after selling a piece of art?
                        </div>
                        <div style={styles.termInfo}>
                            Go to the cashout pages in setting https://www.nanobash.com/settings/?screen=cashout and enter your bank account information via Stripe or your Ethereum address.
                        </div>
                        <div style={styles.termTitle}>
                            How long does it take to cashout?
                        </div>
                        <div style={styles.termInfo}>
                            There is a delay of 1 week when cashing out to prevent against fraud.
                        </div>
                        <div style={styles.termTitle}>
                            How can I withdraw my tokens from Nanobash?
                        </div>
                        <div style={styles.termInfo}>
                            The ability to withdraw tokens from the Nanobash platform is coming soon. Once launched you will be able to withdraw your tokens within the settings page.
                        </div>
                        <div style={styles.termTitle}>
                            Are the editions fungible?
                        </div>
                        <div style={styles.termInfo}>
                            Yes, technically this means the tokens would be considered FTs instead of NFTs. However, we have chosen to keep the NFT nomenclature since NFTs are usually used as a term to represent tokenized digital art instead of its literal meaning of Non-fungible token.
                        </div>
                        <div style={styles.termTitle}>
                            What is an FT?
                        </div>
                        <div style={styles.termInfo}>
                            FT stands for fungible token which is an indestructible verification of ownership of a token secured by the blockchain.
                        </div>
                        <div style={styles.termTitle}>
                            What blockchain does Nanobash run on?
                        </div>
                        <div style={styles.termInfo}>
                            Nanobash runs on the Polygon blockchain.
                        </div>
                        <div style={styles.termTitle}>
                            Where do you find artists?
                        </div>
                        <div style={styles.termInfo}>
                            We talk and work with popular artists directly to bring high quality digital art to the Nanobash platform. Please contact us at contact@nanobash.com to get in contact if you are interested in working directly with us.
                        </div>
                        <div style={styles.termTitle}>
                            What are there fees for Ethereum?
                        </div>
                        <div style={styles.termInfo}>
                            Since the site is based in USD we need to account for the volatility in Ethereum's price between the time you load the purchase page and the Ethereum is received. The fees are also used for additional on-chain transactions required on our end.
                        </div>
                        <div style={styles.termTitle}>
                            Why are there minimums on payouts?
                        </div>
                        <div style={styles.termInfo}>
                            There are fees associated with payouts which makes small amounts difficult to achieve for certain payout methods.
                        </div>
                        <div style={styles.termTitle}>
                            Can you add more payment providers?
                        </div>
                        <div style={styles.termInfo}>
                            We currently allow cards via Stripe and Ethereum via metamask. We may add other payment options in the future, but they will be announced.
                        </div>
                    </FormContainer>
                </div>
            </React.Fragment>

        );
    }
}

export default About
