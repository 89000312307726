import React from "react"
import UserTokenListItem from './UserTokenListItem'
import Paginator from "./Paginator";
import UserTokenPage from "./UserTokenPage";
import TokenMiniListItem from "./TokenMiniListItem";
import styled from "styled-components";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    itemsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '10px',
    },
    title: {
        marginTop: '10px',
        fontWeight: '600',
        fontSize: '24px',
    },
    titleUnder: {
        marginTop: '5px',
        fontSize: '16px',
        color: '#999',
        marginBottom: '20px',
    },
    sectionTitle: {
        fontSize: '16px',
        color: '#333',
        textAlign: 'center',
    },
    balance: {
        fontWeight: '600',
        fontSize: '24px',
    },
    balanceSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    },
    itemSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '20px',
        width: '100%',
    },
    overviewContainer: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        maxWidth: '800px',
        borderRadius: '20px',
        margin: '0 20px',
        justifyContent: 'center',
    },
    overviewSection: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px 40px',
    },
    itemTitle: {
        fontWeight: '600',
        fontSize: '36px',
        textAlign: 'center',
        marginTop: '10px',
        paddingTop: '40px',
        marginBottom: '20px',
        width: '100%',
    },
    overviewTitle: {
        color: '#333',
        fontSize: '18px',
        paddingBottom: '10px',
        textAlign: 'center',
        width: '100%',
    },
    socialMediaButtons: {
        display: 'inline-flex',
    },
    icon: {
        width: '40px',
        height: '40px',
        borderRadius: '80px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        fontSize: '24px',
        color: 'white',
        margin: '0 10px 20px',
    },
    twitterColor: {
        backgroundColor: '#1da1f2',
    },
    instagramColor: {
      backgroundColor: '#E1306C',
    },
    websiteColor: {
        backgroundColor: '#E13939',
    },
    bioSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
        borderTop: '1px solid #CCC',
        borderRadius: '5px',
        boxSizing: 'border-box',
        margin: '10px 20px 20px',
        padding: '20px 30px 0',
        maxWidth: '500px',
    },
    bio: {
        textAlign: 'center',
        fontSize: '14px',
        maxWidth: '600px',
        color: '#666',
    },
    topInfoContainer: {
        width: '100%',
        display: 'flex',
        marginBottom: '40px',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '30px 0',
    },
    topInfo: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    leftInfo: {
        margin: '10px 20px',
        textAlign: 'center',
    },
    overviewBalance: {
        fontSize: '30px',
        fontWeight: '600',
    },
    emptyBox: {
        width: '100%',
        margin: '30px 20px 20px',
        maxWidth: '1000px',
        backgroundColor: '#FFF',
        padding: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
        border: '1px solid #CCC',
        color: '#333',
    },
    overviewChange: {
        color: '#5dd982',
        fontWeight: '600',
        fontSize: '18px',
        marginTop: '10px',
    },
    overviewChangeNeg: {
        color: '#ce0000',
        fontWeight: '600',
        fontSize: '14px',
        marginTop: '10px',
    },
}

const Container = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

class ProfilePage extends React.Component {
    constructor(props) {
        super(props)

        this.setRealQuantities()
        this.state = {
            totalPages: props.total_pages,
            user_tokens: props.user_tokens,
        }
    }

    setRealQuantities = () => {
        this.props.user_tokens.map(user_token => {
            let pendingSales = this.props.active_orders.filter(order => order.token_id === user_token.token_id && order.order_type === 'limit_sell')
            let pendingBuys = this.props.active_orders.filter(order => order.token_id === user_token.token_id && order.order_type === 'limit_buy')
            let buysSum = pendingBuys.reduce((a, b) => a + (b['quantity_filled'] || 0), 0);
            let salesSum = pendingSales.reduce((a, b) => a + (b['quantity'] - b['quantity_filled'] || 0), 0);
            let extraQuantity = buysSum + salesSum
            user_token.quantity += extraQuantity
        })
    }

    renderItems() {
        return this.state.user_tokens.map(user_token => {
            if (user_token.quantity === 0) {
                return null
            }
            return (<UserTokenListItem key={user_token.id} {...user_token} />)
        })
    }

    assurePrefix(url, domain) {
        if (url[0] === "@") {
            url = url.substr(1)
        }
        if (url.indexOf('www.') === -1 && url.indexOf('https://') === -1) {
            return domain + url
        }
        return url.match(/^.{3,5}\:\/\//) ? url : `http://${url}`
    }

    capitalize = (str) => (str[0].toUpperCase() + str.slice(1, str.length))

    renderBalance() {
        return (
            <div style={styles.balanceSection}>
                <div style={styles.sectionTitle}>
                    Cash Balance
                </div>
                <div style={styles.balance}>
                    {(this.props.user.balance/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                </div>
            </div>
        )
    }

    renderBio() {
        if (!this.props.user.bio || this.props.user.bio.length === 0) {
            return null
        }
        return (
            <span>
                <div style={styles.sectionTitle}>
                    Bio
                </div>
                <div style={styles.bioSection}>
                    <div style={styles.bio}>
                        {this.props.user.bio}
                    </div>
                </div>
            </span>
        )
    }

    objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    }

    updateItems(page) {
        const body = {
            user_id: this.props.user.id,
            page: page || 1
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/items?${this.objToQueryString(body)}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        items: res.items,
                    });
                }
            })
            .catch(err => {
            })
    }

    renderEmptyState() {
        return (
            <div style={styles.emptyBox}>
                No tokens yet, try buying one on the
                <a style={{color: '#5dd982', marginLeft: '5px', fontWeight: '600'}} href={'/tokens'}>
                    Token Page
                </a>
                !
            </div>
        )
    }

    renderItemsSection() {
        return (
            <div style={styles.itemSection}>
                {this.props.user.id === this.props.current_user?.id &&
                    (
                        <div style={styles.itemTitle}>
                            Dashboard
                        </div>
                    )
                }
                {this.renderOverviewSection()}
                <div style={styles.itemsContainer}>
                    {this.state.user_tokens.length === 0 ? this.renderEmptyState() : this.renderItems()}
                </div>
                {this.state.totalPages > 1 ? <Paginator updateFn={this.updateItems.bind(this)} totalPages={this.state.totalPages} /> : null}
            </div>
        )
    }

    calculateTotalValue() {
        let totalValue = 0
        this.state.user_tokens.map(user_token => {
            let lastPrice = user_token.token.last_price
            totalValue += lastPrice * user_token.quantity
        })
        return totalValue
    }

    totalPercentChange() {
        let yesterday_price = 0
        let now_price = 0

        this.state.user_tokens.forEach(user_token => {
            yesterday_price += (user_token.token.price_1d * user_token.quantity)
            now_price += (user_token.token.last_price * user_token.quantity)
        })

        if (yesterday_price === 0) {
            return 0
        }

        return Math.round(((now_price / yesterday_price) * 100) - 100)
    }

    renderCashBalance = () => {
        return (
            <div style={styles.overviewSection}>
                <div style={styles.overviewTitle}>
                    Cash Balance
                </div>
                <div style={styles.overviewBalance}>
                    {(this.props.user.balance/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                </div>
                <a href={'/settings/?screen=cashout'}>
                    <div style={styles.overviewChange}>
                        {`Cashout`}
                    </div>
                </a>
            </div>
        )
    }

    renderOverviewSection() {
        return (
            <Container style={styles.overviewContainer}>
                <div style={styles.overviewSection}>
                    <div style={styles.overviewTitle}>
                        Token Value
                    </div>
                    <div style={styles.overviewBalance}>
                        {(this.calculateTotalValue()/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                    <div style={this.totalPercentChange() >= 0 ? styles.overviewChange : styles.overviewChangeNeg}>
                        {`${this.totalPercentChange() >= 0 ? '+' : ''}${this.totalPercentChange()}% Today`}
                    </div>
                </div>
                {this.props.user.id === this.props.current_user?.id && this.renderCashBalance()}
            </Container>
        )
    }

    renderGeneralInfo = () => {
        return (
            <div style={styles.topInfoContainer}>
                <div style={styles.topInfo}>
                    <div style={styles.leftInfo}>
                        <div style={styles.title}>
                            {`${this.capitalize(this.props.user.username)}`}
                        </div>
                        <div style={styles.titleUnder}>
                            {`@${this.capitalize(this.props.user.username)}`}
                        </div>
                        <div style={styles.socialMediaButtons}>
                            { this.props.user.twitter && (
                                <a style={{...styles.icon, ...styles.twitterColor}} href={this.assurePrefix(this.props.user.twitter, 'https://www.twitter.com/')} target={"_blank"}>
                                    <i className="fab fa-twitter"></i>
                                </a>
                            ) }
                            { this.props.user.instagram && (
                                <a style={{...styles.icon, ...styles.instagramColor}} href={this.assurePrefix(this.props.user.instagram, 'https://www.instagram.com/')} target={"_blank"}>
                                    <i className="fab fa-instagram"></i>
                                </a>
                            ) }
                            { this.props.user.website && (
                                <a style={{...styles.icon, ...styles.websiteColor}} href={this.assurePrefix(this.props.user.website, '')} target={"_blank"}>
                                    <i className="fa fa-user"></i>
                                </a>
                            ) }
                        </div>
                    </div>
                    {this.renderBio()}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div style={styles.container}>
                {this.props.user.id !== this.props.current_user?.id && this.renderGeneralInfo()}
                {this.renderItemsSection()}
            </div>
        );
    }
}

export default ProfilePage