import React from "react"
import styled from "styled-components";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        borderRadius: '20px',
        margin: 30,
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px',
        boxSizing: 'border-box',
        width: '100%',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#EEE',
    },
    extraInfo: {
        padding: '15px',
        boxSizing: 'border-box',
        backgroundColor: '#F6F6F6',
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    collectionName: {
        fontSize: '20px',
        width: '100%',
    },
    collectionAuthor: {
        fontSize: '14px',
        color: '#666',
        marginTop: '5px',
    },
    ownerSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    ownerTitle: {
        color: '#999',
        fontSize: '14px',
    },
    owner: {
        fontSize: '18px',
    },
    price: {
        fontSize: '18px',
    },
    attributes: {
        marginTop: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '500px',
    },
    traitSection: {
        display: 'inline-block',
        width: '100px',
        margin: '10px 5px 5px',
        border: '1px solid #CCC',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '10px',
    },
    traitCategory: {
        fontSize: '14px',
        color: '#5dd982',
    },
    traitPercentage: {
        fontSize: '14px',
        color: '#999',
        marginTop: '5px',
    },
    trait: {
        fontWeight: '600',
        fontSize: '16px',
    },
    onSaleFor: {
        color: '#999',
        marginBottom: '5px',
        fontSize: '14px',
    },
    sellType: {
        display: 'inline-flex',
        padding: '3px 10px 5px',
        borderRadius: '10px',
        border: '1px solid #CCC',
    },
    numAvailable: {
        color: '#666',
        marginTop: '5px',
        fontSize: '12px',
    },
    rightInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    pricesHolder: {
        display: 'flex',
        textAlign: 'left',
    }
}

const Container = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
transition: 0.3s;
:hover {
    background-color: #FAFAFA !important;
    box-shadow: rgb(0, 0, 0, 0.2) 0px 4px 16px !important;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
}
`

class TokenListItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    render() {
        let imageStyle = {width: '100%', maxWidth: this.state.mobile ? '300px' : '500px', height: this.state.mobile ? '300px' : '500px', objectFit: 'cover', borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}
        return (
            // <React.Fragment>
            //     <Container style={styles.container}>
            //         <a href={`/item_details/${this.props.slug}`}>
            //             <div style={styles.imageContainer}>
            //                 <img style={{width: '100%', maxWidth: '400px', height: '500px', objectFit: 'cover'}} src={this.props.thumbnail_url} />
            //             </div>
            //             <div style={styles.infoContainer}>
            //                 <div>
            //                     <div style={styles.collectionName}>
            //                         {this.props.name}
            //                     </div>
            //                     <div style={styles.collectionAuthor}>
            //                         {'by ' + this.props.user.username}
            //                     </div>
            //                     <div style={styles.price}>
            //                         {(this.props.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
            //                     </div>
            //                 </div>
            //                 <div style={styles.infoRight}>
            //                     <div style={styles.typeBadge}>
            //                         {this.props.sell_type}
            //                     </div>
            //                     {this.props.sell_type === "Drawing" && (
            //                         <div style={styles.available}>
            //                             {this.props.quantity + ' available'}
            //                         </div>
            //                     )}
            //                 </div>
            //             </div>
            //         </a>
            //     </Container>
            // </React.Fragment>

            <React.Fragment>
                <Container style={{...styles.container, width: this.state.mobile ? '300px' : '500px'}}>
                    <a style={{width: this.state.mobile ? '300px' : '500px'}} href={`/tokens/${this.props.slug}`}>
                        <div style={styles.imageContainer}>
                            <img style={imageStyle} src={this.props.thumbnail_url} />
                        </div>
                        <div style={styles.infoContainer}>
                            <div style={styles.collectionName}>
                                <div>
                                    <a href={`/tokens/${this.props.slug}`}>
                                        {this.props.name}
                                    </a>
                                </div>
                                <div style={styles.collectionAuthor}>
                                    {'by ' + this.props.user.username}
                                </div>
                            </div>
                        </div>
                        <div style={styles.divider} />
                        <div style={styles.extraInfo}>
                            <div style={styles.pricesHolder}>
                                <div>
                                    <div style={styles.onSaleFor}>
                                        Original Price
                                    </div>
                                    <div style={styles.price}>
                                        {(this.props.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                    </div>
                                </div>
                                <div style={{marginLeft: '30px'}}>
                                    <div style={styles.onSaleFor}>
                                        Last Price
                                    </div>
                                    <div style={styles.price}>
                                        {(this.props.last_price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                    </div>
                                </div>
                            </div>
                            <div style={styles.rightInfo}>
                                <div style={styles.sellType}>
                                    {this.props.sell_type}
                                </div>
                                <div style={styles.numAvailable}>
                                    {this.props.quantity_minted + " minted"}
                                </div>
                            </div>
                        </div>
                    </a>
                </Container>
            </React.Fragment>
        );
    }
}

export default TokenListItem
