import React from "react"
import styled from "styled-components";
import Toast from "./Toast";

const styles = {
    inputSection: {
        width: '100%',
        marginBottom: '20px',
        marginTop: '20px',
    },
    inputLabel: {
        fontSize: '18px',
        fontWeight: '300',
        margin: '5px 0',
        color: '#333',
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    cardTitle: {
        paddingBottom: '10px',
        boxSizing: 'border-box',
        marginBottom: '30px',
        borderBottom: '1px solid #CCC',
        fontSize: '24px',
        fontWeight: '600',
        width: '100%',
    },
    info: {
        color: '#333',
        fontSize: '22px',
        fontWeight: '600',
        marginTop: '30px',
        paddingTop: '30px',
        borderTop: '1px solid #999',
    },
    infoDescription: {
        color: '#666',
        marginBottom: '20px',
        fontSize: '14px',
    },
    infoMini: {
        color: '#333',
        marginBottom: '20px',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'inline-flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    submitButtonMini: {
        display: 'inline-flex',
        padding: '6px 12px',
        fontSize: '16px',
        fontWeight: '400',
        backgroundColor: 'white',
        border: '1px solid #333',
        alignItems: 'center',
        color: '#333',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    disabledButton: {
        border: 'none',
        backgroundColor: '#CCC',
        display: 'inline-flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'not-allowed',
        borderRadius: '5px',
    },
    balanceText: {
        fontSize: '26px',
        fontWeight: '600',
    },
    bankName: {
        fontWeight: '600',
        fontSize: '20px',
    },
    error: {
        width: '100%',
        borderRadius: '20px',
        boxSizing: 'border-box',
        margin: '20px 0',
        padding: '20px',
        fontWeight: '600',
        fontSize: '20px',
        backgroundColor: '#ffe3de',
    },
    ethLogo: {
        marginLeft: '5px',
        color: '#555',
        marginTop: '2px',
    }
}

const SubmitButton = styled.button`
:hover {
    background-color: #49ab67 !important;
}
`
let toastID = 1

class SettingsCashout extends React.Component {

    constructor(props) {
        super(props)

        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        let error = false
        if (urlParams.get('error')) {
            error = true
        }

        this.state = {
            balance: '',
            toasts: [],
            currentBalance: this.props.user.balance,
            error: error,
            cashout_url: '',
            bank: {},
            transfers_enabled: false,
            fetchedAccount: false,
            eth_address: window?.ethereum?.selectedAddress || '',
            amount_usd: '',
        }
    }

    componentDidMount() {
        this.fetchAccountInfo()
    }

    fetchAccountInfo = () => {
        const body = {}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/users/fetch_account_info`, {
            method: "POST",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        cashout_url: res.cashout_url,
                        bank: res.bank,
                        transfers_enabled: res.transfers_enabled,
                        fetchedAccount: true,
                    });
                }
            })
            .catch(err => {
            })
    }

    convertAnythingToMoney = (str) => {
        if (str[0] === '$') {
            str = str.substr(1)
        }
        if (str[0] === '.') {
            str = '0' + str
        }

        let foundDecimal = false
        let afterDecimal = 0
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        for (let i = 0; i < str.length; i++) {
            if (numbers.includes(str[i])) {
                if (foundDecimal) {
                    afterDecimal++
                    if (afterDecimal === 3) {
                        return false
                    }
                }
            } else if (str[i] === '.') {
                if (foundDecimal) {
                    return false
                }
                foundDecimal = true
            } else {
                return false
            }
        }

        if (str.length === 0) {
            return ''
        }
        return '$' + str
    }

    onMoneyChange(e) {
        const res = this.convertAnythingToMoney(e.target.value)
        if (res !== false) {
            this.setState({[e.target.name]: res})
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    renderCashout = () => {
        let disabled = false
        let errorMessage = ''

        if (this.convertMoneyToCents(this.state.balance) > parseInt(this.state.currentBalance)) {
            disabled = true
            errorMessage = 'Insufficient Balance'
        }

        if (5000 > this.convertMoneyToCents(this.state.balance)) {
            disabled = true
            errorMessage = 'Minimum transfer of $50.00'
        }

        return (
            <React.Fragment>

                <a href={this.state.cashout_url}>
                    <div style={styles.submitButtonMini}>
                        Change Payout Information
                    </div>
                </a>


                <div style={styles.info}>
                    Transfer balance to bank account
                </div>
                <div style={styles.infoDescription}>
                    Please be aware that there is a wait time of 7 days on all transfers
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Balance to transfer
                    </div>
                    <input style={styles.inputBox} maxLength="50" name="balance" placeholder="$0.00" value={this.state.balance} onChange={this.onMoneyChange.bind(this)} />
                </div>
                {
                    !disabled ? (
                        <SubmitButton onClick={this.transfer.bind(this)} style={styles.submitButton}>
                            {`Transfer`}
                        </SubmitButton>

                    ) : (

                        <button style={styles.disabledButton}>
                            {errorMessage}
                        </button>
                    )
                }
            </React.Fragment>
        )
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    convertMoneyToCents = (str) => {
        let newStr = str.replace('$', '');
        newStr = newStr.replace(',', '')
        let cents = newStr.split('.')[1] || ''
        while (cents.length < 2) {
            cents += '0'
        }
        return parseInt(newStr.split('.')[0] + cents)
    }

    transfer = () => {
        let transferBalance = this.convertMoneyToCents(this.state.balance)
        const body = {balance: transferBalance}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const trueThis = this

        fetch(`${window.location.origin}/api/v0/payouts`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    let newToast = {
                        title: 'Transfer Initiated',
                        information:  'You have successfully initiated a transfer to your account.',
                        id: toastID
                    }
                    trueThis.state.toasts.push(newToast)
                    toastID++
                    trueThis.setState({currentBalance: (trueThis.state.currentBalance - transferBalance), toasts: trueThis.state.toasts})
                    // trueThis.setState({currentBalance: (trueThis.state.currentBalance - transferBalance)})
                } else {
                }
            })
            .catch(err => {

            })
    }

    transferEth = () => {
        let transferBalance = this.convertMoneyToCents(this.state.amount_usd)
        const body = {
            amount_usd: transferBalance,
            eth_address: this.state.eth_address,
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const trueThis = this

        fetch(`${window.location.origin}/api/v0/crypto_payouts`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    let newToast = {
                        title: 'Transfer Initiated',
                        information:  'You have successfully initiated a transfer to your ethereum wallet.',
                        id: toastID
                    }
                    trueThis.state.toasts.push(newToast)
                    toastID++
                    trueThis.setState({currentBalance: (trueThis.state.currentBalance - transferBalance), toasts: trueThis.state.toasts})
                } else {
                }
            })
            .catch(err => {

            })
    }

    renderTransfer = () => {
        if (this.state.bank) {
            return (
                <React.Fragment>
                    <div style={styles.infoMini}>
                        Verify your identity via stripe to cashout
                    </div>
                    <a href={this.state.cashout_url}>
                        <SubmitButton style={styles.submitButton}>
                            Verify Identity
                        </SubmitButton>
                    </a>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <div style={styles.infoMini}>
                    Connect your bank account via stripe in order to cash out. (Please fully verify your identity to cash out)
                </div>
                <a href={this.state.cashout_url}>
                    <SubmitButton style={styles.submitButton}>
                        Connect Bank
                    </SubmitButton>
                </a>
            </React.Fragment>
        )
    }

    renderError = () => (
        <div style={styles.error}>
            Unable to setup account, please try again
        </div>
    )

    renderCryptoCashout = () => {

        let ethAmount = this.convertMoneyToCents(this.state.amount_usd)/this.props.eth_price_in_cents
        ethAmount -= 0.0015
        ethAmount = parseFloat(ethAmount.toFixed(4));
        ethAmount = Math.max(0, ethAmount)

        let disabled = false
        let errorMessage = 'Transfer Invalid'

        if (this.convertMoneyToCents(this.state.amount_usd) > parseInt(this.state.currentBalance)) {
            disabled = true
            errorMessage = 'Insufficient Balance'
        }

        if (this.state.eth_address.substr(0, 2) !== '0x' || this.state.eth_address.length !== 42) {
            disabled = true
            errorMessage = 'Invalid Eth Address'
        }

        if (this.convertMoneyToCents(this.state.amount_usd) < 5000) {
            disabled = true
            errorMessage = "Minimum transfer of $50"
        }

        return (
            <React.Fragment>
                <div style={styles.info}>
                    Withdraw balance in Eth
                    <i className="fab fa-ethereum" style={styles.ethLogo} />
                </div>
                <div style={styles.infoDescription}>
                    Estimated wait time of 5-14 days
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Balance to transfer (USD)
                    </div>
                    <input style={styles.inputBox} maxLength="50" name="amount_usd" placeholder="$0.00" value={this.state.amount_usd} onChange={this.onMoneyChange.bind(this)} />
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Estimated Eth
                    </div>
                    <div style={styles.balanceText}>
                        {ethAmount}
                        <i className="fab fa-ethereum" style={styles.ethLogo} />
                    </div>
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Ethereum Address
                    </div>
                    <input style={styles.inputBox} maxLength="50" name="eth_address" placeholder="0x123" value={this.state.eth_address} onChange={this.onChange.bind(this)} />
                </div>
                {
                    !disabled ? (
                        <SubmitButton onClick={this.transferEth.bind(this)} style={styles.submitButton}>
                            {`Transfer`}
                        </SubmitButton>

                    ) : (

                        <button style={styles.disabledButton}>
                            {errorMessage}
                        </button>
                    )
                }
            </React.Fragment>
        )
    }

    render() {
        if (!this.state.fetchedAccount) {
            return (
                <div style={{boxSizing: 'border-box'}}>
                    <div style={styles.cardTitle}>
                        Cashout
                    </div>
                    <div>
                        Loading...
                    </div>
                </div>
            )
        }
        return (
            <div style={{boxSizing: 'border-box'}}>
                <div style={styles.cardTitle}>
                    Cashout
                </div>
                {this.state.error && this.renderError()}
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Available Balance
                    </div>
                    <div style={styles.balanceText}>
                        {(this.state.currentBalance/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                {this.state.transfers_enabled && this.renderCashout()}
                {!this.state.transfers_enabled && this.renderTransfer()}
                {this.renderCryptoCashout()}
                {this.renderToasts()}
            </div>
        )
    }
}

export default SettingsCashout