import React from "react"
import styled from "styled-components";
import Toast from "./Toast";

const styles = {
    container: {
        width: '100%',
        backgroundColor: '#171b1c',
        fontFamily: 'Lato, Arial',
        color: 'white',
        display: 'flex',
    },
    innerContainer: {
        width: '100%',
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        fontWeight: '600',
        fontSize: '24px',
    },
    links: {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    link: {
        marginLeft: '30px',
        cursor: 'pointer',
        fontWeight: '600',
        color: '#999',
    },
    specialLink: {
        marginLeft: '30px',
        cursor: 'pointer',
        fontWeight: '600',
        border: '1px solid #999',
        borderRadius: '10px',
        padding: '10px 20px',
        color: '#999',
    },

    specialLinkMobile: {
        cursor: 'pointer',
        fontWeight: '600',
        border: '1px solid #999',
        borderRadius: '10px',
        padding: '10px 20px',
        fontFamily: 'Lato, Arial',
        textAlign: 'center',
        marginTop: '20px',
        color: '#999',
    },
    menuBack: {
        width: '100%',
        backgroundColor: '#171b1c',
        boxSizing: 'border-box',
        overflow: 'hidden',
        transition: 'height 100ms ease 0s',
    },
    menuBackInner: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
    },
    menuLink: {
        color: 'white',
        fontFamily: 'Lato, Arial',
        paddingBottom: '10px',
        marginBottom: '10px',
        borderBottom: '1px solid #414c4d',
        fontWeight: '600',
    },
}

const Link = styled.div`
transition: 0.2s;
:hover {
    color: white !important;
}
`

let toastId = 1

class Header extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loggedIn: !!props.user,
            mobile: window.innerWidth < 1000,
            menuExpanded: false,
            connecting: false,
            connected: true,
            toasts: []
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));

        window.addEventListener("load", async () => {
            if (window?.ethereum?.selectedAddress) {
                this.setState({connected: true})
            } else {
                this.setState({connected: false})
            }
        })
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000, menuExpanded: false})
    }

    updateUserSetting = () => {
        const body = {payment_method_preference: 'eth'}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/users/${this.props.user?.slug}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
            })
            .catch(err => {
            })
    }

    connectWallet = () => {
        if (!this.state.connecting && window.ethereum) {
            this.setState({connecting: true})
            let test = window.ethereum.request({ method: 'eth_requestAccounts' })
            test.then(res => {
                console.log('res', res)
                if (window.ethereum?.selectedAddress) {
                    this.updateUserSetting()
                    this.state.toasts.push({title: 'Wallet Connected', information:  `You have successfully connected your ethereum wallet!`, id: toastId++})
                    this.setState({toasts: this.state.toasts, connected: true})
                }
            }).catch(err => {
                console.log('err', err)
            })
        }
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    capitalize = (str) => (str[0].toUpperCase() + str.slice(1, str.length))

    logOut = () => {
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = {}

        const outsideThis = this

        fetch(`${window.location.origin}/api/v0/sessions/logout`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                outsideThis.setState({user: null, loggedIn: false})
                window.location.href = '/?logout=true'
            })
            .catch(err => {
                outsideThis.setState({user: null, loggedIn: false})
                window.location.href = '/?logout=true'
            })
    }

    isActivePath(path) {
        return document.location.pathname.substr(1, path.length) === path
    }

    renderAccount() {
        return (
            <React.Fragment>
                <a href={`/referrals`}>
                    <Link style={{...styles.link, color: this.isActivePath('referrals') ? 'white' : '#999'}}>
                        Free NFTs
                    </Link>
                </a>
                <a href={`/transactions`}>
                    <Link style={{...styles.link, color: this.isActivePath('transactions') ? 'white' : '#999'}}>
                        Transactions
                    </Link>
                </a>
                <a href={`/users/${this.props.user.slug}`}>
                    <Link style={{...styles.link, color: this.isActivePath('users') ? 'white' : '#999'}}>
                        Dashboard
                    </Link>
                </a>
                <a href={`/settings`}>
                    <Link style={{...styles.link, color: this.isActivePath('settings') ? 'white' : '#999'}}>
                        Settings
                    </Link>
                </a>
                {/*<Link onClick={this.logOut} style={styles.link}>*/}
                {/*    Log Out*/}
                {/*</Link>*/}
                {!window.ethereum && (
                    <a href={'https://metamask.io/download'} target={"_blank"}>
                        <Link style={styles.specialLink}>
                            Connect Wallet
                        </Link>
                    </a>
                )}
                {window.ethereum && !window.ethereum?.selectedAddress &&
                    <Link onClick={this.connectWallet} style={styles.specialLink}>
                        Connect Wallet
                    </Link>
                }
            </React.Fragment>
        )
    }

    renderNoAccount() {
        return (
            <React.Fragment>
                <a href={'/users/new'}>
                    <Link style={styles.link}>
                        Sign Up
                    </Link>
                </a>
                <a href={'/users/login'}>
                    <Link style={styles.link}>
                        Log In
                    </Link>
                </a>
            </React.Fragment>
        )
    }

    renderHamburger() {
        return (
            <div onClick={() => this.setState({menuExpanded: !this.state.menuExpanded})}>
                <div style={{width: '40px', height: '4px', borderRadius: '5px', backgroundColor: '#DDD'}} />
                <div style={{width: '40px', height: '4px', borderRadius: '5px', backgroundColor: '#DDD', marginTop: '8px'}} />
                <div style={{width: '40px', height: '4px', borderRadius: '5px', backgroundColor: '#DDD', marginTop: '8px'}} />
            </div>
        )
    }

    renderMobile() {
        return (
            <div>
                {this.renderHamburger()}
            </div>
        )
    }

    renderDesktop() {
        return (
            <div style={styles.links}>
                <a href={'/drops'}>
                    <Link style={{...styles.link, color: this.isActivePath('drops') ? 'white' : '#999'}}>
                        Drops
                    </Link>
                </a>
                <a href={'/tokens'}>
                    <Link style={{...styles.link, color: this.isActivePath('tokens') ? 'white' : '#999'}}>
                        Marketplace
                    </Link>
                </a>
                {this.state.loggedIn ? this.renderAccount() : this.renderNoAccount()}
            </div>
        )
    }

    renderMenu() {
        return (
            <div style={{...styles.menuBack, height: this.state.menuExpanded ? 'initial' : 0}}>
                <div style={styles.menuBackInner}>
                    <a href={'/drops'}>
                        <div style={{...styles.menuLink}}>
                            Drops
                        </div>
                    </a>
                    <a href={'/tokens'}>
                        <div style={{...styles.menuLink}}>
                            Marketplace
                        </div>
                    </a>
                    {this.state.loggedIn ? this.renderAccountMobile() : this.renderNoAccountMobile()}
                </div>
            </div>
        )
    }

    renderAccountMobile() {
        return (
            <React.Fragment>
                <a href={`/referrals`}>
                    <div style={styles.menuLink}>
                        Free NFTs
                    </div>
                </a>
                <a href={`/transactions`}>
                    <div style={styles.menuLink}>
                        Transactions
                    </div>
                </a>
                <a href={`/settings`}>
                    <div style={styles.menuLink}>
                        Settings
                    </div>
                </a>
                <a href={`/users/${this.props.user.slug}`}>
                    <div style={styles.menuLink}>
                        Dashboard
                    </div>
                </a>
                {/*<div onClick={this.logOut} style={{...styles.menuLink, marginBottom: 0, paddingBottom: 0, borderBottom: 'none'}}>*/}
                {/*    Log Out*/}
                {/*</div>*/}
                {!window.ethereum && (
                    <a href={'https://metamask.io/download'} target={"_blank"}>
                        <Link style={styles.specialLinkMobile}>
                            Connect Wallet
                        </Link>
                    </a>
                )}
                {window.ethereum && (!window.ethereum?.selectedAddress || this.state.connected) &&
                <Link onClick={this.connectWallet} style={styles.specialLinkMobile}>
                    Connect Wallet
                </Link>
                }
            </React.Fragment>
        )
    }

    renderNoAccountMobile() {
        return (
            <React.Fragment>
                <a href={'/users/new'}>
                    <div style={styles.menuLink}>
                        Sign Up
                    </div>
                </a>
                <a href={'/users/login'}>
                    <div style={styles.menuLink}>
                        Log In
                    </div>
                </a>
            </React.Fragment>
        )
    }

    render () {
        return (
            <React.Fragment>
                <div style={styles.container}>
                    <div style={styles.innerContainer}>
                        <a href={'/'}>
                            <div style={styles.logo}>
                                <span style={{color: '#5dd982'}}>
                                    Nano
                                </span>
                                {" Bash"}
                            </div>
                        </a>
                        {this.state.mobile ? this.renderMobile() : this.renderDesktop()}
                    </div>
                </div>
                {this.state.mobile ? this.renderMenu() : null}
                {this.renderToasts()}
            </React.Fragment>
        );
    }
}

export default Header
