import React from "react"
import styled from "styled-components";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        borderRadius: '20px',
        margin: 30,
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px',
        boxSizing: 'border-box',
        width: '100%',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#EEE',
    },
    extraInfo: {
        padding: '15px',
        boxSizing: 'border-box',
        backgroundColor: '#F6F6F6',
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    collectionName: {
        fontSize: '20px',
        width: '100%',
    },
    collectionAuthor: {
        fontSize: '14px',
        color: '#666',
        marginTop: '5px',
    },
    ownerSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    ownerTitle: {
        color: '#999',
        fontSize: '14px',
    },
    owner: {
        fontSize: '18px',
    },
    price: {
        fontSize: '18px',
        fontWeight: '600',
    },
    percent: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#5dd982',
    },
    percentNeg: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#ce0000',
    },
    attributes: {
        marginTop: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '300px',
    },
    traitSection: {
        display: 'inline-block',
        width: '100px',
        margin: '10px 5px 5px',
        border: '1px solid #CCC',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '10px',
    },
    traitCategory: {
        fontSize: '14px',
        color: '#5dd982',
    },
    traitPercentage: {
        fontSize: '14px',
        color: '#999',
        marginTop: '5px',
    },
    trait: {
        fontWeight: '600',
        fontSize: '16px',
    },
    onSaleFor: {
        color: '#999',
        fontSize: '14px',
        marginBottom: '5px',
    },
    sellType: {
        display: 'inline-flex',
        padding: '3px 10px 5px',
    },
    numAvailable: {
        color: '#666',
        marginTop: '5px',
        fontSize: '12px',
    },
    rightInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}

const Container = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
transition: 0.3s;
:hover {
    background-color: #FAFAFA !important;
    box-shadow: rgb(0, 0, 0, 0.2) 0px 4px 16px !important;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
}
`

class TokenMiniListItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    percentageChange = () => {
        if (this.props.price_1d === 0) {
            return 0
        }
        if (isNaN(Math.round(((this.props.last_price / this.props.price_1d) * 100) - 100))) {
            return 0
        }
        return Math.round(((this.props.last_price / this.props.price_1d) * 100) - 100)
    }

    render() {
        let imageStyle = {width: '100%', maxWidth: this.state.mobile ? '300px' : '300px', height: this.state.mobile ? '300px' : '300px', objectFit: 'cover', borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}
        return (

            <React.Fragment>
                <Container style={{...styles.container, width: '300px'}}>
                    <a style={{width: '300px'}} href={`/tokens/${this.props.slug}`}>
                        <div style={styles.imageContainer}>
                            <img style={imageStyle} src={this.props.thumbnail_url} />
                        </div>
                        <div style={styles.infoContainer}>
                            <div style={styles.collectionName}>
                                <div>
                                    <a href={`/tokens/${this.props.slug}`}>
                                        {this.props.name}
                                    </a>
                                </div>
                                <div style={styles.collectionAuthor}>
                                    {'by ' + this.props.user.username}
                                </div>
                            </div>
                        </div>
                        <div style={styles.divider} />

                        <div style={styles.extraInfo}>

                            <div>
                                <div style={styles.onSaleFor}>
                                    Last Price
                                </div>
                                <div style={styles.price}>
                                    {(this.props.last_price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                </div>
                                <div style={{...styles.onSaleFor, marginTop: '15px'}}>
                                    Original Price
                                </div>
                                <div style={styles.price}>
                                    {(this.props.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                </div>
                            </div>
                            <div style={styles.rightInfo}>
                                <div style={styles.onSaleFor}>
                                    24h Change
                                </div>
                                <div style={this.percentageChange() >= 0 ? styles.percent : styles.percentNeg}>
                                    {`${this.percentageChange() >= 0 ? '+' : ''}${this.percentageChange()}%`}
                                </div>
                                <div style={{...styles.onSaleFor, marginTop: '15px'}}>
                                    Editions Minted
                                </div>
                                <div style={styles.price}>
                                    {this.props.quantity_minted}
                                    {/*+ '/' + (this.props.locked ? this.props.quantity_minted : this.props.quantity)*/}
                                </div>
                            </div>
                        </div>
                    </a>
                </Container>
            </React.Fragment>
        );
    }
}

export default TokenMiniListItem
