import React from "react"
import styled from "styled-components";
import Toast from "./Toast";

const styles = {
    inputSection: {
        width: '100%',
        marginBottom: '20px',
        marginTop: '20px',
    },
    inputLabel: {
        fontSize: '18px',
        fontWeight: '300',
        margin: '5px 0',
        color: '#333',
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    scanTitle: {
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '20px',
    },
    cardTitle: {
        paddingBottom: '10px',
        boxSizing: 'border-box',
        marginBottom: '30px',
        borderBottom: '1px solid #CCC',
        fontSize: '24px',
        fontWeight: '600',
        width: '100%',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'inline-flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
}

const SubmitButton = styled.button`
:hover {
    background-color: #49ab67 !important;
}
`

let toastID = 1

class SettingsTwoFactorAuth extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            otp: '',
            toasts: [],
            otp_required_for_login: props.user.otp_required_for_login
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    activate2fa = (otpRequiredForLogin) => {

        const body = {
            otp: this.state.otp,
            otp_required_for_login: otpRequiredForLogin
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        const trueThis = this

        fetch(`${window.location.origin}/api/v0/users/${this.props.user?.slug}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    let newToast = {
                        title: `2FA ${otpRequiredForLogin ? '' : 'de'}activated`,
                        information:  `You have successfully ${otpRequiredForLogin ? '' : 'de'}activated 2FA.`,
                        id: toastID,
                    }
                    toastID++
                    trueThis.state.toasts.push(newToast)
                    trueThis.setState({otp_required_for_login: otpRequiredForLogin, toasts: trueThis.state.toasts})
                } else {
                }
            })
            .catch(err => {

            })
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderActive2fa = () => {
        return (
            <React.Fragment>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        OTP Code
                    </div>
                    <input style={styles.inputBox} maxLength="6" name="otp" value={this.state.otp} onChange={this.onChange.bind(this)} />
                </div>
                <SubmitButton onClick={this.activate2fa.bind(this, true)} style={styles.submitButton}>
                    Activate 2FA
                </SubmitButton>
            </React.Fragment>
        )
    }

    renderDeactivate2fa = () => {
        return (
            <SubmitButton onClick={this.activate2fa.bind(this, false)} style={{...styles.submitButton, marginTop: 20}}>
                Deactivate 2FA
            </SubmitButton>
        )
    }

    render() {
        return (
            <div>
                <div style={styles.cardTitle}>
                    {this.state.otp_required_for_login ? '2FA Currently Enabled' : 'Scan & Enter Code To Activate 2FA'}
                </div>
                <div dangerouslySetInnerHTML={{__html: this.props.two_factor_qr}} />
                {this.state.otp_required_for_login ? this.renderDeactivate2fa() : this.renderActive2fa() }
                {this.renderToasts()}
            </div>
        )
    }
}

export default SettingsTwoFactorAuth