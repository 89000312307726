import React from "react"
import styled from "styled-components";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        borderRadius: '20px',
        margin: '30px 20px',
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px',
        boxSizing: 'border-box',
        width: '100%',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#EEE',
    },
    extraInfo: {
        padding: '15px',
        boxSizing: 'border-box',
        backgroundColor: '#F6F6F6',
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    collectionName: {
        fontSize: '18px',
        width: '100%',
    },
    collectionAuthor: {
        fontSize: '12px',
        color: '#666',
        marginTop: '5px',
    },
    ownerSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    ownerTitle: {
        color: '#999',
        fontSize: '14px',
    },
    owner: {
        fontSize: '18px',
    },
    price: {
        fontSize: '14px',
        fontWeight: '600',
    },
    percent: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#5dd982',
    },
    percentNeg: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#ce0000',
    },
    attributes: {
        marginTop: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '190px',
    },
    traitSection: {
        display: 'inline-block',
        width: '100px',
        margin: '10px 5px 5px',
        border: '1px solid #CCC',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '10px',
    },
    traitCategory: {
        fontSize: '14px',
        color: '#5dd982',
    },
    traitPercentage: {
        fontSize: '14px',
        color: '#999',
        marginTop: '5px',
    },
    trait: {
        fontWeight: '600',
        fontSize: '16px',
    },
    onSaleFor: {
        color: '#999',
        fontSize: '12px',
        marginBottom: '2px',
    },
    sellType: {
        display: 'inline-flex',
        padding: '3px 10px 5px',
    },
    numAvailable: {
        color: '#666',
        marginTop: '5px',
        fontSize: '12px',
    },
    rightInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}

const Container = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
transition: 0.3s;
:hover {
    background-color: #FAFAFA !important;
    box-shadow: rgb(0, 0, 0, 0.2) 0px 4px 16px !important;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
}
`

class UserTokenListItemMini extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    percentageChange = () => {
        if (this.props.token.price_1d === 0) {
            return 0
        }

        return Math.round(((this.props.token.last_price / this.props.token.price_1d) * 100) - 100)
    }

    render() {
        let imageStyle = {width: '100%', maxWidth: this.state.mobile ? '190px' : '190px', height: this.state.mobile ? '190px' : '190px', objectFit: 'cover', borderTopLeftRadius: '20px', borderTopRightRadius: '20px'}
        return (

            <React.Fragment>
                <Container style={{...styles.container, width: '190px'}}>
                    <div style={{width: '190px', cursor: 'pointer'}} onClick={() => this.props.clickMethod()}>
                        <div style={styles.imageContainer}>
                            <img style={imageStyle} src={this.props.token.thumbnail_url} />
                        </div>
                        <div style={styles.infoContainer}>
                            <div style={styles.collectionName}>
                                <div>
                                    {this.props.token.name}
                                </div>
                                <div style={styles.collectionAuthor}>
                                    {'by ' + this.props.token.user.username}
                                </div>
                            </div>
                        </div>
                        <div style={styles.divider} />
                        <div style={styles.extraInfo}>
                            <div>
                                <div style={styles.onSaleFor}>
                                    Quantity
                                </div>
                                <div style={styles.price}>
                                    {this.props.quantity}
                                </div>

                            </div>
                            <div style={styles.rightInfo}>
                                <div style={styles.onSaleFor}>
                                    Last Price
                                </div>
                                <div style={styles.price}>
                                    {((this.props.token.last_price/100) || 0).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default UserTokenListItemMini

