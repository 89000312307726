import React from "react"
import styled from "styled-components";
import moment from "moment";
import Toast from "./Toast";

const styles = {
    containerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    container: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
        maxWidth: '1000px',
        margin: '30px 20px',
        boxSizing: 'border-box',
        borderRadius: '20px',
    },
    imageContainer: {
        width: '100%',
    },
    h1: {
        fontWeight: '600',
        fontSize: '36px',
        margin: '40px 0 0',
        fontFamily: 'Lato, Arial',
        width: '100%',
        textAlign: 'center',
    },
    name: {
        fontWeight: '600',
        fontSize: '30px',
        marginTop: '5px',
    },
    priceContainer: {
        padding: 20,
        margin: 10,
        border: '1px solid #CCC',
        borderRadius: '20px',
    },
    price: {
        fontWeight: '600',
        fontSize: '24px',
    },
    attributesContainer: {
        marginTop: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    purchaseInfo: {
        textAlign: 'center',
    },
    descriptor: {
        color: '#666',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#e73b1d',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '18px',
        fontWeight: '600',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '10px',
    },
    cancelContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    }
}

const Container = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.input`
:hover {
    background-color: #ff6146 !important;
}
`

let toastId = 1

class OrderPage extends React.Component {

    capitalize = (str) => (str[0].toUpperCase() + str.slice(1, str.length))

    constructor(props) {
        super(props)

        this.state = {
            toasts: [],
        }
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderYouReceived = () => {
        return (
            <React.Fragment>
                <div style={styles.priceContainer}>
                    <div style={styles.descriptor}>
                        Fees (8%)
                    </div>
                    <div style={styles.price}>
                        {(Math.ceil(this.props.average_fill_price * this.props.order.quantity_filled * .08)/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.priceContainer}>
                    <div style={styles.descriptor}>
                        You Received
                    </div>
                    <div style={styles.price}>
                        {(Math.floor(this.props.average_fill_price * this.props.order.quantity_filled * .92)/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    cancelOrder = () => {
        const body = {}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/orders/${this.props.order.id}/cancel`, {
            method: "POST",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    console.log('res', res)
                    if (res.order.status === 'pending_cancelled') {
                        res.order.status = 'Cancelling'
                    }
                    this.state.toasts.push({title: 'Order Cancelling', information: 'You have successfully requested to cancel the order', id: toastId++})
                    this.setState({toasts: this.state.toasts})
                } else {
                    this.state.toasts.push({title: 'Cancel Failed', information: res.message, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                }
            })
            .catch(err => {
            })
    }

    render() {
        return (
            <div style={styles.containerContainer}>
                <div style={styles.h1}>
                    Order Details
                </div>
                <Container style={styles.container}>
                    <div style={styles.purchaseInfo}>
                        <a href={`/tokens/${this.props.order.token.slug}`}>
                            <div style={styles.imageContainer}>
                                <img style={{width: '100%', maxWidth: '500px', height: '375px', objectFit: 'cover', borderRadius: '20px'}} src={this.props.order.token.thumbnail_url} />
                            </div>
                            <div style={styles.name}>
                                {this.props.order.token.name}
                            </div>
                            <div style={styles.author}>
                                {'by ' + this.props.order.token.user.username}
                            </div>
                        </a>
                    </div>
                    <div style={styles.attributesContainer}>
                        <div style={styles.priceContainer}>
                            <div style={styles.descriptor}>
                                Offered Price
                            </div>
                            <div style={styles.price}>
                                {(this.props.order.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div style={styles.descriptor}>
                                Average Fill Price
                            </div>
                            <div style={styles.price}>
                                {(this.props.average_fill_price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                        {this.props.order.order_type === 'limit_sell' && this.renderYouReceived()}
                        <div style={styles.priceContainer}>
                            <div style={styles.descriptor}>
                                Order placed on
                            </div>
                            <div style={styles.price}>
                                {moment(new Date(this.props.order.created_at)).format("MMM DD, YYYY")}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div style={styles.descriptor}>
                                Status
                            </div>
                            <div style={styles.price}>
                                {this.capitalize(this.props.order.status)}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div style={styles.descriptor}>
                                Quantity Filled
                            </div>
                            <div style={styles.price}>
                                {`${this.props.order.quantity_filled} / ${this.props.order.quantity}`}
                            </div>
                        </div>
                        <div style={styles.priceContainer}>
                            <div style={styles.descriptor}>
                                Order Type
                            </div>
                            <div style={styles.price}>
                                {`${(this.props.order.order_type === 'limit_sell' || this.props.order.order_type === 'market_sell') ? 'Sell' : 'Buy'}`}
                            </div>
                        </div>
                        {this.props.order.order_type === 'limit_buy' && (
                            <div style={styles.priceContainer}>
                                <div style={styles.priceTitle}>
                                    Payment Method
                                </div>
                                <div style={styles.price}>
                                    {this.props.order.payment_method}
                                </div>
                            </div>
                        )}
                        {this.props.order.payment_method === 'eth' && this.props.order.order_type === 'limit_buy' && (
                            <React.Fragment>
                                <div style={styles.priceContainer}>
                                    <div style={styles.priceTitle}>
                                        Payment Status
                                    </div>
                                    <div style={styles.price}>
                                        {this.props.order.payment_received ? 'Received' : 'Pending'}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    {this.renderToasts()}
                </Container>
                {this.props.order.status === 'pending' &&
                    <div style={styles.cancelContainer}>
                        <SubmitButton type="submit" value="Cancel Order" onClick={this.cancelOrder.bind(this)} style={styles.submitButton}/>
                    </div>
                }
            </div>
        );
    }
}

export default OrderPage