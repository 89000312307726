import React from "react"
import styled from "styled-components";
import ErrorSection from "./ErrorSection";
import Toast from "./Toast";
import TokenMiniListItem from "./TokenMiniListItem";

const styles = {
    inputSection: {
        width: '100%',
        marginBottom: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    header: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        margin: '20px 0'
    },
    verified: {
        fontSize: '28px',
        fontWeight: '600',
        color: '#5dd982',
    },
    changeNumber: {
        fontSize: '12px',
        cursor: 'pointer',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    disabledButton: {
        border: 'none',
        backgroundColor: '#CCC',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    emailVerificationContainer: {
    },
    resendTitle: {
        fontSize: '24px',
        color: '#e73b1d',
        fontWeight: '600',
    },
    resend: {
        cursor: 'pointer',
        color: '#5dd982',
    },
    submitButtonMini: {
        display: 'inline-flex',
        padding: '6px 12px',
        fontSize: '16px',
        fontWeight: '400',
        backgroundColor: 'white',
        border: '1px solid #333',
        alignItems: 'center',
        color: '#333',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
        marginTop: '5px',
    },
    cardTitle: {
        paddingBottom: '10px',
        boxSizing: 'border-box',
        marginBottom: '10px',
        borderBottom: '1px solid #CCC',
        fontSize: '24px',
        fontWeight: '600',
        width: '100%',
    },
    miniDescription: {
        fontSize: '12px',
        color: '#666',
        marginBottom: '10px',
    },
    rewardInfo: {
        marginTop: '40px',
        fontWeight: '600',
        fontSize: '24px',
    }
}

const SubmitButton = styled.div`
:hover {
    background-color: #49ab67 !important;
}
`

let toastId = 1

class SettingsVerification extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            phone_number: props.user.phone_number ?? "",
            code_sent: false,
            verified: props.user.verified_phone,
            email_verified: !!props.user.confirmed_at,
            code: "",
            toasts: [],
            showReward: false,
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    submitForm(e) {
        const body = {
            phone_number: this.state.phone_number,
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        this.setState({code_sent: true})

        fetch(`${window.location.origin}/api/v0/users/${this.props.user?.slug}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.state.toasts.push({title: 'SMS Confirmation Sent', information:  `We have sent you a text message with your verification code. Enter it below.`, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                } else {
                    this.setState({errors: err.errors})
                    scroll(0,0)
                }
            })
            .catch(err => {

            })
    }

    submitConfirmationForm(e) {
        const body = {
            confirmation_code: this.state.code,
        }

        if (this.state.code.length < 5) {
            return
        }

        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/verification_codes`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({verified: true})
                } else {
                    this.setState({errors: res.errors})
                    scroll(0,0)
                }
            })
            .catch(err => {
            })
    }

    renderToBeVerified = () => {
        return (
            <React.Fragment>
                <ErrorSection errors={this.state.errors} />
                <div style={styles.resendTitle}>
                    Not Confirmed
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Phone Number (Include country code)
                    </div>
                    <input maxLength="18" style={styles.inputBox} name="phone_number" value={this.state.phone_number} onChange={this.onChange.bind(this)} />
                </div>
                {!this.state.code_sent &&
                (   <SubmitButton onClick={this.submitForm.bind(this)} name="commit" style={styles.submitButton}>
                    Send Verification Text
                </SubmitButton>)
                }

                <div style={{...styles.inputSection, marginTop: '50px'}}>
                    <div style={styles.inputLabel}>
                        Enter Code
                    </div>
                    <input maxLength="5" style={styles.inputBox} name="code" value={this.state.code} onChange={this.onChange.bind(this)} />
                </div>
                <SubmitButton disabled={this.state.code.length < 5} onClick={this.submitConfirmationForm.bind(this)} name="commit" style={this.state.code.length < 5 ? styles.disabledButton : styles.submitButton}>
                    Confirm Code
                </SubmitButton>
            </React.Fragment>
        )
    }

    renderVerified = () => {
        return (
            <React.Fragment>
                <div style={styles.verified}>
                    Verified
                </div>
                <div style={styles.submitButtonMini} onClick={() => this.setState({verified: false})}>
                    Change Number
                </div>
            </React.Fragment>
        )
    }

    sendEmailVerification = () => {
        const body = {
        }

        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/users/resend_verification_email`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.state.toasts.push({title: 'Email Confirmation Sent', information:  `We have resent your confirmation. Please check your email.`, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                } else {
                    this.setState({errors: res.errors})
                    scroll(0,0)
                }
            })
            .catch(err => {
            })
    }

    renderEmailVerification = () => {
        if (this.state.email_verified) {
            return (
                <div style={styles.verified}>
                    Verified
                </div>
            )
        }
        return (
            <div style={styles.emailVerificationContainer}>
                <div style={styles.resendTitle}>
                    Not Confirmed
                </div>
                <div onClick={this.sendEmailVerification.bind(this)} style={styles.submitButtonMini}>
                    Resend email verification
                </div>
            </div>
        )
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    render() {
        return (
            <div>
                <div style={styles.cardTitle}>
                    Verify your account
                </div>
                <div style={styles.miniDescription}>
                    Please note that the extra reward of the NFT "Jungle" is no longer available. All 20,000 have been claimed.
                </div>
                {/*{!this.state.showReward ? (*/}
                {/*    <div style={styles.submitButtonMini} onClick={() => this.setState({showReward: true})}>*/}
                {/*        Show Reward*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <div style={styles.submitButtonMini} onClick={() => this.setState({showReward: false})}>*/}
                {/*        Hide Reward*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*{this.state.showReward && <TokenMiniListItem {...this.props.referral_token} />}*/}

                {/*<div style={{...styles.cardTitle, marginTop: '20px'}}>*/}
                {/*    Phone Verification*/}
                {/*</div>*/}
                {/*{this.state.verified ? this.renderVerified() : this.renderToBeVerified()}*/}

                <div style={{...styles.cardTitle, marginTop: '40px'}}>
                    Email Verification
                </div>
                {this.renderEmailVerification()}
                {this.renderToasts()}
            </div>
        )
    }
}

export default SettingsVerification