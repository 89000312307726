import React from "react"

const styles = {
    container: {
        width: '100%',
        fontFamily: 'Lato, Arial',
        boxSizing: 'border-box',
        display: 'flex',
        padding: '40px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containerMobile: {
        fontFamily: 'Lato, Arial',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        padding: '40px',
        textAlign: 'center',
    },
    rightSide: {
        display: 'inline-flex'
    },
    link: {
        color: '#5dd982',
        margin: '0 30px',
    },
    rightSideMobile: {
        display: 'flex',
        flexDirection: 'column'
    },
    linkMobile: {
        color: '#5dd982',
        margin: '20px 0 0',
    },
    twitterColor: {
        backgroundColor: '#1da1f2',
    },
    instagramColor: {
        backgroundColor: '#E1306C',
    },
    discordColor: {
        backgroundColor: '#7289DA',
    },
    icon: {
        width: '40px',
        height: '40px',
        borderRadius: '80px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        fontSize: '24px',
        color: 'white',
        marginRight: '20px',
    },
    leftSide: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    leftSideMobile: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    }
}

class Footer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    render () {
        return (
            <div style={this.state.mobile ? styles.containerMobile : styles.container}>
                <div style={this.state.mobile ? styles.leftSideMobile : styles.leftSide}>
                    <a style={{...styles.icon, ...styles.twitterColor}} href={'https://twitter.com/nanobashnft'} target={"_blank"}>
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a style={{...styles.icon, ...styles.instagramColor}} href={'https://www.instagram.com/nanobashnft/'} target={"_blank"}>
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a style={{...styles.icon, ...styles.discordColor, ...(this.state.mobile ? {marginRight: 0} : {})}} href={'https://discord.gg/xRhQC5n7B9'} target={"_blank"}>
                        <i className="fab fa-discord"></i>
                    </a>
                    <div style={this.state.mobile ? {marginTop: '20px', width: '100%'} : {}}>
                        @ 2021 NanoBash, LLC.
                    </div>
                </div>
                <div style={this.state.mobile ? styles.rightSideMobile : styles.rightSide}>
                    <a href={`/about`}>
                        <div style={this.state.mobile ? styles.linkMobile : styles.link}>
                            FAQ
                        </div>
                    </a>
                    <a href={`mailto:contact@nanobash.com`}>
                        <div style={this.state.mobile ? styles.linkMobile : styles.link}>
                            Contact Us
                        </div>
                    </a>
                    <a href={`/privacy`}>
                        <div style={this.state.mobile ? styles.linkMobile : styles.link}>
                            Privacy Policy
                        </div>
                    </a>
                    <a href={`/terms`}>
                        <div style={this.state.mobile ? styles.linkMobile : styles.link}>
                            Terms of Service
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

export default Footer
