import React from "react"
import styled from "styled-components";
import ErrorSection from "./ErrorSection";

const styles = {
    container: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
    },
    h1: {
        fontSize: 36,
        fontWeight: 600,
        marginBottom: '25px',
        marginTop: '35px',
    },
    formContainer: {
        maxWidth: '800px',
        padding: '30px',
        boxSizing: 'border-box',
        width: '100%',
    },
    inputSection: {
        width: '100%',
        marginBottom: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.input`
:hover {
    background-color: #49ab67 !important;
}
`

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            password: '',
            password_confirmation: '',
        }
    }

    submitForm = () => {

        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        const body = this.state
        body.reset_password_token = this.props.reset_password_token

        fetch(`${window.location.origin}/api/v0/users/reset_password`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    window.location.href = "/"
                }
                if (!res.success) {
                    this.setState({errors: res.errors})
                    scroll(0,0)
                }
            })
            .catch(err => {
                this.setState({errors: err.errors})
                scroll(0,0)
            })
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    render () {
        return (
            <React.Fragment>
                <div style={styles.container}>
                    <div style={styles.h1}>
                        Reset Password
                    </div>
                    <FormContainer style={styles.formContainer}>
                        <ErrorSection errors={this.state.errors} />
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                New Password
                            </div>
                            <input type="password" style={styles.inputBox} name="password" value={this.state.password} onChange={this.onChange.bind(this)} />
                        </div>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Confirm Password
                            </div>
                            <input type="password" style={styles.inputBox} name="password_confirmation" value={this.state.password_confirmation} onChange={this.onChange.bind(this)} />
                        </div>

                        <SubmitButton type="submit" onClick={this.submitForm.bind(this)} name="commit" value="Send Reset Password Instructions" style={styles.submitButton} data-disable-with="
submit" />
                    </FormContainer>
                </div>
            </React.Fragment>
        );
    }
}

export default ResetPassword