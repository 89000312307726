import React from "react"
import styled from "styled-components";
import Toast from "./Toast";
import MediaModal from "./MediaModal";
import TradeList from "./TradeList";
import moment from "moment";
import ErrorSection from "./ErrorSection";

const styles = {
    topSection: {
        width: '100%',
        padding: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        backgroundColor: 'white',
        borderBottom: '1px solid #EEE',
    },
    container: {
        maxWidth: '1000px',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: '0 auto',
        padding: '50px 20px 0',
    },
    containerMobile: {
        maxWidth: '1000px',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 auto',
        padding: '50px 20px 0',
    },
    nft: {
        marginRight: '50px',
        maxWidth: '500px',
        width: '100%',
        borderRadius: '15px',
    },
    nftMobile: {
        maxWidth: '300px',
        width: '100%',
        borderRadius: '15px',
    },
    title: {
        fontWeight: '600',
        fontSize: '24px',
    },
    author: {
        fontSize: '18px',
        color: '#999',
    },
    description: {
        fontSize: '14px',
        color: "#333",
        marginTop: '20px',
    },
    editButton: {
        border: '1px solid #333',
        padding: 10,
        marginTop: '20px',
        display: 'inline-flex',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    info: {
        boxSizing: 'border-box',
        borderRadius: '15px',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '450px',
        width: '100%',
    },
    infoMobile: {
        boxSizing: 'border-box',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginTop: '30px',
        width: '100%',
        maxWidth: '800px',
    },
    infoInner: {
        width: '100%',
        padding: '30px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '530px',
        borderRadius: '15px',
    },
    infoInnerMobile: {
        padding: '30px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '800px',
        borderRadius: '15px',
    },
    submitSection: {
        width: '100%',
        padding: '30px',
        boxSizing: 'border-box',
        borderRadius: '15px',
    },
    submitSectionMobile: {
        width: '100%',
        padding: '30px',
        boxSizing: 'border-box',
        borderRadius: '15px',
        marginTop: '30px',
    },
    inputSection: {
        width: '100%',
        marginBottom: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '18px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
        width: '100%',
    },
    disabledButton: {
        border: 'none',
        backgroundColor: '#CCC',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    section: {
        marginBottom: '20px',
    },
    sectionTitle: {
        fontSize: '18px',
        color: '#666',
        marginBottom: '5px',
    },
    sellType: {
        fontSize: '30px',
        fontWeight: '600',
        marginBottom: '30px',
    },
    username: {
        fontSize: '30px',
        fontWeight: '600',
        marginBottom: '30px',
        color: '#5dd982',
    },
    attributes: {
        marginBottom: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '400px',
    },
    traitSection: {
        display: 'inline-block',
        width: '100px',
        margin: '10px 5px 5px',
        borderRadius: '5px',
        padding: '10px',
        textAlign: 'center',
    },
    traitCategory: {
        fontSize: '14px',
        color: '#5dd982',
    },
    traitPercentage: {
        fontSize: '14px',
        color: '#999',
        marginTop: '5px',
    },
    trait: {
        fontWeight: '600',
        fontSize: '16px',
    },
    itemDetailInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
    },
    infoContainer: {
        padding: '10px 0',
        minWidth: '140px',
        margin: '10px',
        borderRadius: '5px',
        backgroundColor: 'white',
        textAlign: 'center',
    },
    infoTitle: {
        fontSize: '12px',
        color: '#999',
    },
    infoText: {
        fontWeight: '600',
        fontSize: '20px',
    },
    breadcrumb: {
        color: '#CCC',
        marginBottom: '30px',
    },
    breadcrumbLink: {
        color: '#5dd982',
    },
    header: {
        padding: '20px',
        boxSizing: 'border-box',
    },
    paymentHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paymentHeaderTitle: {
        fontWeight: '600',
        fontSize: '22px',
        marginBottom: '20px',
    },
    paymentMethods: {
        display: 'flex',
    },
    paymentMethodButton: {
        boxSizing: 'border-box',
        padding: '10px',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    cardContainer: {
        width: '100%',
        border: '1px solid #CCC',
        padding: '20px',
        boxSizing: 'border-box',
        margin: '20px 0',
        textAlign: 'center',
    },
    cardDetail: {
        marginBottom: '10px',
    },
    balanceText: {
        fontWeight: '600',
        fontSize: '20px',
    },
    rightSide: {
        alignItems: 'right',
        textAlign: 'right',
    },
    rightSideMobile: {
        alignItems: 'left',
        textAlign: 'left',
    }
}

const InfoContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`



const SubmitButton = styled.input`
:hover {
    background-color: #49ab67 !important;
}
`

let toastId = 1

class UserTokenPage extends React.Component {
    constructor(props) {
        super(props)

        const map = {}
        const total_weights = {}

        props.item.item_detail.trait_categories.forEach((trait_category) => {
            map[trait_category.id] = trait_category.name
            total_weights[trait_category.id] = trait_category.total_weight
        })

        this.state = {
            trait_categories: map,
            on_sale: props.item.on_sale,
            total_weights: total_weights,
            askingPrice: props.item.price,
            can_edit: props.user?.id === props.item.user.id,
            price: (!!this.props.item.price && this.props.item.price !== 0) ? ((parseFloat(this.props.item.price)/100).toFixed(2) + "") : "",
            toasts: [],
            modal: false,
            mobile: window.innerWidth < 1000,
            payment_method_preference: props.user?.payment_method_preference,
        }
    }

    componentDidMount() {
        setInterval(() => this.setState({timeToDropStart: this.state.timeToDropStart-1, timeToDropEnd: this.state.timeToDropEnd - 1}), 1000)
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    submitForm() {
        let price = this.state.price
        if (price === "" || price === null) {
            price = 0
        }
        if (price[0] === '$') {
            price = parseFloat(price.substr(1, price.length))
        }
        price = Math.floor(price * 100)

        if (isNaN(price)) {
            this.state.toasts.push({title: 'Unabled to update', information:  `Price is not a number`, id: toastId++})
            this.setState({toasts: this.state.toasts})
            return
        }

        const body = {item: {price: price}}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/items/${this.props.item.id}`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    console.log(res.item.on_sale)
                    this.state.toasts.push({title: 'Price Updated', information:  `You have updated the price to ${(res.item.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}`, id: toastId++})
                    this.setState({toasts: this.state.toasts, on_sale: res.item?.on_sale, askingPrice: res.item?.price})
                }
            })
            .catch(err => {
            })
    }

    submitBuy() {
        const body = {
            item_id: this.props.item.id,
            payment_method: this.state.payment_method_preference,
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/trades`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    window.location.href = `/trades/${res.trade.id}`
                } else {
                    this.setState({errors: res.errors})
                }
            })
            .catch(err => {
                this.setState({errors: err.errors})
            })
    }

    renderUpdatePrice() {
        let price = this.state.price
        if (price === "" || price === null || price === "$") {
            price = 0
        }
        if (price[0] === '$') {
            price = parseFloat(price.substr(1, price.length))
        }
        price = Math.floor(price * 100)

        let buttonText

        if (price === 0) {
            buttonText = "Enter a for sale price"
        } else if (!isNaN(price)) {
            price = (price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})
            buttonText = `Update price to ${price}`
            if (!this.state.askingPrice || this.state.askingPrice === 0 || this.state.askingPrice === "0") {
                buttonText = `Place on sale for ${price}`
            }
        } else {
            buttonText = 'Invalid Price - Please enter a number'
        }

        return (
            <React.Fragment>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Set Price - Enter price in USD
                    </div>
                    <input maxLength="10" placeholder={'$1000.00'} style={styles.inputBox} name="price" value={this.state.price} onChange={this.onChange.bind(this)} />
                </div>
                <SubmitButton type="submit" onClick={this.submitForm.bind(this)} name="commit" value={buttonText} style={styles.submitButton} data-disable-with="
                submit" />
            </React.Fragment>
        )
    }

    cancelSale() {
        this.setState({price: 0, askingPrice: 0})

        const body = {item: {price: 0}}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/items/${this.props.item.id}`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.state.toasts.push({title: 'Sale Cancelled', information:  `You have cancelled the sale.`, id: toastId++})
                    this.setState({on_sale: false, toasts: this.state.toasts})
                }
            })
            .catch(err => {
            })
    }

    renderCancelSaleButton() {
        if (false) {
            return null
        }

        return (
            <div onClick={this.cancelSale.bind(this)} style={styles.editButton}>
                Cancel Sale
            </div>
        )
    }

    renderBuyButton() {
        if (!this.props.item.on_sale || parseInt(this.props.item.price) === 0) {
            return null
        }
        if (this.props.user?.verified_phone) {
            return this.renderPayment()
        }

        if (this.props.user) {
            return (
                <a href={'/settings?screen=verification'}>
                    <SubmitButton type="submit" value="Verify account to purchase" style={styles.submitButton}/>
                </a>
            )
        } else {
            return (
                <a href={'/users/new'}>
                    <SubmitButton type="submit" value="Create an account to purchase" style={styles.submitButton} />
                </a>
            )
        }
    }

    renderAttribute = (trait) => {
        return (
            <div style={styles.traitSection}>
                <div style={styles.traitCategory}>
                    {this.state.trait_categories[trait.trait_category_id]}
                </div>
                <div style={styles.trait}>
                    {trait.name}
                </div>

                <div style={styles.traitPercentage}>
                    {parseFloat(`${(trait.weight * 100) / this.state.total_weights[trait.trait_category_id]}`).toFixed(2) + "%"}
                </div>
            </div>
        )
    }


    renderAttributes = () => {
        return this.props.item.traits.map(this.renderAttribute)
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderItemDetailInfo() {
        if (!this.props.item_detail_info) {
            return null
        }
        return (
            <div style={styles.itemDetailInfo}>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Last Price
                    </div>
                    <div style={styles.infoText}>
                        {(this.props.item_detail_info.last_price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Lowest for sale
                    </div>
                    <div style={styles.infoText}>
                        {(this.props.item_detail_info.lowest_for_sale/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Last Traded
                    </div>
                    <div style={styles.infoText}>
                        {this.props.item_detail_info.last_traded_datetime ? moment(new Date(this.props.item_detail_info.last_traded_datetime)).fromNow(true) + ' ago' : 'Never'}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Total Trades
                    </div>
                    <div style={styles.infoText}>
                        {this.props.item_detail_info.total_trades}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Listing Price
                    </div>
                    <div style={styles.infoText}>
                        {(this.props.item_detail_info.listing_price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Editions Minted
                    </div>
                    <div style={styles.infoText}>
                        {this.props.item_detail_info.editions_minted}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Percent Change
                    </div>
                    <div style={styles.infoText}>
                        {this.props.item_detail_info.percent_change_from_initial + "%"}
                    </div>
                </div>
            </div>
        )
    }

    renderBreadcrumb = () => {
        return (
            <div style={styles.breadcrumb}>
                <a href={'/'}>
                    <span style={styles.breadcrumbLink}>
                        Home
                    </span>
                </a>
                {' / '}
                <a href={`/collections/${this.props.item.item_detail.collection.slug}`}>
                    <span style={styles.breadcrumbLink}>
                        {this.props.item.item_detail.collection.title}
                    </span>
                </a>
                {' / '}
                <a href={`/item_details/${this.props.item.item_detail.slug}`}>
                    <span style={styles.breadcrumbLink}>
                        {this.props.item.item_detail.name}
                    </span>
                </a>
            </div>
        )
    }

    closeModal() {
        this.setState({modal: false})
    }

    renderBalance = () => {
        return (
            <div style={styles.cardContainer}>
                <div style={{color: '#666'}}>
                    Balance
                </div>
                <div style={styles.balanceText}>
                    {(this.props.user.balance/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                </div>
                <div style={{marginTop: '10px'}}>
                    {`Your balance will be deducted by `}
                    <span style={{fontWeight: '600'}}>
                                {(this.props.item.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </span>
                </div>
            </div>
        )
    }

    renderCard = () => {
        return (
            <div style={styles.cardContainer}>
                <div style={styles.cardDetail}>
                    { this.props.user.stripe_payment_method_id ? (
                        <React.Fragment>
                            {`Your credit card will be charged `}
                            <span style={{fontWeight: '600'}}>
                                {(this.props.item.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </span>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            You have not added any credit cards to your account
                        </React.Fragment>
                    )}
                </div>
                <div>
                    <a style={{color: '#5dd982'}} href='/settings/?screen=payment' target="_blank">
                        Change/Add card in settings
                    </a>
                </div>
            </div>
        )
    }

    updateUserSetting(payment_method_preference) {
        const body = {payment_method_preference: payment_method_preference}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/users/${this.props.user?.slug}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({payment_method_preference: payment_method_preference})
                } else {
                }
            })
            .catch(err => {

            })
    }

    capitalize = (str) => (str[0].toUpperCase() + str.slice(1, str.length))

    renderPaymentSubmitButton() {
        if (this.state.purchasing) {
            return (
                <SubmitButton disabled type="submit" value="Submitting..." style={styles.submitButton} />
            )
        }
        if (this.state.payment_method_preference === 'card' && !this.props.user.stripe_payment_method_id) {
            return (
                <a href={'/settings/?screen=payment'} target="_blank">
                    <SubmitButton type="submit" value="Add a credit card to purchase" style={styles.submitButton} />
                </a>
            )
        } else {
            return (
                <SubmitButton type="submit" onClick={this.submitBuy.bind(this)} name="commit"
                              value={`Purchase with ${this.state.payment_method_preference} for ${(this.props.item.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}`} style={styles.submitButton}
                              data-disable-with="
                Submit"/>
            )
        }
    }

    renderPayment = () => {
        return (
            <div>
                <div style={styles.paymentHeader}>
                    <div style={styles.paymentHeaderTitle}>
                        Choose Payment Type
                    </div>
                    <div style={styles.paymentMethods}>
                        <div style={{...styles.paymentMethodButton, border: this.state.payment_method_preference === 'card' ? '1px solid #666' : '1px solid #CCC',}} onClick={() => this.updateUserSetting('card')}>
                            Card
                        </div>
                        <div style={{...styles.paymentMethodButton, marginLeft: '20px', border: this.state.payment_method_preference === 'balance' ? '1px solid #666' : '1px solid #CCC',}} onClick={() => this.updateUserSetting('balance')}>
                            Balance
                        </div>
                    </div>
                </div>
                {this.state.payment_method_preference === 'card' ? this.renderCard() : this.renderBalance()}
                {this.renderPaymentSubmitButton()}
            </div>
        )
    }

    render () {
        return (
            <React.Fragment>
                <div style={styles.topSection}>
                    {this.renderItemDetailInfo()}
                </div>
                <div style={this.state.mobile ? styles.containerMobile : styles.container}>
                    {this.state.modal && <MediaModal close={this.closeModal.bind(this)} media_url={this.props.item.item_detail.media_url}/>}
                    <InfoContainer style={this.state.mobile ? styles.nftMobile : styles.nft}>
                        <img onClick={() => this.setState({modal: true})} style={{width: '100%', maxWidth: this.state.mobile ? '300px' : '500px', height: this.state.mobile ? '300px' : '500px', objectFit: 'cover', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', cursor: 'pointer'}} src={this.props.item.item_detail.thumbnail_url} />
                        <div style={styles.header}>
                            {this.renderBreadcrumb()}
                            <a href={`/item_details/${this.props.item.item_detail.slug}`}>
                                <div style={styles.title}>
                                    {this.props.item.item_detail.name + (this.props.item.edition ? " #" + this.props.item.edition + '/' + this.props.item.item_detail.quantity_minted : '')}
                                </div>
                            </a>
                            <div style={styles.author}>
                                {"by: " + this.props.item.item_detail.user.username}
                            </div>
                            <div style={styles.description}>
                                {this.props.item.item_detail.description}
                            </div>
                            <a href={`/marketplace?item_detail_id=${this.props.item.item_detail.id}`}> <div style={styles.editButton}> View In Marketplace </div> </a>
                        </div>
                    </InfoContainer>
                    <div style={this.state.mobile ? styles.infoMobile : styles.info}>
                        <InfoContainer style={this.state.mobile ? styles.infoInnerMobile : styles.infoInner}>
                            <div style={styles.leftSide}>
                                <div style={styles.section}>
                                    <div style={styles.sectionTitle}>
                                        Edition Owned By
                                    </div>
                                    <a href={`/users/${this.props.item.user.slug}`}>
                                        <div style={styles.username}>
                                            {this.props.item.user.username}
                                        </div>
                                    </a>
                                </div>
                                <div style={styles.section}>
                                    <div style={styles.sectionTitle}>
                                        Asking Price
                                    </div>
                                    {!this.state.on_sale ? (
                                        <div style={styles.sellType}>
                                            Not For Sale
                                        </div>
                                    ) : (
                                        <div style={styles.sellType}>
                                            {(this.state.askingPrice/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                        </div>
                                    )}

                                </div>
                                {/*<div style={styles.attributes}>*/}
                                {/*    {this.renderAttributes()}*/}
                                {/*</div>*/}
                            </div>
                            <div style={this.state.mobile ? styles.rightSideMobile : styles.rightSide}>
                                <div style={styles.section}>
                                    <div style={styles.sectionTitle}>
                                        Edition #
                                    </div>
                                    <div style={styles.price}>
                                        {this.props.item.edition ? this.props.item.edition + '/' + this.props.item.item_detail.quantity_minted : '---'}
                                    </div>
                                </div>
                                <div style={styles.section}>
                                    <div style={styles.sectionTitle}>
                                        Status
                                    </div>
                                    <div style={styles.price}>
                                        {this.props.item.status ? this.capitalize(this.props.item.status) : '---'}
                                    </div>
                                </div>
                                <div style={styles.section}>
                                    <div style={styles.sectionTitle}>
                                        Transaction
                                    </div>
                                    <div style={styles.price}>
                                        {this.props.item.tx_id ?
                                            (
                                                //<a style={{color: '#5dd982'}} href={`https://flowscan.org/transaction/${this.props.item.tx_id}`}>
                                                <a style={{color: '#5dd982'}} href={`https://flow-view-source.com/testnet/tx/${this.props.item.tx_id}`}>
                                                    {this.props.item.tx_id.substr(0, 10) + '...'}
                                                </a>
                                            )
                                            : '---'}
                                    </div>
                                </div>
                            </div>
                        </InfoContainer>
                        <InfoContainer style={this.state.mobile ? styles.submitSectionMobile : styles.submitSection}>
                            <ErrorSection errors={this.state.errors} />
                            {this.state.can_edit ? this.renderUpdatePrice() : null }
                            {this.state.can_edit && this.state.on_sale ? this.renderCancelSaleButton() : null}
                            {this.state.can_edit ? null : this.renderBuyButton() }
                        </InfoContainer>

                    </div>
                    <TradeList trades={this.props.trades} item_detail_id={this.props.item.item_detail.id} total_pages={this.props.total_pages} />
                    {this.renderToasts()}
                </div>
            </React.Fragment>
        );
    }
}

export default UserTokenPage