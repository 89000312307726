import React from "react"
import styled from "styled-components";
import Toast from "./Toast";
import UserTokenListItemMini from './UserTokenListItemMini'

const styles = {
    inputSection: {
        width: '100%',
        marginBottom: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    header: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        margin: '20px 0'
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    disabledButton: {
        border: 'none',
        backgroundColor: '#CCC',
        display: 'inline-flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'not-allowed',
        borderRadius: '5px',
    },
    cardTitle: {
        paddingBottom: '10px',
        boxSizing: 'border-box',
        marginBottom: '10px',
        borderBottom: '1px solid #CCC',
        fontSize: '24px',
        fontWeight: '600',
        width: '100%',
    },
    infoDescription: {
        color: '#666',
        marginBottom: '20px',
        fontSize: '14px',
    },
    modalContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    container: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '20px',
        zIndex: 200,
        fontFamily: 'Lato, Arial',
    },
    inputDescription: {
        color: '#666',
        fontSize: '14px',
        marginBottom: '5px',
    },
    imageContainer: {
        marginBottom: '10px',
    }
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.div`
:hover {
    background-color: #49ab67 !important;
}
`

let toastId = 1

class SettingsWithdrawNFT extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            quantity: "",
            eth_address: "",
            toasts: [],
            user_tokens: props.user_tokens,
            token_selected: null,
            showModal: false,
        }
    }

    onChange(e) {
        console.log('e', e)
        this.setState({[e.target.name]: e.target.value})
    }

    selectToken = (token) => {
        console.log('token', token)
        let eth_address = this.state.eth_address || window.ethereum?.selectedAddress
        this.setState({token_selected: token, showModal: true, eth_address: eth_address})
    }

    closeModal() {
        this.setState({showModal: false})
    }

    submitForm(e) {
        const body = {
            token_withdrawal: {
                quantity: this.state.quantity,
                token_id: this.state.token_selected.token.id,
                address: this.state.eth_address,
            }
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}
        this.setState({showModal: false})

        fetch(`${window.location.origin}/api/v0/token_withdrawals`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.success) {
                    this.state.toasts.push({title: 'Withdrawal Initiated', information:  `Your tokens should be in your Polygon wallet shortly`, id: toastId++})
                    this.setState({toasts: this.state.toasts})
                } else {
                    this.state.toasts.push({title: 'Withdrawal Failed', information:  res?.errors?.[0], id: toastId++})
                    this.setState({toasts: this.state.toasts})
                }
            })
            .catch(err => {
            })
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderTokens() {
        return this.state.user_tokens.map(user_token => {
            if (user_token.quantity === 0 || user_token.token.blockchain_id === null) {
                return null
            }
            return (<UserTokenListItemMini key={user_token.id} clickMethod={this.selectToken.bind(this, user_token)} {...user_token} />)
        })
    }

    withdrawModal = () => {

        if (!this.state.showModal) {
            return null
        }

        let disabled = false
        let errorMessage = 'Transfer Invalid'
        let imageStyle = {width: '300px', height: '300px', objectFit: 'cover', borderRadius: '20px'}

        if (this.state.quantity === 0 || this.state.quantity === ''){
            disabled = true
            errorMessage = `Enter a quantity above 0`
        }

        if (this.state.eth_address.substr(0, 2) !== '0x' || this.state.eth_address.length !== 42) {
            disabled = true
            errorMessage = 'Invalid Eth Address'
        }

        if (this.state.quantity > this.state.token_selected.quantity) {
            disabled = true
            errorMessage = `Max Quantity ${this.state.token_selected.quantity}`
        }

        return (
            <div style={styles.modalContainer}>
                <FormContainer style={styles.container}>
                    <div style={styles.cardTitle}>
                        {`Withdraw ${this.state.token_selected.token.name}`}
                    </div>
                    <div style={styles.imageContainer}>
                        <img style={imageStyle} src={this.state.token_selected.token.thumbnail_url} />
                    </div>
                    <div style={styles.inputSection}>
                        <div style={styles.inputLabel}>
                            Quantity
                        </div>
                        <div style={styles.inputDescription}>
                            {`${this.state.token_selected.quantity} owned`}
                        </div>
                        <input style={styles.inputBox} maxLength="50" name="quantity" placeholder="0" value={this.state.quantity} onChange={this.onChange.bind(this)} />
                    </div>
                    <div style={styles.inputSection}>
                        <div style={styles.inputLabel}>
                            Ethereum Address
                        </div>
                        <input style={{...styles.inputBox, fontSize: '12px'}} maxLength="50" name="eth_address" placeholder="0x123" value={this.state.eth_address} onChange={this.onChange.bind(this)} />
                    </div>
                    {
                        !disabled ? (
                            <SubmitButton onClick={this.submitForm.bind(this)} style={styles.submitButton}>
                                {`Withdraw`}
                            </SubmitButton>

                        ) : (

                            <button style={styles.disabledButton}>
                                {errorMessage}
                            </button>
                        )
                    }
                </FormContainer>
                <div onClick={() => this.closeModal()} style={styles.background} />
            </div>
        )
    }

    renderWithdrawNFTs = () => {
        return (
            <React.Fragment>
                <div style={styles.cardTitle}>
                    Withdraw NFTs
                </div>
                <div style={styles.infoDescription}>
                    <div>
                        <a style={{color: '#5dd982', fontSize: '24px', fontWeight: 600}} href={'https://www.youtube.com/watch?v=F3gKREyUJl4'} target={"_blank"}>
                            {`Video Guide for NFT Withdrawals`}
                        </a>
                    </div>
                    For fraud prevention, withdrawals are temporarily restricted if you have recently made a credit card purchase
                </div>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Select Token
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {this.renderTokens()}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <div>
                {this.renderWithdrawNFTs()}
                {this.withdrawModal()}
                {this.renderToasts()}
            </div>
        )
    }
}


export default SettingsWithdrawNFT
