import React from "react"
import styled from "styled-components";
import moment from "moment";
import MediaModal from "./MediaModal";
import TradeList from "./TradeList";
import Toast from "./Toast";

const Web3Utils = require('web3-utils');
console.log('here')
console.log(Web3Utils)
// import web3 from 'web3';

const styles = {
    topSection: {
        width: '100%',
        padding: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        backgroundColor: 'white',
        borderBottom: '1px solid #EEE',
    },
    container: {
        maxWidth: '1000px',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: '0 auto',
        padding: '50px 20px 0',
    },
    containerMobile: {
        maxWidth: '1000px',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 auto',
        padding: '50px 20px 0',
    },
    nft: {
        marginRight: '50px',
        maxWidth: '500px',
        width: '100%',
        borderRadius: '15px',
    },
    nftMobile: {
        maxWidth: '300px',
        width: '100%',
        borderRadius: '15px',
    },
    info: {
        boxSizing: 'border-box',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '450px',
        width: '100%',
    },
    infoMobile: {
        boxSizing: 'border-box',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        width: '100%',
        maxWidth: '800px',
    },
    infoInner: {
        width: '100%',
        padding: '30px',
        boxSizing: 'border-box',
        display: 'flex',
        maxWidth: '530px',
        borderRadius: '15px',
    },
    infoInnerMobile: {
        padding: '30px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '800px',
        borderRadius: '15px',
    },
    submitSection: {
        width: '100%',
        padding: '30px',
        boxSizing: 'border-box',
        borderRadius: '15px',
        marginBottom: '40px',
    },
    submitSectionMobile: {
        width: '100%',
        padding: '30px',
        boxSizing: 'border-box',
        borderRadius: '15px',
        marginTop: '30px',
    },
    title: {
        fontWeight: '600',
        fontSize: '24px',
    },
    tx: {
        marginTop: '5px',
        fontSize: '16px',
        color: '#5dd982',
    },
    author: {
        fontSize: '14px',
        color: '#999',
    },
    description: {
        fontSize: '14px',
        color: "#333",
        marginTop: '20px',
    },
    editButton: {
        border: '1px solid #333',
        padding: 10,
        marginTop: '20px',
        display: 'inline-flex',
        borderRadius: '10px',
    },
    breadcrumb: {
        color: '#CCC',
        marginBottom: '30px',
    },
    breadcrumbLink: {
        color: '#5dd982',
    },
    rightSide: {
        paddingLeft: '20px',
        textAlign: 'right',
    },
    rightSideMobile: {
        marginTop: '30px',
    },
    price: {
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '30px',
    },
    quantity: {
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '30px',
    },
    sellType: {
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '30px',
    },
    leftSide: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    balanceSection: {

    },
    balanceTitle: {
        fontSize: '20px',
        color: '#999',
        marginBottom: '5px',
    },
    balance: {
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '30px',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '18px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
        width: '100%',
    },
    disabledButton: {
        border: 'none',
        backgroundColor: '#CCC',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '18px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'not-allowed',
        borderRadius: '5px',
        width: '100%',
    },
    section: {
        marginBottom: '40px',
    },
    sectionTitle: {
        fontSize: '18px',
        color: '#666',
        marginBottom: '5px',
    },
    itemDetailInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
    },
    infoContainer: {
        padding: '10px 0',
        minWidth: '140px',
        margin: '10px',
        borderRadius: '5px',
        backgroundColor: 'white',
        textAlign: 'center',
    },
    infoTitle: {
        fontSize: '12px',
        color: '#999',
    },
    orderBookContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerColumn: {
      padding: '10px',
    },
    column: {
        padding: '10px',
    },
    infoText: {
        fontWeight: '600',
        fontSize: '20px',
    },
    drawingEntered: {

    },
    header: {
        padding: '20px',
        boxSizing: 'border-box',
    },
    paymentHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paymentHeaderTitle: {
        fontWeight: '600',
        fontSize: '20px',
        marginBottom: '20px',
    },
    tradeHeaderTitle: {
        fontWeight: '600',
        fontSize: '20px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    paymentMethods: {
        display: 'flex',
    },
    paymentMethodButton: {
        boxSizing: 'border-box',
        padding: '10px',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    tradeMethods: {
        display: 'flex',
    },
    tradeMethodButton: {
        boxSizing: 'border-box',
        padding: '10px',
        borderRadius: '10px',
        fontWeight: '600',
        cursor: 'pointer',
        textAlign: 'center',
    },
    cardContainer: {
        width: '100%',
        borderTop: '1px solid #EEE',
        boxSizing: 'border-box',
        margin: '20px 0',
        textAlign: 'center',
    },
    cardDetail: {
        marginBottom: '10px',
    },
    balanceText: {
        fontWeight: '600',
        fontSize: '20px',
    },
    inputSection: {
        width: '100%',
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
        justifyContent: 'space-between',
    },
    inputLabel: {
        fontSize: '14px',
        fontWeight: '300',
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '14px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '300px',
        marginLeft: '20px',
    },
    divider: {
      width: '100%',
      height: '1px',
      backgroundColor: '#EEE',
    },
    estimatedCostSection: {
        display: 'flex',
        marginTop: '20px',
        width: '100%',
        justifyContent: 'space-between',
    },
    estimatedCostLabel: {
        fontWeight: '600',
    },
    estimatedCost: {
        fontWeight: '600',
    },
    marketPrice: {
        marginTop: '20px',
        fontSize: '13px',
        color: '#666',
    },
    switchPaymentMethod: {
        marginTop: '5px',
        fontSize: '15px',
        color: '#5dd982',
        cursor: 'pointer',
    },
    table: {
        width: '100%',
        textAlign: 'left',
        borderCollapse: 'collapse',
        borderSpacing: 0,
    },
    lastColumn: {
        textAlign: 'right',
    },
    headerRow: {
        fontSize: '13px',
        color: '#666',
        backgroundColor: '#EEE',
    },
    circle: {
        marginRight: '10px',
        color: '#999',
        cursor: 'pointer',
        position: 'relative',
    },
    ethInfoContainer: {
        color: '#111',
        borderRadius: '20px',
        padding: '30px',
        position: 'absolute',
        boxSizing: 'border-box',
        width: '230px',
        top: -100,
        left: -250,
    },
    ethTitle: {
        borderBottom: '1px solid #CCC',
        fontSize: '20px',
        paddingBottom: '5px',
    },
    ethLogo: {
        marginLeft: '5px',
        color: '#555',
        marginTop: '2px',
    }
}

const InfoContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.input`
:hover {
    background-color: #49ab67 !important;
}
`

const DisabledButton = styled.input`
`

let toastId = 1

class TokenPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            timeToDropStart: props.token.time_to_drop_start,
            timeToDropEnd: props.token.time_to_drop_end,
            modal: false,
            toasts: [],
            purchasing: false,
            drawingEntered: props.drawing_entered,
            payment_method_preference: props.user?.payment_method_preference || 'balance',
            trade_method: 'limit_buy',
            amount: '',
            price: '',
            quantity: '',
            purchase_quantity: '1',
            showPurchaseBreakdown: false,
            showOrderBreakdown: false,
        }
    }

    componentDidMount() {
        setInterval(() => this.setState({timeToDropStart: this.state.timeToDropStart-1, timeToDropEnd: this.state.timeToDropEnd - 1}), 1000)
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000})
    }

    renderBreadcrumb = () => {
        return (
            <div style={styles.breadcrumb}>
                <a href={'/'}>
                    <span style={styles.breadcrumbLink}>
                        Home
                    </span>
                </a>
                {' / '}
                <a href={`/tokens/${this.props.token.slug}`}>
                    <span style={styles.breadcrumbLink}>
                        {this.props.token.name}
                    </span>
                </a>
            </div>
        )
    }

    connectWallet = () => {
        console.log('hello')
        if (!this.state.connecting && window.ethereum) {
            this.setState({connecting: true})
            let test = window.ethereum.request({ method: 'eth_requestAccounts' })
            test.then(res => {
                this.updateUserSetting('eth')
                this.setState({purchasing: false})
            }).catch(err => {
                this.setState({purchasing: false})
            })
        }
    }

    submitForm() {
        if (this.state.purchasing) {
            return
        }

        if (this.state.payment_method_preference === 'eth' && !window.ethereum?.selectedAddress) {
            this.connectWallet()
            return
        }

        const body = {
            token_id: this.props.token.id,
            payment_method: this.state.payment_method_preference,
            quantity: this.state.purchase_quantity,
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        let fetchPath

        if (this.state.payment_method_preference === 'eth') {
            body.crypto_payment_id = this.props.crypto_payment.id
        }

        if (this.props.token.sell_type === "Normal") {
            fetchPath = `${window.location.origin}/api/v0/purchases`
        }

        if (this.props.token.sell_type === "Drawing") {
            fetchPath = `${window.location.origin}/api/v0/entries`
            this.setState({drawingEntered: true})
        }

        this.setState({purchasing: true})

        fetch(fetchPath, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                if (res.success) {
                    if (res.crypto_payment) {
                        let tx = await this.sendTransaction(res)
                        if (tx) {
                            window.location.href = `/purchases/${res.purchase.id}`
                        }
                    } else {
                        if (res.purchase) {
                            window.location.href = `/purchases/${res.purchase.id}`
                        }
                        if (res.entry) {
                            this.state.toasts.push({
                                title: 'Drawing Entered',
                                information: `You have successfully entered the drawing`,
                                id: toastId++
                            })
                            this.setState({toasts: this.state.toasts})
                        }
                    }
                } else {
                    this.state.toasts.push({title: 'Error', information:  res.errors?.[0], id: toastId++})
                    this.setState({toasts: this.state.toasts, purchasing: false})
                }
            })
            .catch(err => {
            })
    }

    submitTrade() {
        if (this.state.purchasing) {
            return
        }

        const body = {
            payment_method: this.state.payment_method_preference,
            order: {
                payment_method: this.state.payment_method_preference,
                token_id: this.props.token.id,
                quantity: this.state.quantity,
                order_type: this.state.trade_method,
                price: Math.round(parseFloat(this.state.amount.substr(1)) * 100),
            }
        }

        if (this.state.payment_method_preference === 'eth') {
            body.crypto_payment_id = this.props.crypto_payment.id
        }

        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        let fetchPath

        fetchPath = `${window.location.origin}/api/v0/orders`

        this.setState({purchasing: true})

        fetch(fetchPath, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                if (res.success) {
                    if (res.crypto_payment) {
                        let tx = await this.sendTransaction(res)
                        if (tx) {
                            window.location.href = `/orders/${res.order.id}`
                        }
                    } else {
                        window.location.href = `/orders/${res.order.id}`
                    }
                } else {
                    this.state.toasts.push({title: 'Order failed', information:  (res.errors?.[0] ?? `Unable to create order.`), id: toastId++})
                    this.setState({toasts: this.state.toasts, purchasing: false})
                }
            })
            .catch(err => {
            })
    }

    calcSeconds = time => {
        if (Math.floor(time % 60) === 0) {
            return "00"
        }
        return this.pad(Math.floor(time % 60))
    }

    calcMinutes = time => {
        if (Math.floor(time/60) % 60=== 0) {
            return "00"
        }
        return this.pad(Math.floor(time/60) % 60)
    }

    calcHours = time => {
        if (Math.floor(time/3600) === 0) {
            return "00"
        }
        return this.pad(Math.floor(time/3600))
    }

    pad = num => {
        if ((num + "").length === 1) {
            return "0" + num
        }
        return num
    }

    calcTime = time => {
        return this.calcHours(time) + ":" + this.calcMinutes(time) + ":" + this.calcSeconds(time)
    }

    renderEthInfo = (price, show) => {
        if (!show) {
            return null
        }

        if (isNaN(price)) {
            price = 0
        }

        let ethCost = price/this.props.crypto_payment.eth_price_in_cents
        ethCost = parseFloat(ethCost.toFixed(4));

        let ethFee = price/this.props.crypto_payment.eth_price_in_cents
        ethFee *= 0.05
        ethFee += 0.0015
        ethFee = parseFloat(ethFee.toFixed(4));

        let ethTotal = price/this.props.crypto_payment.eth_price_in_cents
        ethTotal *= 1.05
        ethTotal += 0.0015
        ethTotal = parseFloat(ethTotal.toFixed(4));

        return (
            <InfoContainer style={styles.ethInfoContainer}>
                <div style={styles.ethTitle}>
                    Cost Breakdown
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Price
                    </div>
                    <div style={{...styles.estimatedCost, display: 'flex'}}>
                        {ethCost}
                        <i className="fab fa-ethereum" style={styles.ethLogo} />
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Fee
                    </div>
                    <div style={{...styles.estimatedCost, display: 'flex'}}>
                        {ethFee}
                        <i className="fab fa-ethereum" style={styles.ethLogo} />
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Total
                    </div>
                    <div style={{...styles.estimatedCost, display: 'flex'}}>
                        {ethTotal}
                        <i className="fab fa-ethereum" style={styles.ethLogo} />
                    </div>
                </div>
            </InfoContainer>
        )
    }

    renderEth = () => {
        let price = (this.props.token.price * this.state.purchase_quantity)
        if (isNaN(price)) {
            price = 0
        }

        let ethCost = price/this.props.crypto_payment.eth_price_in_cents
        ethCost *= 1.05
        ethCost += 0.0015
        ethCost = parseFloat(ethCost.toFixed(4));

        if (price === 0) {
            ethCost = 0
        }

        return (
            <div style={styles.cardContainer}>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Quantity
                    </div>
                    <input style={{...styles.inputBox, marginLeft: '40px'}}  maxLength="50" name="purchase_quantity" placeholder="0" value={this.state.purchase_quantity} onChange={this.onChange.bind(this)} />
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Cost
                    </div>
                    <div style={styles.estimatedCost}>
                        {(price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Eth
                    </div>
                    <div style={{...styles.estimatedCost, display: 'flex'}}>
                        <div style={styles.circle} onMouseOut={() => this.setState({showPurchaseBreakdown: false})} onMouseOver={() => this.setState({showPurchaseBreakdown: true})}>
                            <i className="far fa-question-circle"></i>
                            {this.renderEthInfo((this.props.token.price * this.state.purchase_quantity), this.state.showPurchaseBreakdown)}
                        </div>
                        {ethCost}
                        <i className="fab fa-ethereum" style={styles.ethLogo} />
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Time Remaining
                    </div>
                    <div style={styles.estimatedCost}>
                        {this.calcTime(this.state.timeToDropEnd)}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Editions Remaining
                    </div>
                    <div style={styles.estimatedCost}>
                        {(this.props.token.quantity - this.props.token.quantity_minted) + '/' + this.props.token.quantity}
                    </div>
                </div>
                <div style={styles.marketPrice}>
                    Transactions made with ETH must be finalized on-chain within 30 minutes of purchase. If you do not receive your NFT your Eth will automatically be returned.
                </div>
            </div>
        )
    }

    renderBalance = () => {
        let price = (this.props.token.price * this.state.purchase_quantity)
        if (isNaN(price)) {
            price = 0
        }
        return (
            <div style={styles.cardContainer}>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Quantity
                    </div>
                    <input style={{...styles.inputBox, marginLeft: '40px'}}  maxLength="50" name="purchase_quantity" placeholder="0" value={this.state.purchase_quantity} onChange={this.onChange.bind(this)} />
                </div>
                <div style={{...styles.estimatedCostSection, paddingTop: '20px', borderTop: '1px solid #EEE'}}>
                    <div style={styles.estimatedCostLabel}>
                        Balance
                    </div>
                    <div style={styles.estimatedCost}>
                        {((this.props.user?.balance || 0)/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Cost
                    </div>
                    <div style={styles.estimatedCost}>
                        {(price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Time Remaining
                    </div>
                    <div style={styles.estimatedCost}>
                        {this.calcTime(this.state.timeToDropEnd)}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Editions Remaining
                    </div>
                    <div style={styles.estimatedCost}>
                        {(this.props.token.quantity - this.props.token.quantity_minted) + '/' + this.props.token.quantity}
                    </div>
                </div>
            </div>
        )
    }

    renderCard = () => {
        let price = (this.props.token.price * this.state.purchase_quantity)
        if (isNaN(price)) {
            price = 0
        }
        return (
            <div style={styles.cardContainer}>
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Quantity
                    </div>
                    <input style={{...styles.inputBox, marginLeft: '40px'}}  maxLength="50" name="purchase_quantity" placeholder="0" value={this.state.purchase_quantity} onChange={this.onChange.bind(this)} />
                </div>
                <div style={{...styles.cardDetail, paddingTop: '20px', borderTop: '1px solid #EEE'}}>
                    { this.props.user.stripe_payment_method_id ? (
                        <React.Fragment>
                            {`Your credit card will be charged `}
                            <span style={{fontWeight: '600'}}>
                                {(price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </span>
                            {this.props.token.sell_type === 'Drawing' ? ' upon winning' : ''}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            You have not added any credit cards to your account
                        </React.Fragment>
                    )}
                </div>
                <div>
                    <a style={{color: '#5dd982'}} href='/settings/?screen=payment' target="_blank">
                        Change/Add card in settings
                    </a>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Time Remaining
                    </div>
                    <div style={styles.estimatedCost}>
                        {this.calcTime(this.state.timeToDropEnd)}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Editions Remaining
                    </div>
                    <div style={styles.estimatedCost}>
                        {(this.props.token.quantity - this.props.token.quantity_minted) + '/' + this.props.token.quantity}
                    </div>
                </div>
            </div>
        )
    }

    updateUserSetting(payment_method_preference) {
        const body = {payment_method_preference: payment_method_preference}
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/users/${this.props.user?.slug}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({payment_method_preference: payment_method_preference})
                } else {
                }
            })
            .catch(err => {

            })
    }

    updateTradeMethod(trade_method) {
        this.setState({trade_method: trade_method})
    }

    renderTradeSubmitButton() {
        if (this.state.purchasing) {
            return (
                <DisabledButton disabled type="submit" value="Submitting..." style={styles.disabledButton} />
            )
        }

        if (!this.props.user) {
            return (
                <a href={'/users/new'}>
                    <SubmitButton type="submit" value="Create an account to trade" style={styles.submitButton} />
                </a>
            )
        }

        let buttonText
        if (this.state.trade_method === 'limit_buy' || this.state.trade_method === 'market_buy') {
            buttonText = `Place order with ${this.state.payment_method_preference} for ${this.estimatedCost()}`
        } else {
            buttonText = `Place sell order of ${this.state.quantity} for ${this.state.amount}${this.state.quantity > 1 ? ' each' : ''}`
        }

        let disabled = false

        if (this.estimatedCost() === '$0.00') {
            disabled = true
        }

        if (this.state.trade_method === 'limit_buy' && this.convertMoneyToCents(this.estimatedCost()) < 5000 && this.state.payment_method_preference === 'eth') {
            disabled = true
            buttonText = 'Crypto $50 minimum'
        }

        if (this.state.quantity === '' || this.state.amount === '') {
            disabled = true
            buttonText = 'Enter order data'
        }

        if (this.state.trade_method === 'limit_sell' || this.state.trade_method === 'market_sell') {
            if (this.state.quantity > this.props.user_token?.quantity || 0) {
                disabled = true
                buttonText = `You don't own ${this.state.quantity} ${this.props.token.name}`
            }
        }

        const amount = parseFloat(this.state.amount.substr(1))
        let result = ((amount * 100 * this.state.quantity).toFixed(2))
        if (result > this.props.user.balance && this.state.payment_method_preference === 'balance' && this.state.trade_method === 'limit_buy') {
            disabled = true
            buttonText = 'Insufficient funds'
        }

        if (this.state.payment_method_preference === 'eth' && !window.ethereum && this.state.trade_method === 'limit_buy') {
            return (
                <a href={'https://metamask.io/download'} target={"_blank"}>
                    <SubmitButton type="submit" value={'Download Metamask'} style={styles.submitButton}/>
                </a>
            )
        }

        if (this.state.payment_method_preference === 'eth' && !window.ethereum?.selectedAddress && this.state.trade_method === 'limit_buy') {
            return <SubmitButton type="submit" onClick={this.submitForm.bind(this)} name="commit" value={'Connect Wallet'} style={styles.submitButton} data-disable-with="Signup"/>
        }

        if (this.state.payment_method_preference === 'card' && !this.props.user.stripe_payment_method_id && this.state.trade_method === 'limit_buy') {
            return (
                <a href={'/settings/?screen=payment'} target="_blank">
                    <SubmitButton type="submit" value="Add a credit card to purchase" style={styles.submitButton} />
                </a>
            )
        } else {

            if (disabled) {
                return (
                    <DisabledButton type="submit" name="commit" value={buttonText} style={styles.disabledButton}/>
                )
            } else {
                return (
                    <SubmitButton type="submit" name="commit" data-disable-with="submit" onClick={this.submitTrade.bind(this)} value={buttonText} style={styles.submitButton}/>
                )
            }

        }
    }

    renderPaymentSubmitButton() {
        if (this.state.purchasing) {

            let buttonText = "Submitting..."

            if (this.state.payment_method_preference === 'eth') {
                buttonText = "Check Metamask"
            }

            return (
                <DisabledButton disabled type="submit" value={buttonText} style={styles.disabledButton} />
            )
        }

        if (!this.props.user) {
            return (
                <a href={'/users/new'}>
                    <SubmitButton type="submit" value="Create an account to purchase" style={styles.submitButton} />
                </a>
            )
        } else if (this.state.drawingEntered) {
            return (
                <div style={styles.drawingEntered}>
                    Drawing Entered
                </div>
            )
        }

        if (this.state.payment_method_preference === 'card' && !this.props.user.stripe_payment_method_id) {
            return (
                <a href={'/settings/?screen=payment'} target="_blank">
                    <SubmitButton type="submit" value="Add a credit card to purchase" style={styles.submitButton} />
                </a>
            )
        } else {
            let price = (this.props.token.price * this.state.purchase_quantity)
            if (isNaN(price)) {
                price = 0
            }
            if (price === 0) {
                return (
                    <DisabledButton disabled type="submit" value="Enter a value above 0" style={styles.disabledButton} />
                )
            }

            if (price < 5000 && this.state.payment_method_preference === 'eth') {
                return (
                    <DisabledButton disabled type="submit" value="Crypto $50 minimum" style={styles.disabledButton} />
                )
            }

            if (this.props.token.max_quantity && parseInt(this.state.purchase_quantity) + parseInt(this.props.purchased_quantity) > parseInt(this.props.token.max_quantity)) {
                return (
                    <DisabledButton disabled type="submit" value={`Purchase exceeds max of ${this.props.token.max_quantity} per person`} style={styles.disabledButton} />
                )
            }
            if (this.state.payment_method_preference === 'balance' && price > this.props.user.balance) {
                return (
                    <DisabledButton disabled type="submit" value="Insufficient Balance" style={styles.disabledButton} />
                )
            }

            if (this.state.payment_method_preference === 'eth' && !window.ethereum) {
                return (
                    <a href={'https://metamask.io/download'} target={"_blank"}>
                        <SubmitButton type="submit" value={'Download Metamask'} style={styles.submitButton}/>
                    </a>
                )
            }

            if (this.state.payment_method_preference === 'eth' && !window.ethereum?.selectedAddress) {
                return <SubmitButton type="submit" onClick={this.submitForm.bind(this)} name="commit" value={'Connect Wallet'} style={styles.submitButton} data-disable-with="Signup"/>
            }

            return (
                <SubmitButton type="submit" onClick={this.submitForm.bind(this)} name="commit"
                              value={this.props.token.sell_type === "Normal" ? `Purchase with ${this.state.payment_method_preference} for ${(price / 100).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD"
                              })}` : `Enter Drawing with ${this.state.payment_method_preference} for ${(price/ 100).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD"
                              })}`} style={styles.submitButton} data-disable-with="Signup"/>
            )
        }
    }

    renderPayment = () => {
        const selectedStyle = {
            border: '1px solid #5dd982',
            backgroundColor: '#5dd982',
            color: 'white',
            fontWeight: '600',
        }
        const unselectedStyle = {
            border: '1px solid #CCC',
            backgroundColor: 'white',
            color: '#666',
            fontWeight: '400',
        }
        return (
            <div>
                <div style={styles.paymentHeader}>
                    <div style={styles.paymentHeaderTitle}>
                        {`Purchase ${this.props.token.name}`}
                    </div>
                    <div style={styles.paymentMethods}>
                        <div style={{...styles.tradeMethodButton, ...(this.state.payment_method_preference === 'eth' ? selectedStyle : unselectedStyle)}} onClick={() => this.updateUserSetting('eth')}>
                            Eth
                            <i className="fab fa-ethereum" style={{...styles.ethLogo, color: this.state.payment_method_preference === 'eth' ? '#FFF' : '#555'}} />
                        </div>
                        <div style={{...styles.tradeMethodButton, marginLeft: '20px', ...(this.state.payment_method_preference === 'card' ? selectedStyle : unselectedStyle)}} onClick={() => this.updateUserSetting('card')}>
                            Card
                        </div>
                        <div style={{...styles.tradeMethodButton, marginLeft: '20px', ...(this.state.payment_method_preference === 'balance' ? selectedStyle : unselectedStyle)}} onClick={() => this.updateUserSetting('balance')}>
                            Balance
                        </div>
                    </div>
                </div>
                {this.state.payment_method_preference === 'card' && this.renderCard()}
                {this.state.payment_method_preference === 'balance' && this.renderBalance()}
                {this.state.payment_method_preference === 'eth' && this.renderEth()}
                {this.renderPaymentSubmitButton()}
            </div>
        )
    }

    resetCryptoPayment = () => {
        const body = {
            crypto_payment: {
                purchase_id: null,
                order_id: null,
            },
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

        fetch(`${window.location.origin}/api/v0/crypto_payments/${this.props.crypto_payment.id}`, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(async(res) => {
                if (res.success) {
                    this.setState({purchasing: false})
                } else {
                    this.state.toasts.push({title: 'Something went wrong', information:  `Please refresh the page.`, id: toastId++})
                    this.setState({toasts: this.state.toasts, purchasing: false})
                }
            })
            .catch(err => {
            })
    }

    sendTransaction = async(res) => {
        const transactionParameters = {
            to: res.crypto_payment.address,
            from: window.ethereum.selectedAddress,
            value: Web3Utils.toHex(res.crypto_payment.wei)
        }

        let txHash = null

        try {
            txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            })
        } catch (err) {
            if (err.code === 4001) {
                this.resetCryptoPayment()
            }
            console.log('nope')
            console.log(err)
        }

        return txHash
    }

    convertAnythingToMoney = (str) => {
        if (str[0] === '$') {
            str = str.substr(1)
        }
        if (str[0] === '.') {
            str = '0' + str
        }

        let foundDecimal = false
        let afterDecimal = 0
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        for (let i = 0; i < str.length; i++) {
            if (numbers.includes(str[i])) {
                if (foundDecimal) {
                    afterDecimal++
                    if (afterDecimal === 3) {
                        return false
                    }
                }
            } else if (str[i] === '.') {
                if (foundDecimal) {
                    return false
                }
                foundDecimal = true
            } else {
                return false
            }
        }

        if (str.length === 0) {
            return ''
        }
        return '$' + str
    }

    numbersOnly = (str) => {
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        for (let i = 0; i < str.length; i++) {
            if (!numbers.includes(str[i])) {
                return false
            }
        }
        return str
    }

    onChange(e) {
        if (e.target.name === 'amount') {
            const res = this.convertAnythingToMoney(e.target.value)
            if (res !== false) {
                this.setState({[e.target.name]: res})
            }
        } else if (e.target.name === 'quantity') {
            const res = this.numbersOnly(e.target.value)
            if (res !== false) {
                this.setState({[e.target.name]: res})
            }
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    estimatedCost = () => {
        if (this.state.amount.length <= 1 || this.state.quantity.length === 0) {
            return "$0.00"
        }

        const amount = parseFloat(this.state.amount.substr(1))
        let result = ((amount * this.state.quantity).toFixed(2))
        result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        return '$' + result
    }

    convertMoneyToCents = (str) => {
        let newStr = str.replace('$', '');
        newStr = newStr.replace(',', '')
        let cents = newStr.split('.')[1] || ''
        while (cents.length < 2) {
            cents += '0'
        }

        return parseInt(newStr.split('.')[0] + cents)
    }

    renderBuy = () => {
        const isLimit = this.state.trade_method === 'limit_buy'

        const selectedStyle = {
            border: '1px solid #5dd982',
            backgroundColor: '#5dd982',
            color: 'white',
            fontWeight: '600',
        }
        const unselectedStyle = {
            border: '1px solid #CCC',
            backgroundColor: 'white',
            color: '#666',
            fontWeight: '400',
        }

        let ethCost = this.convertMoneyToCents(this.estimatedCost())/this.props.crypto_payment.eth_price_in_cents
        ethCost *= 1.05
        ethCost += 0.0015
        ethCost = parseFloat(ethCost.toFixed(4));

        if (this.convertMoneyToCents(this.estimatedCost()) === 0) {
            ethCost = 0
        }

        return (
            <div>
                <div style={{...styles.divider, marginTop: '20px'}} />
                { isLimit && (
                    <div style={styles.inputSection}>
                        <div style={styles.inputLabel}>
                            Limit Price
                        </div>
                        <input style={styles.inputBox}  maxLength="50" name="amount" placeholder="$0.00" value={this.state.amount} onChange={this.onChange.bind(this)} />
                    </div>
                )}
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Quantity
                    </div>
                    <input style={styles.inputBox}  maxLength="50" name="quantity" placeholder="0" value={this.state.quantity} onChange={this.onChange.bind(this)} />
                </div>
                <div style={styles.divider} />
                <div style={{...styles.paymentHeader, width: '100%', padding: '20px 0'}}>
                    <div style={styles.paymentMethods}>
                        <div style={{...styles.tradeMethodButton, ...(this.state.payment_method_preference === 'eth' ? selectedStyle : unselectedStyle)}} onClick={() => this.updateUserSetting('eth')}>
                            Eth
                            <i className="fab fa-ethereum" style={{...styles.ethLogo, color: this.state.payment_method_preference === 'eth' ? '#FFF' : '#555'}} />
                        </div>
                        <div style={{...styles.tradeMethodButton, marginLeft: '20px', ...(this.state.payment_method_preference === 'card' ? selectedStyle : unselectedStyle)}} onClick={() => this.updateUserSetting('card')}>
                            Card
                        </div>
                        <div style={{...styles.tradeMethodButton, marginLeft: '20px', ...(this.state.payment_method_preference === 'balance' ? selectedStyle : unselectedStyle)}} onClick={() => this.updateUserSetting('balance')}>
                            Balance
                        </div>
                    </div>
                </div>
                <div style={styles.divider} />
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Estimated Cost
                    </div>
                    <div style={styles.estimatedCost}>
                        {this.estimatedCost()}
                    </div>
                </div>
                { this.state.payment_method_preference === 'eth' && (
                    <div style={styles.estimatedCostSection}>
                        <div style={styles.estimatedCostLabel}>
                            Eth
                        </div>
                        <div style={{...styles.estimatedCost, display: 'flex'}}>
                            <div style={styles.circle} onMouseOut={() => this.setState({showOrderBreakdown: false})} onMouseOver={() => this.setState({showOrderBreakdown: true})}>
                                <i className="far fa-question-circle" />
                                {this.renderEthInfo(Math.max(0, this.convertMoneyToCents(this.estimatedCost())), this.state.showOrderBreakdown)}
                            </div>
                            {ethCost}
                            <i className="fab fa-ethereum" style={styles.ethLogo} />
                        </div>
                    </div>
                )}
                {
                    this.state.payment_method_preference === 'card' &&
                        (
                            <React.Fragment>
                                <div style={styles.estimatedCostSection}>
                                    <div style={styles.estimatedCostLabel}>
                                        Paying with
                                    </div>
                                    <div style={styles.estimatedCost}>
                                        Credit Card
                                    </div>
                                </div>
                                <div style={{marginTop: '5px'}}>
                                    <a style={styles.switchPaymentMethod} href='/settings/?screen=payment' target="_blank">
                                        Change / Add card in settings
                                    </a>
                                </div>
                            </React.Fragment>
                        )
                }
                { this.state.payment_method_preference === 'balance' && (
                    (
                        <div style={styles.estimatedCostSection}>
                            <div style={styles.estimatedCostLabel}>
                                Balance
                            </div>
                            <div style={styles.estimatedCost}>
                                {((this.props.user?.balance || 0)/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                            </div>
                        </div>
                    )
                )}
                <div style={styles.marketPrice}>
                    {this.props.lowest_for_sale ? `Lowest for sale: ${(this.props.lowest_for_sale/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}` : `No ${this.props.token.name} are currently on sale`}
                </div>
                <div style={{width: '100%', height: '20px'}} />
                {this.renderTradeSubmitButton()}
            </div>
        )
    }

    renderSell = () => {
        const isLimit = this.state.trade_method === 'limit_sell'
        return (
            <div>
                <div style={{...styles.divider, marginTop: '20px'}} />
                { isLimit && (
                    <div style={styles.inputSection}>
                        <div style={styles.inputLabel}>
                            Limit Price
                        </div>
                        <input style={styles.inputBox}  maxLength="50" name="amount" placeholder="$0.00" value={this.state.amount} onChange={this.onChange.bind(this)} />
                    </div>
                )}
                <div style={styles.inputSection}>
                    <div style={styles.inputLabel}>
                        Quantity
                    </div>
                    <input style={styles.inputBox}  maxLength="50" name="quantity" placeholder="0" value={this.state.quantity} onChange={this.onChange.bind(this)} />
                </div>
                <div style={styles.divider} />
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Quantity Owned
                    </div>
                    <div style={styles.estimatedCost}>
                        {this.props.user_token?.quantity || 0}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Estimated Sale Price
                    </div>
                    <div style={styles.estimatedCost}>
                        {this.estimatedCost()}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        Transaction Fee (8%)
                    </div>
                    <div style={styles.estimatedCost}>
                        {((this.convertMoneyToCents(this.estimatedCost()) * 0.08)/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.estimatedCostSection}>
                    <div style={styles.estimatedCostLabel}>
                        You Receive
                    </div>
                    <div style={styles.estimatedCost}>
                        {((this.convertMoneyToCents(this.estimatedCost()) * 0.92)/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>

                <div style={styles.marketPrice}>
                    {this.props.highest_offer ? `Highest offer: ${(this.props.highest_offer/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}` : `No active bids for ${this.props.token.name}`}
                </div>
                <div style={{width: '100%', height: '20px'}} />
                {this.renderTradeSubmitButton()}
            </div>
        )
    }

    renderTrade = () => {
        const selectedStyle = {
            border: '1px solid #5dd982',
            backgroundColor: '#5dd982',
            color: 'white',
            fontWeight: '600',
        }
        const unselectedStyle = {
            border: '1px solid #CCC',
            backgroundColor: 'white',
            color: '#666',
            fontWeight: '400',
        }
        return (
            <div>
                <div style={styles.paymentHeader}>
                    <div style={styles.tradeHeaderTitle}>
                        {`Trade ${this.props.token.name}`}
                    </div>
                    <div style={styles.tradeMethods}>
                        <div style={{...styles.tradeMethodButton, marginLeft: '20px', ...(this.state.trade_method === 'limit_buy' ? selectedStyle : unselectedStyle)}} onClick={() => this.updateTradeMethod('limit_buy')}>
                            Limit Buy
                        </div>
                        <div style={{...styles.tradeMethodButton, marginLeft: '20px', ...(this.state.trade_method === 'limit_sell' ? selectedStyle : unselectedStyle)}} onClick={() => this.updateTradeMethod('limit_sell')}>
                            Limit Sell
                        </div>
                    </div>
                </div>
                {(this.state.trade_method === 'market_buy' || this.state.trade_method === 'limit_buy') ? this.renderBuy() : this.renderSell()}
            </div>
        )
    }

    renderPurchase = () => {
        if ((this.state.timeToDropEnd > 0 && this.state.timeToDropStart < 0 && this.props.token.quantity - this.props.token.quantity_minted > 0) || this.props.user?.admin) {
            return (
                <React.Fragment>
                    <InfoContainer style={this.state.mobile ? styles.submitSectionMobile : styles.submitSection}>
                        {this.renderPayment()}
                    </InfoContainer>
                </React.Fragment>
            )
        }
        return null
    }

    renderCreateOrder = () => {
        if (this.state.timeToDropStart > 0 || this.props.token.quantity_minted === 0) {
            return null
        }
        return (
            <InfoContainer style={this.state.mobile ? styles.submitSectionMobile : styles.submitSection}>
                {this.renderTrade()}
            </InfoContainer>
        )
    }

    renderDropTime = () => {
        if (this.state.timeToDropEnd < 0) {
            // Drop Ended
            return (
                <div style={styles.balanceSection}>
                    <div style={styles.balanceTitle}>
                        Drop Completed
                    </div>
                </div>
            )

        } else if (this.state.timeToDropStart > 0 ){
            // Drop hasn't started yet

            return (
                <div style={styles.balanceSection}>
                    <div style={styles.balanceTitle}>
                        Time To Drop
                    </div>
                    <div style={styles.balance}>
                        {this.calcTime(this.state.timeToDropStart)}
                    </div>
                </div>
            )
        } else {
            // Drop in progress

            return (
                <div style={styles.balanceSection}>
                    <div style={styles.balanceTitle}>
                        Time Remaining
                    </div>
                    <div style={styles.balance}>
                        {this.calcTime(this.state.timeToDropEnd)}
                    </div>
                </div>
            )
        }
    }

    renderItemDetailInfo() {
        if (!this.props.token_info) {
            return null
        }
        return (
            <div style={styles.itemDetailInfo}>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Last Price
                    </div>
                    <div style={styles.infoText}>
                        {(this.props.token_info.last_price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Lowest for sale
                    </div>
                    <div style={styles.infoText}>
                        {this.props.lowest_for_sale ? `${(this.props.lowest_for_sale/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}` : `---`}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Last Traded
                    </div>
                    <div style={styles.infoText}>
                        {this.props.token_info.last_traded_datetime ? moment(new Date(this.props.token_info.last_traded_datetime)).fromNow(true) + ' ago' : 'Never'}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Total Trades
                    </div>
                    <div style={styles.infoText}>
                        {this.props.token_info.total_trades}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Listing Price
                    </div>
                    <div style={styles.infoText}>
                        {(this.props.token_info.listing_price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Editions Minted
                    </div>
                    <div style={styles.infoText}>
                        {this.props.token_info.editions_minted}
                    </div>
                </div>
                <div style={styles.infoContainer}>
                    <div style={styles.infoTitle}>
                        Percent Change
                    </div>
                    <div style={styles.infoText}>
                        {(this.props.token_info.percent_change_from_initial || 0) + "%"}
                    </div>
                </div>
            </div>
        )
    }

    closeModal() {
        this.setState({modal: false})
    }

    renderToast = (toast) => {
        return (
            <Toast key={toast.id} title={toast.title} information={toast.information} />
        )
    }

    renderToasts = () => {
        return this.state.toasts.map(this.renderToast)
    }

    renderOrder = (order) => {
        return (
            <tr style={styles.row}>
                <td style={styles.column}>
                    {(order.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                </td>
                <td style={styles.column}>
                    {order.quantity}
                </td>
                <td style={{...styles.column, ...styles.lastColumn}}>
                    {(order.total/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                </td>
            </tr>
        )
    }

    renderOrders = (order_book, type) => {
        let orders = []
        for (const key in order_book) {
            let order = {}
            order.price = key
            order.quantity = order_book[key]
            order.total = order.price * order.quantity
            orders.push(order)
        }

        if (type === 'buy') {
            orders = orders.reverse()
        }

        if (orders.length === 0) {
            return (
                <div style={{width: '100%', padding: '10px'}} >
                    No Active Orders
                </div>
            )
        }

        return orders.map(this.renderOrder)
    }

    renderOrderBook = (type) => {
        if (this.state.timeToDropStart > 0 || this.props.token.quantity_minted === 0) {
            return null
        }
        return (
            <InfoContainer style={this.state.mobile ? styles.submitSectionMobile : styles.submitSection}>
                <div style={styles.orderBookContainer}>
                    <div style={styles.tradeHeaderTitle}>
                        {`${type === 'sell' ? 'For Sale' : 'Active Offers'}`}
                    </div>
                    <table style={styles.table}>
                        <thead>
                            <tr style={styles.headerRow}>
                                <th style={styles.headerColumn}>
                                    Price (USD)
                                </th>
                                <th style={styles.headerColumn}>
                                    Quantity
                                </th>
                                <th style={{...styles.headerColumn, ...styles.lastColumn}}>
                                    Total (USD)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderOrders(type === 'sell' ? this.props.order_book_sell : this.props.order_book_buy, type)}
                        </tbody>
                    </table>
                </div>
            </InfoContainer>
        )
    }

    renderTokenDetails = () => {
        if (this.state.timeToDropEnd <= 0) {
            return null
        }
        return (
            <InfoContainer style={this.state.mobile ? styles.infoInnerMobile : styles.submitSection}>
                <div style={styles.leftSide}>
                    <div>
                        {this.renderDropTime()}
                    </div>
                </div>
                <div style={this.state.mobile ? styles.rightSideMobile : styles.rightSide}>
                    <div style={styles.section}>
                        <div style={styles.sectionTitle}>
                            Price
                        </div>
                        <div style={styles.price}>
                            {(this.props.token.price/100).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                        </div>
                    </div>
                    <div style={styles.section}>
                        <div style={styles.sectionTitle}>
                            # Available
                        </div>
                        <div style={styles.quantity}>
                            {(this.props.token.quantity - this.props.token.quantity_minted) + '/' + this.props.token.quantity}
                        </div>
                    </div>
                    <div style={styles.section}>
                        <div style={styles.sectionTitle}>
                            Auction Type
                        </div>
                        <div style={styles.sellType}>
                            {this.props.token.sell_type}
                        </div>
                    </div>
                </div>
            </InfoContainer>
        )
    }

    renderText(text) {
        const re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
        let parts = text.split(re) // re is a matching regular expression
        for (let i = 1; i < parts.length; i += 2) {
            parts[i] = <a style={{color: '#5dd982'}} key={'link' + i} href={parts[i]}>{parts[i]}</a>
        }
        return parts
    }

    render () {
        return (
            <React.Fragment>
                {this.state.modal && <MediaModal close={this.closeModal.bind(this)} media_url={this.props.token.media_url}/>}
                <div style={styles.topSection}>
                    {this.renderItemDetailInfo()}
                </div>
                <div style={{...(this.state.mobile ? styles.containerMobile : styles.container), justifyContent: this.state.timeToDropStart <= 0 ? 'space-between' : 'space-around'}}>
                    <div>
                        <InfoContainer style={this.state.mobile ? styles.nftMobile : styles.nft}>
                            <img onClick={() => this.setState({modal: true})} style={{width: '100%', maxWidth: this.state.mobile ? '300px' : '500px', height: this.state.mobile ? '300px' : '500px', objectFit: 'cover', borderTopRightRadius: '15px', borderTopLeftRadius: '15px', cursor: 'pointer'}} src={this.props.token.thumbnail_url} />
                            <div style={styles.header}>
                                {this.renderBreadcrumb()}
                                <div style={styles.title}>
                                    {this.props.token.name}
                                </div>
                                {this.props.token.tx_id && (
                                    <a href={`https://polygonscan.com/tx/${this.props.token.tx_id}`}>
                                        <div style={styles.tx}>
                                            {"0x" + this.props.token.tx_id?.substr(0, 10) + "..." + this.props.token.tx_id?.substr(60)}
                                        </div>
                                    </a>
                                )}
                                <div style={styles.author}>
                                    {"by: " + this.props.token.user?.username}
                                </div>
                                <div style={{...styles.description, whiteSpace: 'pre-line'}}>
                                    {this.renderText(this.props.token.description)}
                                </div>
                                {/*{new Date() > new Date(this.props.token.auction_start) ? <a href={`/marketplace?item_detail_id=${this.props.token.id}`}> <div style={styles.editButton}> View In Marketplace </div> </a> : null }*/}
                                {this.state.can_edit ? <a href={`/tokens/${this.props.token.slug}/edit`}> <div style={styles.editButton}> Edit </div> </a> : null}
                            </div>
                        </InfoContainer>
                    </div>

                    {(this.state.timeToDropStart <= 0 || this.props.user?.admin) &&
                        <div style={this.state.mobile ? styles.infoMobile : styles.info}>
                            {this.renderPurchase()}
                            {this.renderCreateOrder()}
                            {this.renderOrderBook('sell')}
                            {this.renderOrderBook('buy')}
                        </div>
                    }
                    <TradeList trades={this.props.trades} total_pages={this.props.total_pages} />
                    {this.renderToasts()}
                </div>
            </React.Fragment>
        );
    }
}

export default TokenPage
