import React from "react"
import styled from "styled-components"
import DateTimePicker from 'react-datetime-picker'
import ErrorSection from "./ErrorSection";
import Checkbox from "./Checkbox";

const styles = {
    container: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Lato, Arial',
    },
    h1: {
        fontSize: 36,
        fontWeight: 600,
        marginBottom: '25px',
        marginTop: '35px',
    },
    formContainer: {
        maxWidth: '800px',
        padding: '30px',
        boxSizing: 'border-box',
        borderRadius: '20px',
        width: '100%',
    },
    formDescription: {

    },
    inputSection: {
        width: '100%',
        marginBottom: '20px',
    },
    inputLabel: {
        fontSize: '20px',
        fontWeight: '300',
        margin: '5px 0'
    },
    inputBox: {
        border: '1px solid #CCC',
        fontSize: '24px',
        padding: 10,
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        width: '100%',
    },
    submitButton: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    disabledButton: {
        border: 'none',
        backgroundColor: '#CCC',
        display: 'flex',
        padding: '10px 20px',
        fontSize: '22px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        transition: '0.3s',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    traitsSection: {
        paddingLeft: '30px',
        paddingTop: '20px',
    },
    categoriesSection: {
        marginBottom: '20px',
    },
    link: {
        border: 'none',
        backgroundColor: '#5dd982',
        display: 'flex',
        padding: '8px 16px',
        fontSize: '18px',
        fontWeight: '400',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        borderRadius: '5px',
    },
    delete: {
        marginLeft: '10px',
        cursor: 'pointer',
        fontSize: '14px',
        color: '#5dd982',
    },
    notificationSection: {
        margin: '20px 0',
        alignItems: 'center',
        display: 'flex',
    },
    notificationTitle: {
        fontSize: '20px',
        fontWeight: '300',
        marginLeft: '10px',
    },
}

const FormContainer = styled.div`
box-shadow: rgb(0, 0, 0, 0.15) 0px 2px 8px;
background-color: white;
`

const SubmitButton = styled.input`
:hover {
    background-color: #49ab67 !important;
}
`

class TokenNew extends React.Component {

    category_id = 100000000
    trait_id = 100000000

    constructor(props) {
        super(props)

        this.state = {
            is_edit: !!props.token,
            name: props.token?.name ?? '',
            description: props.token?.description ?? '',
            auction_start: props.token?.auction_start ? new Date(props.token?.auction_start) : null,
            auction_end: props.token?.auction_end ? new Date(props.token?.auction_end) : null,
            sell_type: props.token?.sell_type ?? props.sell_type_options[0],
            max_quantity: props.token?.max_quantity ?? '',
            quantity: props.token?.quantity ?? '',
            visible: props.token?.visible ?? false,
            ipfs: props.token?.ipfs ?? '',
            price: !!props.token ? ('$' + ((parseFloat(props.token?.price)/100).toFixed(2) + "")) : '',
            categories: props.token?.trait_categories || [],
            uploading: false,
            thumbnail_id: null,
            media_id: null,
        }

        this.state.categories.forEach((category) => {
            category.disabled = true
            category.traits.forEach((trait) => {
                trait.disabled = true
            })
        })
    }

    convertAnythingToMoney = (str) => {
        if (str[0] === '$') {
            str = str.substr(1)
        }
        if (str[0] === '.') {
            str = '0' + str
        }

        let foundDecimal = false
        let afterDecimal = 0
        const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        for (let i = 0; i < str.length; i++) {
            if (numbers.includes(str[i])) {
                if (foundDecimal) {
                    afterDecimal++
                    if (afterDecimal === 3) {
                        return false
                    }
                }
            } else if (str[i] === '.') {
                if (foundDecimal) {
                    return false
                }
                foundDecimal = true
            } else {
                return false
            }
        }

        if (str.length === 0) {
            return ''
        }
        return '$' + str
    }

    submitForm = () => {

        const token = (({ name, description, max_quantity, auction_start, auction_end, sell_type, quantity, ipfs, price, visible }) => ({ name, description, max_quantity, auction_start, auction_end, sell_type, quantity, ipfs, price, visible }))(this.state)

        const body = {token: token, media_id: this.state.media_id, thumbnail_id: this.state.thumbnail_id, categories: this.state.categories}


        if (body.token.price[0] === '$') {
            body.token.price = parseFloat(body.token.price.substr(1, body.token.price.length))
        }

        if (this.state.max_quantity?.length > 0) {
            body.token.max_quantity = parseInt(this.state.max_quantity)
        }

        body.token.price = Math.floor(body.token.price * 100)

        if (isNaN(body.token.price)) {
            // this.state.toasts.push({title: 'Unabled to update', information:  `Price is not a number`, id: toastId++})
            // this.setState({toasts: this.state.toasts})
            return
        }

        const csrf = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrf}

        fetch(`${window.location.origin}/api/v0/tokens${this.state.is_edit ? "/" + this.props.token.id : ""}`, {
            method: this.state.is_edit ? "PUT" : "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.success) {
                    window.location.href = `/tokens/${res.res.slug}`
                } else {
                    this.setState({errors: res.errors})
                    scroll(0,0)
                }

            })
            .catch(err => {
            })
    }

    handleChangeStart(e) {
        this.setState({auction_start: e})
    }

    handleChangeEnd(e) {
        this.setState({auction_end: e})
    }

    onChange(e) {
        if (e.target.name === 'price') {
            const res = this.convertAnythingToMoney(e.target.value)
            if (res !== false) {
                this.setState({[e.target.name]: res})
            }
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    renderSellTypeOptions() {
        return this.props.sell_type_options.map(option => (
            <option value={option}>
                {option}
            </option>
        ))
    }

    onFileChange = (fileName, event) => {
        const formData = new FormData();

        formData.append(
            "myFile",
            event.target.files[0],
            event.target.files[0].name
        );

        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'X-CSRF-TOKEN': token}

        const trueThis = this

        this.setState({uploading: true})

        fetch(`${window.location.origin}/api/v0/file_uploads`, {
            method: 'POST',
            headers: headers,
            body: formData
        })
            .then(response => response.json())
            .then(result => {
                if (fileName === 'thumbnail') {
                    trueThis.setState({thumbnail_id: result.res.id, uploading: false})
                } else {
                    trueThis.setState({media_id: result.res.id, uploading: false})
                }
                console.log('Success:', result);
            })
            .catch(error => {
                trueThis.setState({uploading: false})
                console.error('Error:', error);
            })
    }

    addCategory() {
        const categories = this.state.categories
        categories.push({id: this.category_id++, name: "", traits: []})
        this.setState({categories: categories})
    }

    addTrait(index) {
        const categories = this.state.categories
        categories[index].traits.push({id: this.trait_id++, name: "", weight: ""})
        this.setState({categories: categories})
    }

    removeCategory(index) {

        const category = this.state.categories[index]

        if (category.disabled) {
            const body = {id: category.id}
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/tokens/delete_category`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(res => {
                    console.log(res)
                    const categories = this.state.categories
                    categories.splice(index, 1)
                    this.setState({categories: categories})
                })
                .catch(err => {
                })
        } else {
            const categories = this.state.categories
            categories.splice(index, 1)
            this.setState({categories: categories})
        }


    }

    removeTrait(category_index, index) {
        const categories = this.state.categories
        const traits = categories[category_index].traits
        const trait = traits[index]

        if (trait.disabled) {
            const body = {id: trait.id}
            const token = document.querySelector('[name=csrf-token]').content
            const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}

            fetch(`${window.location.origin}/api/v0/tokens/delete_trait`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            }).then(res => res.json())
                .then(res => {
                    console.log(res)
                    traits.splice(index, 1)
                    this.setState({categories: categories})
                })
                .catch(err => {
                })
        } else {
            traits.splice(index, 1)
            this.setState({categories: categories})
        }
    }

    renderCategories() {
        return (
            <div style={styles.categoriesSection}>
                {this.state.categories.map((category, index) => this.renderCategory(category, index))}
                <div style={styles.link} onClick={this.addCategory.bind(this)}>
                    New Category
                </div>
            </div>
        )
    }

    onChangeCategory(index, e) {
        const categories = this.state.categories
        categories[index].name = e.target.value
        this.setState({categories: categories})
    }

    renderCategory(category, index) {
        return (
            <div key={category.id} style={styles.inputSection}>
                <div style={styles.inputLabel}>
                    Category
                    <span style={styles.delete} onClick={this.removeCategory.bind(this, index)}>
                        Delete
                    </span>
                </div>
                <input disabled={!!category.disabled} style={styles.inputBox} name={"name"} value={this.state.categories[index].name} placeholder={'Category'} onChange={this.onChangeCategory.bind(this, index)} />
                <div style={styles.traitsSection}>
                    {this.renderTraits(category.traits, index)}
                    <div style={styles.link} onClick={this.addTrait.bind(this, index)}>
                        New Trait
                    </div>
                </div>
            </div>
        )
    }

    renderTraits(traits, category_index) {
        return traits.map((trait, index) => this.renderTrait(trait, index, category_index))
    }

    onChangeTrait(category_index, index, e) {
        const categories = this.state.categories
        categories[category_index].traits[index][e.target.name] = e.target.value
        this.setState({categories: categories})
    }

    renderTrait(trait, index, category_index) {
        // TODO: Don't allow editing of name on update
        console.log(trait)
        return (
            <div key={trait.id} style={styles.inputSection}>
                <div style={styles.inputLabel}>
                    Trait
                    <span style={styles.delete} onClick={this.removeTrait.bind(this, category_index, index)}>
                        Delete
                    </span>
                </div>
                <input disabled={!!trait.disabled} style={styles.inputBox} name="name" value={this.state.categories[category_index].traits[index].name} placeholder={'Trait'} onChange={this.onChangeTrait.bind(this, category_index, index)} />
                <div style={{width: '100%', height: '10px'}}></div>
                <input style={styles.inputBox} name="weight" type="number" value={this.state.categories[category_index].traits[index].weight} placeholder={'Weight'} onChange={this.onChangeTrait.bind(this, category_index, index)} />
            </div>
        )
    }

    updateVisible(value) {
        this.setState({visible: value})
    }

    render () {
        console.log(this.props)
        return (
            <React.Fragment>
                <div style={styles.container}>
                    <div style={styles.h1}>
                        {`${this.state.is_edit ? 'Update' : 'New'}  Item`}
                    </div>
                    <FormContainer style={styles.formContainer}>
                        <ErrorSection errors={this.state.errors} />
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Name
                            </div>
                            <input maxLength="100" style={styles.inputBox} name="name" value={this.state.name} onChange={this.onChange.bind(this)} />
                        </div>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Description
                            </div>
                            <textarea maxLength="1000" style={styles.inputBox} name="description" value={this.state.description} onChange={this.onChange.bind(this)} />
                        </div>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Quantity
                            </div>
                            <input maxLength="10" style={styles.inputBox} type="number" name="quantity" value={this.state.quantity} onChange={this.onChange.bind(this)} />
                        </div>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Price
                            </div>
                            <input maxLength="10" style={styles.inputBox} name="price" value={this.state.price} onChange={this.onChange.bind(this)} />
                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Max Quantity
                            </div>
                            <input maxLength="100" style={styles.inputBox} name="max_quantity" value={this.state.max_quantity} onChange={this.onChange.bind(this)} />
                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                IPFS
                            </div>
                            <input maxLength="200" style={styles.inputBox} name="ipfs" value={this.state.ipfs} onChange={this.onChange.bind(this)} />
                        </div>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Auction Start
                            </div>
                            <DateTimePicker name="auction_start" onChange={this.handleChangeStart.bind(this)} value={this.state.auction_start}/>
                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Auction End
                            </div>
                            <DateTimePicker name="auction_end" onChange={this.handleChangeEnd.bind(this)} value={this.state.auction_end}/>
                        </div>

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Auction Type
                            </div>
                            <select style={styles.inputBox} onChange={this.onChange.bind(this)} value={this.state.sell_type_option} name="sell_type" id="sell_type">
                                {this.renderSellTypeOptions()}
                            </select>
                        </div>

                        <div style={styles.notificationSection}>
                            <Checkbox active={this.state.visible} updateFn={this.updateVisible.bind(this)} model={'something'} field={'visible'} />
                            <div style={styles.notificationTitle}>
                                Token Visible
                            </div>
                        </div>

                        {/*{this.renderCategories()}*/}

                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Thumbnail (500x500)
                            </div>
                            <input type="file" onChange={this.onFileChange.bind(this, 'thumbnail')} />
                        </div>
                        <div style={styles.inputSection}>
                            <div style={styles.inputLabel}>
                                Full file
                            </div>
                            <input type="file" onChange={this.onFileChange.bind(this, 'media')} />
                        </div>

                        <SubmitButton disabled={this.state.uploading} type="submit" onClick={this.submitForm.bind(this)} name="commit" value="Submit" style={this.state.uploading ? styles.disabledButton : styles.submitButton} data-disable-with="
Submit" />
                    </FormContainer>
                </div>
            </React.Fragment>
        );
    }
}

export default TokenNew
