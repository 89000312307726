import React from "react"
import TokenMiniListItem from "./TokenMiniListItem";
import Paginator from "./Paginator";

const styles = {
    totalContainer: {
        width: '100%',
        fontFamily: 'Lato, Arial',
    },
    container: {
        padding: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Lato, Arial',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    countdownLabel: {
        fontSize: '24px',
        color: '#666',
        marginBottom: '10px',
        marginTop: '40px',
    },
    collectionList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#DDD',
        margin: '30px 0 0',
    },
    countdownTimer: {
        fontWeight: '600',
        fontSize: '34px',
        marginBottom: '40px',
        textAlign: 'center',
    },
    pastDrops: {
        paddingTop: '50px',
        fontSize: '36px',
        fontWeight: '600',
        width: '100%',
        textAlign: 'center',
    },
    time: {
        fontWeight: '600',
        fontSize: '34px',
    },
    timeWord: {
        fontWeight: '400',
        marginRight: '30px',
        fontSize: '20px',
    },
    banner: {
        width: '100%',
        padding: '100px 20px',
        boxSizing: 'border-box',
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #EEE',
        textAlign: 'center',
    },
    bannerTitle: {
        fontWeight: '600',
        fontSize: '44px',
    },
    bannerText: {
        fontSize: '24px',
        color: '#666',
        marginTop: '10px',
    },
    learnMore: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#5dd982',
        marginTop: '10px',
    },
    collectionContainer: {
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    subDropText: {
        color: '#666',
        margin: '5px 0',
    },
}

class TokensPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            totalPages: props.total_pages,
            tokens: props.tokens,
        }
    }

    pad = num => {
        if ((num + "").length === 1) {
            return "0" + num
        }
        return num
    }

    renderCollections = tokens => {
        return tokens.map((token, index) => {
            return <TokenMiniListItem {...token} key={token.id} index={index} />
        })
    }

    renderDrops() {
        if (this.state.tokens.length === 0) {
            return null
        }

        return (
            <React.Fragment>
                <div style={styles.pastDrops}>
                    NFTs
                </div>
                <div style={styles.collectionList}>
                    {this.renderCollections(this.state.tokens)}
                </div>
            </React.Fragment>
        )
    }

    objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    }

    updateTokens(page) {
        const body = {
            page: page || 1,
        }
        const token = document.querySelector('[name=csrf-token]').content
        const headers = {'Content-Type': 'application/json', 'X-CSRF-TOKEN': token}


        fetch(`${window.location.origin}/api/v0/tokens?${this.objToQueryString(body)}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    this.setState({
                        tokens: res.tokens,
                    });
                }
            })
            .catch(err => {
            })
    }

    renderBanner() {
        return (
            <div style={styles.banner}>
                <div style={styles.bannerTitle}>
                    Buy & Sell Premium FTs
                </div>
                <div style={styles.bannerText}>
                    A highly curated collection of FTs from the top artists in the world.
                </div>
                <a href={'/about'} style={styles.learnMore}>
                    Learn More
                </a>
            </div>
        )
    }

    render () {
        return (
            <div style={styles.totalContainer}>
                {this.renderDrops()}
                <div style={styles.container}>
                    {this.state.totalPages > 1 ? <Paginator updateFn={this.updateTokens.bind(this)} totalPages={this.state.totalPages} /> : null}
                </div>
            </div>
        );
    }
}

export default TokensPage
